import * as types from "./managementActionTypes";


export const getUsersRequest = data => ({
  type: types.GET_USERS_REQUEST, payload: data
});

export const getUsersSuccess = data => ({
  type: types.GET_USERS_SUCCESS, payload: data
});

export const getUsersFailed = error => ({
  type: types.GET_USERS_FAILED, payload: error
});

export const getUserRequest = data => ({
  type: types.GET_USER_REQUEST, payload: data
});

export const getUserSuccess = (data = {}) => ({
  type: types.GET_USER_SUCCESS, payload: data
});

export const getUserFailed = error => ({
  type: types.GET_USER_FAILED, payload: error
});

export const deleteUserRequest = (data, toggle) => ({
  type: types.DELETE_USER_REQUEST, payload: { data, toggle }
});

export const deleteUserSuccess = data => ({
  type: types.DELETE_USER_SUCCESS, payload: data
});

export const deleteUserFailed = error => ({
  type: types.DELETE_USER_FAILED, payload: error
});

export const inviteUserRequest = (data, toggle) => ({
  type: types.INVITE_USER_REQUEST, payload: { data, toggle }
});

export const inviteUserSuccess = data => ({
  type: types.INVITE_USER_SUCCESS, payload: data
});

export const inviteUserFailed = error => ({
  type: types.INVITE_USER_FAILED, payload: error
});

export const suspendUserRequest = (data, toggle) => ({
  type: types.SUSPEND_USER_REQUEST, payload: { data, toggle }
});

export const suspendUserSuccess = data => ({
  type: types.SUSPEND_USER_SUCCESS, payload: data
});

export const suspendUserFailed = error => ({
  type: types.SUSPEND_USER_FAILED, payload: error
});

export const unblockSuspendedUserRequest = data => ({
  type: types.UNBLOCK_SUSPENDED_USER_REQUEST, payload: data
});

export const unblockSuspendedUserSuccess = data => ({
  type: types.UNBLOCK_SUSPENDED_USER_SUCCESS, payload: data
});

export const unblockSuspendedUserFailed = error => ({
  type: types.UNBLOCK_SUSPENDED_USER_FAILED, payload: error
});

export const createUserRequest = (data, history) => ({
  type: types.CREATE_USER_REQUEST, payload: { data, history }
});

export const createUserSuccess = data => ({
  type: types.CREATE_USER_SUCCESS, payload: data
});

export const createUserFailed = error => ({
  type: types.CREATE_USER_FAILED, payload: error
});

export const editUserRequest = (data, history) => ({
  type: types.EDIT_USER_REQUEST, payload: { data, history }
});

export const editUserSuccess = data => ({
  type: types.EDIT_USER_SUCCESS, payload: data
});

export const editUserFailed = error => ({
  type: types.EDIT_USER_FAILED, payload: error
});

export const clearUserErrorAction = () => ({
  type: types.CLEAR_USER_ERROR_ACTION,
});
