import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const auth = {
  async login(data) {
    try {
      return await fetch(`${API_URL}/user/sign_in`, {
        headers: {
          Authorization: null,
        },
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getUserViaMagicLink(query) {
    try {
      return await fetch(`${API_URL}/magic_link/sign_in${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async loginViaMagicLink(data) {
    try {
      return await fetch(`${API_URL}/magic_link/dispatch`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async register(data) {
    try {
      const result = await fetch(`${API_URL}/user`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async registerViaInvitation(data) {
    try {
      const result = await fetch(`${API_URL}/user/invitation`, {
        method: "PUT",
        body: JSON.stringify({ user: data })
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async forgetPassword(data) {
    try {
      const result = await fetch(`${API_URL}/user/password`, {
        method: "POST",
        body: JSON.stringify(data)
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async resetPassword(data) {
    try {
      const result = await fetch(`${API_URL}/user/password`, {
        method: "PUT",
        body: JSON.stringify(data)
      });

      return result.data;
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default auth;
