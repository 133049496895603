import {
  EMAIL_VALIDATION_PATTERN,
  PREFIX_TO_NAME_OPTIONS,
  REQUIRED_RULE, TRUE_FALSE_RADIOS
} from "../helpers/constants";

export const DELEGATE_DEFAULT_FIELDS = [
  {
    name: "title",
    label: "Title",
    placeholder: "Title",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    options: PREFIX_TO_NAME_OPTIONS,
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "first_name",
    label: "First Name",
    placeholder: "Type your first name",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "last_name",
    label: "Family Name",
    placeholder: "Type your last name",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter your email",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    registerPreference: {
      ...REQUIRED_RULE,
      pattern: EMAIL_VALIDATION_PATTERN
    },
  },
  {
    name: "phone",
    placeholder: "Enter your phone number",
    label: "Phone",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "organization",
    label: "Organisation",
    placeholder: "Enter your Organisation",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "position",
    label: "Position",
    placeholder: "Enter your position",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "attendee",
    label: "Attendance",
    options: TRUE_FALSE_RADIOS,
    groupClass: "col-12 form-group",
  },
  {
    name: "spam",
    label: "Spam",
    options: TRUE_FALSE_RADIOS,
    groupClass: "col-12 form-group",
  },
  {
    name: "canceled",
    label: "Cancellation",
    options: TRUE_FALSE_RADIOS,
    groupClass: "col-12 form-group",
  },
  {
    name: "notes",
    placeholder: "Type some notes here…",
    label: "Notes",
    groupClass: "col-12 form-group",
  }
];
