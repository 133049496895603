export const GET_DELEGATE_LIST_REQUEST = "GET_DELEGATE_LIST_REQUEST";
export const GET_DELEGATE_LIST_SUCCESS = "GET_DELEGATE_LIST_SUCCESS";
export const GET_DELEGATE_LIST_FAILED = "GET_DELEGATE_LIST_FAILED";

export const GET_SINGLE_DELEGATE_REQUEST = "GET_SINGLE_DELEGATE_REQUEST";
export const GET_SINGLE_DELEGATE_SUCCESS = "GET_SINGLE_DELEGATE_SUCCESS";
export const GET_SINGLE_DELEGATE_FAILED = "GET_SINGLE_DELEGATE_FAILED";

export const UPDATE_SINGLE_DELEGATE_REQUEST = "UPDATE_SINGLE_DELEGATE_REQUEST";
export const UPDATE_SINGLE_DELEGATE_SUCCESS = "UPDATE_SINGLE_DELEGATE_SUCCESS";
export const UPDATE_SINGLE_DELEGATE_FAILED = "UPDATE_SINGLE_DELEGATE_FAILED";

export const TOGGLE_ATTENDEE_REQUEST = "TOGGLE_ATTENDEE_REQUEST";
export const TOGGLE_ATTENDEE_SUCCESS = "TOGGLE_ATTENDEE_SUCCESS";
export const TOGGLE_ATTENDEE_FAILED = "TOGGLE_ATTENDEE_FAILED";

export const TOGGLE_SPAM_REQUEST = "TOGGLE_SPAM_REQUEST";
export const TOGGLE_SPAM_SUCCESS = "TOGGLE_SPAM_SUCCESS";
export const TOGGLE_SPAM_FAILED = "TOGGLE_SPAM_FAILED";

export const TOGGLE_CANCELED_REQUEST = "TOGGLE_CANCELED_REQUEST";
export const TOGGLE_CANCELED_SUCCESS = "TOGGLE_CANCELED_SUCCESS";
export const TOGGLE_CANCELED_FAILED = "TOGGLE_CANCELED_FAILED";

export const GET_DELEGATE_EVENTS_REQUEST = "GET_DELEGATE_EVENTS_REQUEST";
export const GET_DELEGATE_EVENTS_SUCCESS = "GET_DELEGATE_EVENTS_SUCCESS";
export const GET_DELEGATE_EVENTS_FAILED = "GET_DELEGATE_EVENTS_FAILED";

export const GET_DELEGATE_EXEL_FILE_REQUEST = "GET_DELEGATE_EXEL_FILE_REQUEST";
export const GET_DELEGATE_EXEL_FILE_SUCCESS = "GET_DELEGATE_EXEL_FILE_SUCCESS";
export const GET_DELEGATE_EXEL_FILE_FAILED = "GET_DELEGATE_EXEL_FILE_FAILED";
