export const GET_CREDIT_NOTES_REQUEST = "GET_CREDIT_NOTES_REQUEST";
export const GET_CREDIT_NOTES_SUCCESS = "GET_CREDIT_NOTES_SUCCESS";
export const GET_CREDIT_NOTES_FAILED = "GET_CREDIT_NOTES_FAILED";

export const GET_SINGLE_CREDIT_NOTE_REQUEST = "GET_SINGLE_CREDIT_NOTE_REQUEST";
export const GET_SINGLE_CREDIT_NOTE_SUCCESS = "GET_SINGLE_CREDIT_NOTE_SUCCESS";
export const GET_SINGLE_CREDIT_NOTE_FAILED = "GET_SINGLE_CREDIT_NOTE_FAILED";

export const CREATE_CREDIT_NOTE_REQUEST = "CREATE_CREDIT_NOTE_REQUEST";
export const CREATE_CREDIT_NOTE_SUCCESS = "CREATE_CREDIT_NOTE_SUCCESS";
export const CREATE_CREDIT_NOTE_FAILED = "CREATE_CREDIT_NOTE_FAILED";

export const EDIT_CREDIT_NOTE_REQUEST = "EDIT_CREDIT_NOTE_REQUEST";
export const EDIT_CREDIT_NOTE_SUCCESS = "EDIT_CREDIT_NOTE_SUCCESS";
export const EDIT_CREDIT_NOTE_FAILED = "EDIT_CREDIT_NOTE_FAILED";

export const DELETE_CREDIT_NOTE_REQUEST = "DELETE_CREDIT_NOTE_REQUEST";
export const DELETE_CREDIT_NOTE_SUCCESS = "DELETE_CREDIT_NOTE_SUCCESS";
export const DELETE_CREDIT_NOTE_FAILED = "DELETE_CREDIT_NOTE_FAILED";

export const GET_CREDIT_NOTE_PDF_REQUEST = "GET_CREDIT_NOTE_PDF_REQUEST";
export const GET_CREDIT_NOTE_PDF_SUCCESS = "GET_CREDIT_NOTE_PDF_SUCCESS";
export const GET_CREDIT_NOTE_PDF_FAILED = "GET_CREDIT_NOTE_PDF_FAILED";
