import { all, call, put, takeLatest } from "redux-saga/effects";

import * as types from "./globalActionTypes";

import global from "../../api/global";
import * as actions from "./globalActions";
import * as alertActions from "../alert/alertActions";

function* getIsoTimeZonesSaga({ payload }) {
  try {
    const response = yield call(global.getIsoTimeZones, payload);
    yield put(actions.getIsoTimeZonesSuccess(response));
  } catch (err) {
    yield put(actions.getIsoTimeZonesFailed(err));
  }
}

function* getSectorsSaga() {
  try {
    const response = yield call(global.getSectors);
    yield put(actions.getSectorsSuccess(response));
  } catch (err) {
    yield put(actions.getSectorsFailed(err));
  }
}

function* getCodeKeywordsSaga() {
  try {
    const response = yield call(global.getCodeKeywords);
    yield put(actions.getCodeKeywordsSuccess(response));
  } catch (err) {
    yield put(actions.getCodeKeywordsFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_ISO_TIME_ZONES_REQUEST, getIsoTimeZonesSaga),
    yield takeLatest(types.GET_SECTORS_REQUEST, getSectorsSaga),
    yield takeLatest(types.GET_CODE_KEYWORDS_REQUEST, getCodeKeywordsSaga),
  ]);
}
