import * as types from "./bookingActionTypes";
import * as authTypes from "../auth/authActionTypes";


export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  singleBooking: {},
  selectedUser: {},
  paymentSettings: {},
  isExistingUser: false,
  selectedDelegateId: null,
  loadingSelectedUser: false,
  error: null,
  loading: false
};


const booking = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_BOOKINGS_REQUEST:
    case types.EDIT_BOOKING_DELEGATE_REQUEST:
    case types.DELETE_BOOKING_DELEGATE_REQUEST:
    case types.CREATE_BOOKING_DELEGATE_REQUEST:
    case types.GET_SINGLE_BOOKING_REQUEST:
    case types.CREATE_BOOKING_REQUEST:
    case types.REMIND_EMAIL_ABOUT_BOOKING_REQUEST:
    case types.INIT_BOOKING_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_SINGLE_BOOKING_FAILED:
    case types.CREATE_BOOKING_FAILED:
    case types.EDIT_BOOKING_FAILED:
    case types.EDIT_NOTE_FAILED:
    case types.EDIT_BOOKING_DELEGATE_FAILED:
    case types.DELETE_BOOKING_DELEGATE_FAILED:
    case types.CREATE_BOOKING_DELEGATE_FAILED:
    case types.PROCEED_BOOKING_DELEGATE_FAILED:
    case types.REMIND_EMAIL_ABOUT_BOOKING_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        error: null,
        loading: false
      };

    case types.GET_BOOKINGS_FAILED:
      return {
        ...state,
        items: [],
        page: 1,
        count: 0,
        error: action.payload,
        loading: false
      };

    case types.REMIND_EMAIL_ABOUT_BOOKING_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => item.id === action.payload.id ? { ...item, ...action.payload } : item),
        error: null,
        loading: false
      };

    case types.GET_USER_BY_EMAIL_BOOKING_REQUEST:
      return {
        ...state,
        loadingSelectedUser: true
      };

    case types.GET_USER_BY_EMAIL_BOOKING_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
        isExistingUser: !!action.payload.id,
        error: null,
        loadingSelectedUser: false
      };

    case types.GET_USER_BY_EMAIL_BOOKING_FAILED:
      return {
        ...state,
        selectedUser: {},
        isExistingUser: false,
        error: action.payload,
        loadingSelectedUser: false
      };

    case types.GET_SINGLE_BOOKING_SUCCESS:
    case types.GET_BOOKING_DELEGATES_SUCCESS:
    case types.CREATE_BOOKING_SUCCESS:
    case types.PROCEED_BOOKING_DELEGATE_SUCCESS:
      return {
        ...state,
        singleBooking: {
          ...state.singleBooking,
          ...action.payload
        },
        error: null,
        loading: false
      };

    case types.CREATE_BOOKING_DELEGATE_SUCCESS:
      return {
        ...state,
        singleBooking: {
          ...state.singleBooking,
          booking_delegates: [action.payload, ...state.singleBooking.booking_delegates]
        },
        error: null,
        loading: false
      };

    case types.DELETE_BOOKING_DELEGATE_SUCCESS:
      return {
        ...state,
        singleBooking: {
          ...state.singleBooking,
          booking_delegates: state.singleBooking.booking_delegates.filter(item => item.id !== action.payload.id)
        },
        error: null,
        loading: false
      };

    case types.INIT_BOOKING_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSettings: action.payload,
        error: null,
        loading: false
      };

    case types.SET_SELECTED_DELEGATE_ACTION:
      return {
        ...state,
        selectedDelegateId: action.payload,
        error: null
      };

    case types.CLEAR_SELECTED_BOOKING_ACTION:
      return {
        ...state,
        singleBooking: {}
      };

    case types.CLEAR_SELECTED_USER_ACTION:
      return {
        ...state,
        selectedUser: {},
        isExistingUser: false,
        error: null,
        loadingSelectedUser: false
      };

    case types.CLEAR_BOOKING_ERROR_ACTION:
      return {
        ...state,
        error: null
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default booking;
