import { all, call, put, takeLatest } from "redux-saga/effects";

import invoice from "../../api/invoice";
import * as types from "./invoiceActionTypes";
import * as actions from "./invoiceActions";
import * as alertActions from "../alert/alertActions";

import { REQUEST_SUCCESS_MESSAGE } from "../../constants";
import event from "../../api/event";


function* getInvoicesSaga({ payload }) {
  try {
    const response = yield call(invoice.getInvoices, payload);
    yield put(actions.getInvoicesSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getInvoicesFailed(err));
  }
}

function* getSingleInvoiceSaga({ payload }) {
  try {
    const response = yield call(invoice.getSingleInvoice, payload);

    const invoiceEvent = response.booking && response.booking.id && response.event_ids &&
      response.event_ids.length === 1 ? yield call(event.getSingleEvent, response.event_ids[0]) : null;

    yield put(actions.getSingleInvoiceSuccess({ ...response, event: invoiceEvent }));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getSingleInvoiceFailed(err));
  }
}

function* createInvoiceSaga({ payload }) {
  try {
    const response = yield call(invoice.createInvoice, payload.data);
    yield call(() => payload.history.replace("/bookings/invoices"));
    yield put(actions.createInvoiceSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Invoice created successfully!"));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.createInvoiceFailed(err));
  }
}

function* editInvoiceSaga({ payload }) {
  try {
    const response = yield call(invoice.editInvoice, payload.data);
    yield call(() => payload.history.replace("/bookings/invoices"));
    yield put(actions.editInvoiceSuccess(response.items));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editInvoiceFailed(err));
  }
}

function* deleteInvoiceSaga({ payload }) {
  try {
    const response = yield call(invoice.deleteInvoice, payload.invoiceId);

    yield put(actions.deleteInvoiceSuccess(response));
    yield put(actions.getInvoicesRequest({ eventId: payload.eventId }));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.deleteInvoiceFailed(err));
  }
}

function* toggleInvoiceStatusSaga({ payload }) {
  try {
    const response = yield call(invoice.toggleInvoiceStatus, payload.body);

    yield put(actions.toggleInvoiceStatusSuccess(response));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.toggleInvoiceStatusFailed(err));
  }
}

function* sendInvoiceEmailSaga({ payload }) {
  try {
    const response = yield call(invoice.sendInvoiceEmail, payload.id);
    yield put(actions.sendInvoiceEmailSuccess(response));
    yield call(payload.callback);
    yield put(alertActions.showSuccessAlertAction("Email sent successfully!"));
  } catch (err) {
    yield put(actions.sendInvoiceEmailFailed(err));
  }
}

function* getEventInvoicesPdfSaga({ payload }) {
  try {
    const response = yield call(invoice.getEventInvoicesPdf, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank";
    link.click();
    yield put(actions.getEventInvoicesPdfSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getEventInvoicesPdfFailed(err));
  }
}

function* getEventInvoicesExelSaga({ payload }) {
  try {
    const response = yield call(invoice.getEventInvoicesExel, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = payload.filename || "export-invoices.xlsx";
    link.click();
    yield put(actions.getEventInvoicesExelSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getEventInvoicesExelFailed(err));
  }
}

function* getInvoicesExelSaga({ payload }) {
  try {
    const response = yield call(invoice.getInvoicesExel, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = payload.filename || "export-invoices.xlsx";
    link.click();
    yield put(actions.getInvoicesExelSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getInvoicesExelFailed(err));
  }
}

function* getXeroExportSaga({ payload }) {
  try {
    const response = yield call(invoice.getXeroExport, payload);

    if (response.authorization_url) {
      localStorage.setItem("xero", JSON.stringify(payload.query));
      window.location.href = response.authorization_url;
    } else if (response.message === "Invoices have been exported") {
      yield put(alertActions.showSuccessAlertAction(response.message));
      yield put(actions.getXeroExportSuccess(response));
    } else {
      yield put(alertActions.showFailedAlertAction(response.message));
    }
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err && err.errors ? err.errors : err));
    yield put(actions.getXeroExportFailed(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_INVOICES_REQUEST, getInvoicesSaga),
    yield takeLatest(types.GET_SINGLE_INVOICE_REQUEST, getSingleInvoiceSaga),
    yield takeLatest(types.CREATE_INVOICE_REQUEST, createInvoiceSaga),
    yield takeLatest(types.EDIT_INVOICE_REQUEST, editInvoiceSaga),
    yield takeLatest(types.DELETE_INVOICE_REQUEST, deleteInvoiceSaga),
    yield takeLatest(types.TOGGLE_INVOICE_STATUS_REQUEST, toggleInvoiceStatusSaga),
    yield takeLatest(types.SEND_INVOICE_EMAIL_REQUEST, sendInvoiceEmailSaga),
    yield takeLatest(types.GET_EVENT_INVOICES_PDF_REQUEST, getEventInvoicesPdfSaga),
    yield takeLatest(types.GET_EVENT_INVOICES_EXEL_REQUEST, getEventInvoicesExelSaga),
    yield takeLatest(types.GET_INVOICES_EXEL_REQUEST, getInvoicesExelSaga),
    yield takeLatest(types.GET_XERO_EXPORT_REQUEST, getXeroExportSaga),
  ]);
}
