import * as types from "./authActionTypes";

import { getLoggedInUser } from "../../helpers/authUtils";


export const INIT_STATE = {
  user: getLoggedInUser() || {},
  loginViaMagicLinkStatus: "",
  passwordResetStatus: "",
  registerStatus: "",
  loading: false,
  error: null
};


const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_USER_VIA_MAGIC_LINK_REQUEST:
    case types.LOGIN_USER_VIA_MAGIC_LINK_REQUEST:
    case types.LOGIN_USER_REQUEST:
    case types.UPDATE_MY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_USER_VIA_MAGIC_LINK_SUCCESS:
    case types.LOGIN_USER_SUCCESS:
    case types.UPDATE_MY_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload || {},
        loading: false,
        error: null
      };

    case types.GET_USER_VIA_MAGIC_LINK_FAILED:
    case types.LOGIN_USER_VIA_MAGIC_LINK_FAILED:
    case types.LOGIN_USER_FAILED:
    case types.UPDATE_MY_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case types.REGISTER_USER_REQUEST:
    case types.REGISTER_USER_VIA_INVITATION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.LOGIN_USER_VIA_MAGIC_LINK_SUCCESS:
      return {
        ...state,
        loginViaMagicLinkStatus: action.payload,
        loading: false,
        error: null
      };

    case types.REGISTER_USER_VIA_INVITATION_SUCCESS:
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user || {},
        registerStatus: action.payload.message,
        loading: false,
        error: null
      };

    case types.REGISTER_USER_VIA_INVITATION_FAILED:
    case types.REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case types.RESET_PASSWORD_REQUEST:
    case types.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.RESET_PASSWORD_SUCCESS:
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null
      };

    case types.RESET_PASSWORD_FAILED:
    case types.FORGET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case types.LOGOUT_USER_ACTION:
      return {
        ...state,
        user: {}
      };

    case types.CLEAR_AUTH_ERROR_ACTION:
      return {
        ...state,
        loading: false,
        error: null
      };

    default:
      return state;
  }
};

export default auth;
