import * as types from "./countriesActionTypes";


export const getIsoCountriesRequest = () => ({
  type: types.GET_ISO_COUNTRIES_REQUEST
});

export const getIsoCountriesSuccess = countries => ({
  type: types.GET_ISO_COUNTRIES_SUCCESS, payload: countries
});

export const getIsoCountriesFailed = error => ({
  type: types.GET_ISO_COUNTRIES_FAILED, payload: error
});

export const getCountriesRequest = (query) => ({
  type: types.GET_COUNTRIES_REQUEST, payload: query
});

export const getCountriesSuccess = countries => ({
  type: types.GET_COUNTRIES_SUCCESS, payload: countries
});

export const getCountriesFailed = error => ({
  type: types.GET_COUNTRIES_FAILED, payload: error
});

export const getCountriesOptionsRequest = () => ({
  type: types.GET_COUNTRIES_OPTIONS_REQUEST, payload: { per_page: 300 }
});

export const getCountriesOptionsSuccess = countries => ({
  type: types.GET_COUNTRIES_OPTIONS_SUCCESS, payload: countries
});

export const getCountriesOptionsFailed = error => ({
  type: types.GET_COUNTRIES_OPTIONS_FAILED, payload: error
});

export const createCountryRequest = (country, toggleModal) => ({
  type: types.CREATE_COUNTRY_REQUEST, payload: { country, toggleModal }
});

export const createCountrySuccess = data => ({
  type: types.CREATE_COUNTRY_SUCCESS, payload: data
});

export const createCountryFailed = error => ({
  type: types.CREATE_COUNTRY_FAILED, payload: error
});

export const editCountryRequest = (data, toggleModal) => ({
  type: types.EDIT_COUNTRY_REQUEST, payload: { data, toggleModal }
});

export const editCountrySuccess = data => ({
  type: types.EDIT_COUNTRY_SUCCESS, payload: data
});

export const editCountryFailed = error => ({
  type: types.EDIT_COUNTRY_FAILED, payload: error
});

export const deleteCountryRequest = data => ({
  type: types.DELETE_COUNTRY_REQUEST, payload: data
});

export const deleteCountrySuccess = data => ({
  type: types.DELETE_COUNTRY_SUCCESS, payload: data
});

export const deleteCountryFailed = error => ({
  type: types.DELETE_COUNTRY_FAILED, payload: error
});

export const selectCountryAction = data => ({
  type: types.SELECT_COUNTRY_ACTION, payload: data
});

export const deselectCountryAction = () => ({
  type: types.DESELECT_COUNTRY_ACTION
});
