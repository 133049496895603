import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const management = {
  async getUsers(query) {
    try {
      return await fetch(`${API_URL}/users${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getUser(id) {
    try {
      return await fetch(`${API_URL}/users/${id}`, {
        method: "GET",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteUser(id) {
    try {
      return await fetch(`${API_URL}/users/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async suspendUser({ id, locked_reason }) {
    try {
      return await fetch(`${API_URL}/users/${id}/suspend`, {
        method: "PUT",
        body: JSON.stringify({ locked_reason })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async inviteUser(data) {
    try {
      return await fetch(`${API_URL}/user/invitation`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createUser(data) {
    try {
      const formData = new FormData();

      Object.keys(data).forEach(item => {
        if (item === "photo") {
          const photoObj = data[item];

          // no photo for admins
          if (data.role === "admin") {
            return;
          }

          // in case when need upload a photo
          if (typeof photoObj === "object") {
            formData.append("user[photo]", photoObj);
          }

          // in case when a photo is empty
          if (typeof photoObj === "string" && !photoObj.length) {
            formData.append("user[photo]", "");
          }
        } else if (typeof data[item] !== "undefined") {
          formData.append(`user[${item}]`, data[item]);
        }
      });

      return await fetch(`${API_URL}/users`, {
        headers: {
          "Content-Type": null
        },
        method: "POST",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editUser({ id, ...data }) {
    try {
      const formData = new FormData();

      Object.keys(data).forEach(item => {
        if (item === "photo") {
          const photoObj = data[item];

          // no photo for admins
          if (data.role === "admin") {
            return;
          }

          // in case when need upload a photo
          if (typeof photoObj === "object") {
            formData.append("user[photo]", photoObj);
          }

          // in case when need delete a photo
          if (typeof photoObj === "string" && !photoObj.length) {
            formData.append("user[photo]", "DELETE");
          }
        } else if (typeof data[item] !== "undefined") {
          formData.append(`user[${item}]`, data[item]);
        }
      });

      return await fetch(`${API_URL}/users/${id}`, {
        headers: {
          "Content-Type": null
        },
        method: "PUT",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default management;
