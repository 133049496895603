import * as types from "./sponsorshipLevelActionTypes";


export const getSponsorshipLevelsRequest = () => ({
  type: types.GET_SPONSORSHIP_LEVELS_REQUEST,
});

export const getSponsorshipLevelsSuccess = data => ({
  type: types.GET_SPONSORSHIP_LEVELS_SUCCESS, payload: data
});

export const getSponsorshipLevelsFailed = error => ({
  type: types.GET_SPONSORSHIP_LEVELS_FAILED, payload: error
});

export const createSponsorshipLevelRequest = (data, toggleModal) => ({
  type: types.CREATE_SPONSORSHIP_LEVEL_REQUEST, payload: { data, toggleModal }
});

export const createSponsorshipLevelSuccess = data => ({
  type: types.CREATE_SPONSORSHIP_LEVEL_SUCCESS, payload: data
});

export const createSponsorshipLevelFailed = error => ({
  type: types.CREATE_SPONSORSHIP_LEVEL_FAILED, payload: error
});

export const editSponsorshipLevelRequest = (data, toggleModal) => ({
  type: types.EDIT_SPONSORSHIP_LEVEL_REQUEST, payload: { data, toggleModal }
});

export const editSponsorshipLevelSuccess = data => ({
  type: types.EDIT_SPONSORSHIP_LEVEL_SUCCESS, payload: data
});

export const editSponsorshipLevelFailed = error => ({
  type: types.EDIT_SPONSORSHIP_LEVEL_FAILED, payload: error
});

export const deleteSponsorshipLevelRequest = data => ({
  type: types.DELETE_SPONSORSHIP_LEVEL_REQUEST, payload: data
});

export const deleteSponsorshipLevelSuccess = data => ({
  type: types.DELETE_SPONSORSHIP_LEVEL_SUCCESS, payload: data
});

export const deleteSponsorshipLevelFailed = error => ({
  type: types.DELETE_SPONSORSHIP_LEVEL_FAILED, payload: error
});

export const selectSponsorshipLevelAction = data => ({
  type: types.SELECT_SPONSORSHIP_LEVEL_ACTION, payload: data
});

export const deselectSponsorshipLevelAction = () => ({
  type: types.DESELECT_SPONSORSHIP_LEVEL_ACTION,
});
