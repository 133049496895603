import { all, call, put, select, takeLatest } from "redux-saga/effects";

import event from "../../api/event";
import * as types from "./eventActionTypes";
import * as actions from "./eventActions";
import * as alertActions from "../alert/alertActions";
import * as globalActions from "../global/globalActions";

import { REQUEST_SUCCESS_MESSAGE } from "../../constants";


function* getEventsSaga({ payload }) {
  try {
    const response = yield call(event.getEvents, payload);
    yield put(actions.getEventsSuccess(response));
  } catch (err) {
    yield put(actions.getEventsFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getEventsForSelectorSaga({ payload }) {
  try {
    const response = payload.role === "admin" || payload.role === "event_manager"
      ? yield call(event.getEvents, payload.query)
      : yield call(event.getEventsForDelegate, payload);
    yield put(actions.getEventsForSelectorSuccess(response));
  } catch (err) {
    yield put(actions.getEventsForSelectorFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getSingleEventSaga({ payload }) {
  try {
    const response = yield call(event.getSingleEvent, payload);
    yield put(actions.getSingleEventSuccess(response));
    yield put(globalActions.setSelectedEventAction(response));
  } catch (err) {
    yield put(actions.getSingleEventFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getOrganizationTypesSaga({ payload }) {
  try {
    const response = yield call(event.getEventOrganizationTypes, payload);
    yield put(actions.getOrganizationTypesSuccess(response.items));
  } catch (err) {
    yield put(actions.getOrganizationTypesFailed(err));
  }
}

function* getBreakoutsSaga({ payload }) {
  try {
    const response = yield call(event.getEventBreakouts, payload);
    yield put(actions.getBreakoutsSuccess(response.items));
  } catch (err) {
    yield put(actions.getBreakoutsFailed(err));
  }
}

function* createEventSaga({ payload }) {
  try {
    const response = yield call(event.createEvent, payload.event);
    yield call(() => payload.history.push(`/events/${response.id}/event-details`));
    yield put(alertActions.showSuccessAlertAction("Event created successfully!"));
    yield put(actions.createEventSuccess(response));
  } catch (err) {
    yield put(actions.createEventFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventDetailsSaga({ payload }) {
  try {
    const response = yield call(event.editEventDetails, payload);
    yield put(actions.editEventDetailsSuccess(response));
    yield put(globalActions.setSelectedEventAction(response));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventDetailsFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventContactSaga({ payload }) {
  try {
    const response = yield call(event.editEventContact, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventContactFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventVenueSaga({ payload }) {
  try {
    const response = yield call(event.editEventVenue, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventVenueFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventAppearanceSaga({ payload }) {
  try {
    const response = yield call(event.editEventAppearance, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventAppearanceFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventPreferencesSaga({ payload }) {
  try {
    const ids = [];
    const attributes = [];
    const { id, data = {} } = payload;
    const preferences = yield select(store => store.event.singleEvent.preferences) || [];
    const changedPreferences = preferences
      .map((item, index) => ({ ...item, order: index + 1, event_id: id }))
      .filter(item => item.value !== data[item.key])
      .map(item => ({ ...item, value: data[item.key] }));

    const createdPreferences = changedPreferences.filter(item => !item.id);
    if (createdPreferences.length) {
      yield call(event.createEventPreferences, {
        event_id: id,
        body: { event_preference: { attributes: createdPreferences } }
      });
    }

    const updatedPreferences = changedPreferences.filter(item => item.id);
    if (updatedPreferences.length) {
      updatedPreferences.forEach(item => {
        ids.push(item.id);
        attributes.push({ value: item.value });
      });
      yield call(event.editEventPreferences, {
        event_id: id,
        body: { event_preference: { ids, attributes } }
      });
    }

    yield put(actions.getSingleEventRequest(id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventAppearanceFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editBookingFieldSaga({ payload }) {
  try {
    const singleEvent = yield select(store => store.event.singleEvent);
    const response = payload.id
      ? yield call(event.updateEventBookingField, payload)
      : yield call(event.createEventBookingField, payload);

    yield put(actions.getSingleEventSuccess({ ...singleEvent, ...response }));
  } catch (err) {
    yield put(actions.editBookingFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventInvoiceTemplateSaga({ payload }) {
  try {
    const response = yield call(event.editEventInvoiceTemplate, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editEventInvoiceTemplateFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* previewEventCustomInvoicePdfSaga({ payload }) {
  try {
    const response = yield call(event.previewEventCustomInvoicePdf, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank";
    link.click();
    yield put(actions.previewEventCustomInvoicePdfSuccess());
  } catch (err) {
    yield put(actions.previewEventCustomInvoicePdfFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventFeeGroupSaga({ payload }) {
  try {
    const response = yield call(event.createEventFeeGroup, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventFeeGroupFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventFeeGroupSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventFeeGroup, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.deleteEventFeeGroupFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventGroupDiscountSaga({ payload }) {
  try {
    const response = yield call(event.createEventGroupDiscount, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventGroupDiscountFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventGroupDiscountSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventGroupDiscount, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.deleteEventGroupDiscountFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventOrganizationTypeSaga({ payload }) {
  try {
    const response = yield call(event.createEventOrganizationType, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventOrganizationTypeFailed(err));
    yield put(alertActions.showFailedAlertAction(err, ["name"]));
  }
}

function* updateEventOrganizationTypeSaga({ payload }) {
  try {
    const response = yield call(event.updateEventOrganizationType, payload.data);
    yield put(actions.getSingleEventRequest(response.id));

    payload.callback();
  } catch (err) {
    yield put(actions.updateEventOrganizationTypeFailed(err));
    yield put(alertActions.showFailedAlertAction(err, ["name"]));
  }
}

function* deleteEventOrganizationTypeSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventOrganizationType, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.deleteEventOrganizationTypeFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* crudEventOptionalItemSaga({ payload }) {
  try {
    const response = yield call(event.crudEventOptionalItem, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.crudEventOptionalItemFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventDiscountCodeSaga({ payload }) {
  try {
    const response = yield call(event.createEventDiscountCode, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventDiscountCodeFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventDiscountCodeSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventDiscountCode, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.deleteEventDiscountCodeFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* crudEventSponsorAndSpeakerSaga({ payload }) {
  try {
    const response = yield call(event.crudEventSponsorAndSpeaker, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.crudEventSponsorFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventAgendaBreakoutSaga({ payload }) {
  try {
    const response = yield call(event.createEventAgendaBreakout, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventAgendaBreakoutFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventAgendaBreakoutSaga({ payload }) {
  try {
    const { breakout, history } = payload;
    const singleEvent = yield select(store => store.event.singleEvent);
    const breakoutSessions = (singleEvent.sessions || []).filter(x => x.breakout_id === breakout.id);

    const response = yield call(event.editEventAgendaBreakout, breakout);


    if (breakoutSessions.length) {
      yield all(breakoutSessions.map(item => {
        const moderatorObj = item.moderator && item.moderator.id ? { moderator_id: item.moderator.id } : {};
        delete item.moderator;
        delete item.session_event_speakers;

        const session = {
          ...item,
          ...moderatorObj,
          start_at: breakout.start_at,
          end_at: breakout.end_at,
          session_event_speakers_attributes: []
        };

        return call(event.editEventAgendaSession, session);
      }));
    }

    if (history) {
      history.goBack();
    }
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.editEventAgendaBreakoutFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventAgendaBreakoutSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventAgendaBreakout, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction("Breakout was successfully deleted"));
  } catch (err) {
    yield put(actions.deleteEventAgendaBreakoutFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventAgendaSessionSaga({ payload }) {
  try {
    const response = yield call(event.createEventAgendaSession, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(actions.setEventRedirectAction());
  } catch (err) {
    yield put(actions.createEventAgendaSessionFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editEventAgendaSessionSaga({ payload }) {
  try {
    const response = yield call(event.editEventAgendaSession, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(actions.setEventRedirectAction());
  } catch (err) {
    yield put(actions.editEventAgendaSessionFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventAgendaSessionSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventAgendaSessionApi, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.deleteEventAgendaSessionFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventAdditionalFieldGroupSaga({ payload }) {
  try {
    const response = yield call(event.createEventAdditionalFieldGroup, payload.data);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
    payload.callback();
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* updateEventAdditionalFieldGroupSaga({ payload }) {
  try {
    const response = yield call(event.updateEventAdditionalFieldGroup, payload.data);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
    payload.callback();
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventAdditionalFieldGroupSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventAdditionalFieldGroup, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventAdditionalFieldSaga({ payload }) {
  try {
    const response = yield call(event.createEventAdditionalField, payload.data);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
    payload.callback();
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* updateEventAdditionalFieldSaga({ payload }) {
  try {
    const response = yield call(event.updateEventAdditionalField, payload.data);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
    payload.callback();
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventAdditionalFieldSaga({ payload }) {
  try {
    const response = yield call(event.deleteEventAdditionalField, payload);
    yield put(actions.getSingleEventRequest(response.id));
  } catch (err) {
    yield put(actions.createEventAdditionalFieldFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* assignEventManagerSaga({ payload }) {
  try {
    const response = yield call(event.assignEventManager, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.assignEventManagerFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* updateEmailTemplatesSaga({ payload }) {
  try {
    const response = yield call(event.updateEmailTemplates, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.updateEmailTemplatesFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* sendEventEmailInfoSaga({ payload }) {
  try {
    const response = yield call(event.sendEventEmailInfo, payload);
    yield put(actions.getSingleEventRequest(response.id));
    yield put(alertActions.showSuccessAlertAction("Email sent successfully!"));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.getSingleEventFailed(err));
  }
}

function* archiveEventSaga({ payload }) {
  try {
    const response = yield call(event.archiveEventApi, payload);
    yield put(actions.archiveEventSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Event archived successfully!"));
  } catch (err) {
    yield put(actions.getSingleEventFailed(err));
  }
}

function* getEventOptionsPolicy() {
  try {
    const response = yield call(event.getEventOptionsPolicy);
    yield put(actions.getEventOptionsPolicyRequestSuccess(response.form.options_policy));
  } catch (err) {
    yield put(actions.getEventOptionsPolicyRequestError(err));
  }
}

function* saveEventAsDuplicate({ payload }) {
  try {
    yield call(event.saveEventAsDuplicate, payload);
    yield put(alertActions.showSuccessAlertAction("Event successfully duplicated!"));
  } catch (error) {
    yield put(actions.saveEventAsDuplicateRequestError(error));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_EVENTS_REQUEST, getEventsSaga),
    yield takeLatest(types.GET_EVENTS_FOR_SELECTOR_REQUEST, getEventsForSelectorSaga),
    yield takeLatest(types.GET_SINGLE_EVENT_REQUEST, getSingleEventSaga),
    yield takeLatest(types.CREATE_EVENT_REQUEST, createEventSaga),
    yield takeLatest(types.EDIT_EVENT_DETAILS_REQUEST, editEventDetailsSaga),
    yield takeLatest(types.EDIT_EVENT_CONTACT_REQUEST, editEventContactSaga),
    yield takeLatest(types.EDIT_EVENT_VENUE_REQUEST, editEventVenueSaga),
    yield takeLatest(types.EDIT_EVENT_APPEARANCE_REQUEST, editEventAppearanceSaga),
    yield takeLatest(types.EDIT_EVENT_PREFERENCES_REQUEST, editEventPreferencesSaga),
    yield takeLatest(types.EDIT_BOOKING_FIELD_REQUEST, editBookingFieldSaga),
    yield takeLatest(types.EDIT_EVENT_INVOICE_TEMPLATE_REQUEST, editEventInvoiceTemplateSaga),
    yield takeLatest(types.PREVIEW_EVENT_INVOICE_TEMPLATE_REQUEST, previewEventCustomInvoicePdfSaga),
    yield takeLatest(types.CREATE_EVENT_FEE_GROUP_REQUEST, createEventFeeGroupSaga),
    yield takeLatest(types.DELETE_EVENT_FEE_GROUP_REQUEST, deleteEventFeeGroupSaga),
    yield takeLatest(types.CREATE_EVENT_GROUP_DISCOUNT_REQUEST, createEventGroupDiscountSaga),
    yield takeLatest(types.DELETE_EVENT_GROUP_DISCOUNT_REQUEST, deleteEventGroupDiscountSaga),
    yield takeLatest(types.CREATE_EVENT_ORGANIZATION_TYPE_REQUEST, createEventOrganizationTypeSaga),
    yield takeLatest(types.UPDATE_EVENT_ORGANIZATION_TYPE_REQUEST, updateEventOrganizationTypeSaga),
    yield takeLatest(types.DELETE_EVENT_ORGANIZATION_TYPE_REQUEST, deleteEventOrganizationTypeSaga),
    yield takeLatest(types.CRUD_EVENT_OPTIONAL_ITEM_REQUEST, crudEventOptionalItemSaga),
    yield takeLatest(types.CREATE_EVENT_DISCOUNT_CODE_REQUEST, createEventDiscountCodeSaga),
    yield takeLatest(types.DELETE_EVENT_DISCOUNT_CODE_REQUEST, deleteEventDiscountCodeSaga),
    yield takeLatest(types.CRUD_EVENT_SPONSOR_REQUEST, crudEventSponsorAndSpeakerSaga),
    yield takeLatest(types.CREATE_EVENT_AGENDA_BREAKOUT_REQUEST, createEventAgendaBreakoutSaga),
    yield takeLatest(types.EDIT_EVENT_AGENDA_BREAKOUT_REQUEST, editEventAgendaBreakoutSaga),
    yield takeLatest(types.DELETE_EVENT_AGENDA_BREAKOUT_REQUEST, deleteEventAgendaBreakoutSaga),
    yield takeLatest(types.CREATE_EVENT_AGENDA_SESSION_REQUEST, createEventAgendaSessionSaga),
    yield takeLatest(types.EDIT_EVENT_AGENDA_SESSION_REQUEST, editEventAgendaSessionSaga),
    yield takeLatest(types.DELETE_EVENT_AGENDA_SESSION_REQUEST, deleteEventAgendaSessionSaga),
    yield takeLatest(types.CREATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, createEventAdditionalFieldGroupSaga),
    yield takeLatest(types.UPDATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, updateEventAdditionalFieldGroupSaga),
    yield takeLatest(types.DELETE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, deleteEventAdditionalFieldGroupSaga),
    yield takeLatest(types.CREATE_EVENT_ADDITIONAL_FIELD_REQUEST, createEventAdditionalFieldSaga),
    yield takeLatest(types.UPDATE_EVENT_ADDITIONAL_FIELD_REQUEST, updateEventAdditionalFieldSaga),
    yield takeLatest(types.DELETE_EVENT_ADDITIONAL_FIELD_REQUEST, deleteEventAdditionalFieldSaga),
    yield takeLatest(types.ASSIGN_EVENT_MANAGER_REQUEST, assignEventManagerSaga),
    yield takeLatest(types.GET_BREAKOUTS_REQUEST, getBreakoutsSaga),
    yield takeLatest(types.GET_ORGANIZATION_TYPES_REQUEST, getOrganizationTypesSaga),
    yield takeLatest(types.UPDATE_EVENT_EMAIL_TEMPLATES_REQUEST, updateEmailTemplatesSaga),
    yield takeLatest(types.SEND_EVENT_EMAIL_INFO_REQUEST, sendEventEmailInfoSaga),
    yield takeLatest(types.ARCHIVE_EVENT_REQUEST, archiveEventSaga),
    yield takeLatest(types.GET_EVENT_OPTIONS_POLICY_REQUEST, getEventOptionsPolicy),
    yield takeLatest(types.SAVE_EVENT_AS_DUPLICATE_REQUEST, saveEventAsDuplicate)
  ]);
}
