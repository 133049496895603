import { all, call, put, takeLatest } from "redux-saga/effects";

import creditNote from "../../api/creditNote";
import * as types from "./creditNoteActionTypes";
import * as actions from "./creditNoteActions";
import * as alertActions from "../alert/alertActions";

import { REQUEST_SUCCESS_MESSAGE } from "../../constants";


function* getCreditNotesSaga({ payload }) {
  try {
    const response = yield call(creditNote.getCreditNotes, payload);
    yield put(actions.getCreditNotesSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getCreditNotesFailed(err));
  }
}

function* getSingleCreditNoteSaga({ payload }) {
  try {
    const response = yield call(creditNote.getSingleCreditNote, payload);
    yield put(actions.getSingleCreditNoteSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getSingleCreditNotesFailed(err));
  }
}

function* createCreditNoteSaga({ payload }) {
  try {
    const response = yield call(creditNote.createCreditNote, payload.data);
    yield call(() => payload.history.push("/bookings/credit-notes"));
    yield put(actions.createCreditNoteSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Credit Note created successfully"));
  } catch (err) {
    yield put(actions.createCreditNoteFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editCreditNoteSaga({ payload }) {
  try {
    const response = yield call(creditNote.editCreditNote, payload.data);
    yield call(payload.history.goBack);
    yield put(actions.editCreditNoteSuccess(response.items));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editCreditNoteFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteCreditNoteSaga({ payload }) {
  try {
    const response = yield call(creditNote.deleteCreditNote, payload.id);
    if (payload.history) {
      yield call(() => payload.history.replace("/bookings/credit-notes"));
    } else {
      yield call(payload.callback);
      yield put(actions.deleteCreditNoteSuccess(response));
    }
    yield put(alertActions.showSuccessAlertAction("Credit Note was successfully deleted"));
  } catch (err) {
    yield put(actions.deleteCreditNoteFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getCreditNotePdfSaga({ payload }) {
  try {
    const response = yield call(creditNote.getCreditNotePdf, payload);

    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank";
    link.click();
    yield put(actions.getCreditNotePdfSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getCreditNotePdfFailed(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_CREDIT_NOTES_REQUEST, getCreditNotesSaga),
    yield takeLatest(types.GET_SINGLE_CREDIT_NOTE_REQUEST, getSingleCreditNoteSaga),
    yield takeLatest(types.CREATE_CREDIT_NOTE_REQUEST, createCreditNoteSaga),
    yield takeLatest(types.EDIT_CREDIT_NOTE_REQUEST, editCreditNoteSaga),
    yield takeLatest(types.DELETE_CREDIT_NOTE_REQUEST, deleteCreditNoteSaga),
    yield takeLatest(types.GET_CREDIT_NOTE_PDF_REQUEST, getCreditNotePdfSaga),
  ]);
}
