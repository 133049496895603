import { Cookies } from "react-cookie";
import jwtDecode from "jwt-decode";

const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");

  return user ? (typeof user === "object" ? user : JSON.parse(user)) : null;
};

const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }

  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");

    return false;
  }

  return true;

};

export { isUserAuthenticated, getLoggedInUser };
