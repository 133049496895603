import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const delegate = {
  async getDelegateList(query) {
    try {
      return await fetch(`${API_URL}/booking_delegates${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async toggleAttendee(id) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${id}/toggle_attendee`, {
        method: "PUT",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async toggleSpam(id) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${id}/toggle_spam`, {
        method: "PUT",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async toggleCanceled(id) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${id}/toggle_canceled`, {
        method: "PUT",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSingleDelegate(id) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${id}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateSingleDelegate(data) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${data.id}`, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getDelegateEvents(query) {
    try {
      return await fetch(`${API_URL}/events${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getDelegateExelFile(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/export_delegates.xlsx${QueryString.stringify(data.query)}`, {
        headers: {
          "Content-Type": "application/xlsx"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default delegate;
