import * as types from "./delegateActionTypes";


export const getDelegateListRequest = (query) => ({
  type: types.GET_DELEGATE_LIST_REQUEST, payload: query
});

export const getDelegateListSuccess = data => ({
  type: types.GET_DELEGATE_LIST_SUCCESS, payload: data
});

export const getDelegateListFailed = error => ({
  type: types.GET_DELEGATE_LIST_FAILED, payload: error
});

export const getSingleDelegateRequest = id => ({
  type: types.GET_SINGLE_DELEGATE_REQUEST, payload: id
});

export const getSingleDelegateSuccess = data => ({
  type: types.GET_SINGLE_DELEGATE_SUCCESS, payload: data
});

export const getSingleDelegateFailed = error => ({
  type: types.GET_SINGLE_DELEGATE_FAILED, payload: error
});

export const updateSingleDelegateRequest = body => ({
  type: types.UPDATE_SINGLE_DELEGATE_REQUEST, payload: body
});

export const updateSingleDelegateSuccess = data => ({
  type: types.UPDATE_SINGLE_DELEGATE_SUCCESS, payload: data
});

export const updateSingleDelegateFailed = error => ({
  type: types.UPDATE_SINGLE_DELEGATE_FAILED, payload: error
});

export const toggleAttendeeRequest = id => ({
  type: types.TOGGLE_ATTENDEE_REQUEST, payload: id
});

export const toggleAttendeeSuccess = data => ({
  type: types.TOGGLE_ATTENDEE_SUCCESS, payload: data
});

export const toggleAttendeeFailed = error => ({
  type: types.TOGGLE_ATTENDEE_FAILED, payload: error
});

export const toggleSpamRequest = id => ({
  type: types.TOGGLE_SPAM_REQUEST, payload: id
});

export const toggleSpamSuccess = data => ({
  type: types.TOGGLE_SPAM_SUCCESS, payload: data
});

export const toggleSpamFailed = error => ({
  type: types.TOGGLE_SPAM_FAILED, payload: error
});

export const toggleCanceledRequest = (id, callback) => ({
  type: types.TOGGLE_CANCELED_REQUEST, payload: { id, callback }
});

export const toggleCanceledSuccess = data => ({
  type: types.TOGGLE_CANCELED_SUCCESS, payload: data
});

export const toggleCanceledFailed = error => ({
  type: types.TOGGLE_CANCELED_FAILED, payload: error
});

export const getDelegateEventsRequest = query => ({
  type: types.GET_DELEGATE_EVENTS_REQUEST, payload: query
});

export const getDelegateEventsSuccess = data => ({
  type: types.GET_DELEGATE_EVENTS_SUCCESS, payload: data
});

export const getDelegateEventsFailed = error => ({
  type: types.GET_DELEGATE_EVENTS_FAILED, payload: error
});

export const getDelegateExelFileRequest = (id, query, filename, callback) => ({
  type: types.GET_DELEGATE_EXEL_FILE_REQUEST, payload: { id, query, filename, callback }
});

export const getDelegateExelFileSuccess = data => ({
  type: types.GET_DELEGATE_EXEL_FILE_SUCCESS, payload: data
});

export const getDelegateExelFileFailed = error => ({
  type: types.GET_DELEGATE_EXEL_FILE_FAILED, payload: error
});
