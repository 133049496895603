import {
  CURRENCY_OPTIONS,
  EMAIL_VALIDATION_PATTERN,
  PREFIX_TO_NAME_OPTIONS,
  REQUIRED_RULE, TRUE_FALSE_RADIOS
} from "../helpers/constants";

const ACCOUNT_CODE_RADIOS = [
  { value: "sponsorship_202", label: "Sponsorship (202)" },
  { value: "advertising_203", label: "Advertising (203)" },
  { value: "admission_201", label: "Admission (201)" },
  { value: "other_income_204", label: "Other Income (204)" }
];

const TAX_TYPE_OPTIONS = [
  { value: "belgium_output_vat", label: "Belgium Output VAT" },
  { value: "uk_output_vat", label: "UK Output VAT" },
  { value: "zero_rated_income", label: "Zero Rated Income" },
];

export const CREATE_EDIT_INVOICE_FIELDS = [
  {
    name: "event_ids",
    label: "Event",
    groupClass: "col-12 form-group",
    options: [],
    asterisk: "*",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "date_at",
    label: "Invoice Date",
    placeholder: "dd/mm/yyyy",
    groupClass: "col-md-6 form-group",
    asterisk: "*",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "date_format_id",
    label: "Date Format",
    placeholder: "Select date format...",
    groupClass: "col-md-6 form-group",
    options: [],
    asterisk: "*",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "title",
    label: "Title",
    placeholder: "Select title...",
    groupClass: "col-md-6 form-group",
    options: PREFIX_TO_NAME_OPTIONS,
    asterisk: "*",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "first_name",
    label: "First Name",
    placeholder: "Type your first name",
    groupClass: "col-md-6 form-group",
    asterisk: "*",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "last_name",
    label: "Family Name",
    placeholder: "Type your last name",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter your email",
    asterisk: "*",
    groupClass: "col-md-6 form-group",
    registerPreference: {
      ...REQUIRED_RULE,
      pattern: EMAIL_VALIDATION_PATTERN
    },
  },
  {
    name: "phone",
    placeholder: "Enter phone number",
    label: "Phone",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "organization",
    label: "Organisation",
    placeholder: "Enter your Organisation",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "position",
    label: "Position",
    placeholder: "Enter your position",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "address",
    placeholder: "Enter address",
    label: "Address",
    groupClass: "col-12 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "city",
    placeholder: "Enter city",
    label: "City/Town",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "postcode",
    placeholder: "Enter postcode",
    label: "Postcode",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "po_number",
    placeholder: "Enter purchase order number",
    label: "Purchase order number",
    groupClass: "col-md-6 form-group",
  },
  {
    name: "country_id",
    placeholder: "Select your country...",
    label: "Country",
    groupClass: "col-md-6 form-group",
    isSearchable: true,
    options: [],
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "vat_country_code",
    placeholder: "Select Country Code",
    label: "VAT Country Code",
    groupClass: "col-md-6 form-group",
    options: []
  },
  {
    name: "vat_number",
    placeholder: "Enter VAT Number",
    label: "VAT Number",
    groupClass: "col-md-6 form-group",
    type: "number"
  },
  {
    name: "vat_type",
    label: "Tax Status",
    asterisk: "*",
    options: TAX_TYPE_OPTIONS,
    groupClass: "col-12 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "vat_exempt",
    label: "VAT Exempt",
    asterisk: "*",
    options: TRUE_FALSE_RADIOS,
    groupClass: "col-md-6 form-group align-radios",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "vat_exempt_reference",
    placeholder: "Type VAT Exempt Reference",
    label: "VAT Exempt Reference",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "details",
    placeholder: "Enter invoice details",
    label: "Invoice details",
    asterisk: "*",
    groupClass: "col-12 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "account_code",
    label: "Account Code",
    asterisk: "*",
    options: ACCOUNT_CODE_RADIOS,
    groupClass: "col-12 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "reverse_charge",
    label: "Reverse charge should apply",
    options: TRUE_FALSE_RADIOS,
    groupClass: "col-12 form-group",
  },
  {
    name: "currency",
    placeholder: "Select currency",
    label: "Currency",
    asterisk: "*",
    options: CURRENCY_OPTIONS,
    groupClass: "col-md-3 form-group",
    registerPreference: REQUIRED_RULE,
  }
];
