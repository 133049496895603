import * as types from "./dotmailerAddressBooksActionTypes";


export const getDotmailerAddressBookListRequest = category => ({
  type: types.GET_DOTMAILER_ADDRESS_BOOK_LIST_REQUEST, payload: category
});

export const getDotmailerAddressBooksSuccess = data => ({
  type: types.GET_DOTMAILER_ADDRESS_BOOK_LIST_SUCCESS, payload: data
});

export const getDotmailerAddressBooksFailed = error => ({
  type: types.GET_DOTMAILER_ADDRESS_BOOK_LIST_FAILED, payload: error
});

export const createDotmailerAddressBookRequest = (data, toggleModal, category) => ({
  type: types.CREATE_DOTMAILER_ADDRESS_BOOK_REQUEST, payload: { data, toggleModal, category }
});

export const createDotmailerAddressBookSuccess = data => ({
  type: types.CREATE_DOTMAILER_ADDRESS_BOOK_SUCCESS, payload: data
});

export const createDotmailerAddressBookFailed = error => ({
  type: types.CREATE_DOTMAILER_ADDRESS_BOOK_FAILED, payload: error
});

export const updateDotmailerAddressBookRequest = (data, toggleModal, category) => ({
  type: types.UPDATE_DOTMAILER_ADDRESS_BOOK_REQUEST, payload: { data, toggleModal, category }
});

export const updateDotmailerAddressBookSuccess = data => ({
  type: types.UPDATE_DOTMAILER_ADDRESS_BOOK_SUCCESS, payload: data
});

export const updateDotmailerAddressBookFailed = error => ({
  type: types.UPDATE_DOTMAILER_ADDRESS_BOOK_FAILED, payload: error
});

export const deleteDotmailerAddressBookRequest = data => ({
  type: types.DELETE_DOTMAILER_ADDRESS_BOOK_REQUEST, payload: data
});

export const deleteDotmailerAddressBookSuccess = data => ({
  type: types.DELETE_DOTMAILER_ADDRESS_BOOK_SUCCESS, payload: data
});

export const deleteDotmailerAddressBookFailed = error => ({
  type: types.DELETE_DOTMAILER_ADDRESS_BOOK_FAILED, payload: error
});

export const selectDotmailerAddressBookAction = data => ({
  type: types.SELECT_DOTMAILER_ADDRESS_BOOK_ACTION, payload: data
});

export const deselectDotmailerAddressBookAction = () => ({
  type: types.DESELECT_DOTMAILER_ADDRESS_BOOK_ACTION,
});
