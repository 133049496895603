import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const event = {
  async getEvents(query) {
    try {
      return await fetch(`${API_URL}/events${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getEventsForDelegate(query) {
    try {
      return await fetch(`${API_URL}/events/with_delegate_bookings${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSingleEvent(id) {
    try {
      return await fetch(`${API_URL}/events/${id}`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async createEvent(data) {
    try {
      return await fetch(`${API_URL}/events`, {
        method: "POST",
        body: JSON.stringify({ event: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventDetails(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventInvoiceLogo(data) {
    try {
      const formData = new FormData();
      formData.append("event[logo]", data.logo);

      return await fetch(`${API_URL}/events/${data.id}/logo`, {
        headers: {
          "Content-Type": null
        },
        method: "PUT",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventVenue(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/venue`, {
        method: "PUT",
        body: JSON.stringify({ event: { venue_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventContact(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/contact`, {
        method: "PUT",
        body: JSON.stringify({ event: { contact_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventAppearance(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/appearance`, {
        method: "PUT",
        body: JSON.stringify({ event: { appearance_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventPreferences(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/preferences/bulk_create`, {
        method: "POST",
        body: JSON.stringify(data.body)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventPreferences(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/preferences/bulk_update`, {
        method: "PUT",
        body: JSON.stringify(data.body)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventBookingField(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/booking_field_settings`, {
        method: "POST",
        body: JSON.stringify({ booking_field_setting: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventBookingField(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/booking_field_settings/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ booking_field_setting: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventInvoiceTemplate(data) {
    try {
      const formData = new FormData();

      Object.keys(data.event).forEach(item => {
        if (item === "logo") {
          const image = data.event.logo;

          if (typeof image === "object") {
            formData.append("event[logo]", image); // in case when need upload a photo
          }
        } else if (typeof data.event[item] !== "undefined") {
          formData.append(`event[${item}]`, data.event[item]);
        }
      });

      return await fetch(`${API_URL}/events/${data.id}/invoice_template`, {
        headers: {
          "Content-Type": null
        },
        method: "PUT",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async previewEventCustomInvoicePdf(id) {
    try {
      return await fetch(`${API_URL}/events/${id}/preview_custom_invoice.pdf`, {
        headers: {
          "Content-Type": "application/pdf"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventFeeGroup(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/fee_groups`, {
        method: "POST",
        body: JSON.stringify({ event: { fee_group_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventFeeGroup(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/fee_groups/${data.group_id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventGroupDiscount(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/group_discounts`, {
        method: "POST",
        body: JSON.stringify({ event: { group_discount_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventGroupDiscount(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/group_discounts/${data.discount_id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventOrganizationType(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/organization_types`, {
        method: "POST",
        body: JSON.stringify({ event: { organization_type_attributes: data } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventOrganizationType(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/organization_types/${data.type_id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { organization_type_attributes: data } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventOrganizationType(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/organization_types/${data.type_id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async crudEventOptionalItem(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/general_optional_item`, {
        method: "PUT",
        body: JSON.stringify({ event: { general_optional_item_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventDiscountCode(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/discount_codes`, {
        method: "POST",
        body: JSON.stringify({ event: { discount_code_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventDiscountCode(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/discount_codes/${data.discount_id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async crudEventSponsorAndSpeaker(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/event_sponsor`, {
        method: "PUT",
        body: JSON.stringify({ event: { ...data } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventAgendaBreakout(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/breakouts`, {
        method: "POST",
        body: JSON.stringify({ event: { breakout_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventAgendaBreakout(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/breakouts/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { breakout_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventAgendaBreakout(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/breakouts/${data.id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventAgendaSession(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/sessions`, {
        method: "POST",
        body: JSON.stringify({ event: { session_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editEventAgendaSession(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/sessions/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { session_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventAgendaSessionApi(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/sessions/${data.session_id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventAdditionalFieldGroup(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_fields_groups`, {
        method: "POST",
        body: JSON.stringify({ event: { additional_fields_group_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventAdditionalFieldGroup(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_fields_groups/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { additional_fields_group_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventAdditionalFieldGroup(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_fields_groups/${data.id}`, {
        method: "DELETE"
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventAdditionalField(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_registration_fields`, {
        method: "POST",
        body: JSON.stringify({ event: { additional_registration_field_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventAdditionalField(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_registration_fields/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { additional_registration_field_attributes: { ...data } } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventAdditionalField(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/additional_registration_fields/${data.id}`, {
        method: "DELETE"
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async assignEventManager(data) {
    try {
      return await fetch(`${API_URL}/events/${data.event_id}/update_event_manager`, {
        method: "PUT",
        body: JSON.stringify({ event: { user_id: data.user_id } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getEventOrganizationTypes(id) {
    try {
      return await fetch(`${API_URL}/events/${id}/organization_types`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async getEventBreakouts(id) {
    try {
      return await fetch(`${API_URL}/events/${id}/breakouts`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async updateEmailTemplates(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/email_templates`, {
        method: "PUT",
        body: JSON.stringify(data.body)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async getSingleEmailTemplate(eventId, id) {
    try {
      return await fetch(`${API_URL}/events/${eventId}/email_templates/${id}`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async updateSingleEmailTemplate(eventId, id, data) {
    try {
      return await fetch(`${API_URL}/events/${eventId}/email_templates/${id}`, {
        method: "PUT",
        body: JSON.stringify(data)
      })
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async sendEventEmailInfo(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/info_email`, {
        method: "POST",
        body: JSON.stringify({ id: data.id, event: { name: data.type } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async archiveEventApi(id) {
    try {
      return await fetch(`${API_URL}/events/${id}/archive`, {
        method: "PUT",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async getEventOptionsPolicy() {
    try {
      return await fetch(`${API_URL}/events/new`)
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async saveEventAsDuplicate(id) {
    try {
      return await fetch(`${API_URL}/events/${id}/duplicate`, {
        method: "PUT"
      })
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  }
};

export default event;
