import { combineReducers } from "redux";

import alert from "./alert/alertReducer";
import global from "./global/globalReducer";
import auth from "./auth/authReducer";
import event from "./event/eventReducer";
import plugin from "./plugin/pluginReducer";
import dashboard from "./dashboard/dashboardReducer";
import booking from "./booking/bookingReducer";
import delegate from "./delegate/delegateReducer";
import invoice from "./invoice/invoiceReducer";
import creditNote from "./creditNote/creditNoteReducer";
import management from "./management/managementReducer";
import countries from "./preference/countries/countriesReducer";
import dateFormats from "./preference/dateFormats/dateFormatsReducer";
import dotmailerAddressBooks from "./preference/dotmailerAddressBooks/dotmailerAddressBooksReducer";
import sponsors from "./preference/sponsors/sponsorsReducer";
import sponsorshipLevel from "./preference/sponsorshipLevel/sponsorshipLevelReducer";


export default combineReducers({
  alert,
  global,
  auth,
  event,
  plugin,
  dashboard,
  booking,
  delegate,
  invoice,
  creditNote,
  management,
  preference: combineReducers({
    countries,
    dateFormats,
    dotmailerAddressBooks,
    sponsors,
    sponsorshipLevel
  })
});
