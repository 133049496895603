export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const SUSPEND_USER_REQUEST = "SUSPEND_USER_REQUEST";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILED = "SUSPEND_USER_FAILED";

export const UNBLOCK_SUSPENDED_USER_REQUEST = "UNBLOCK_SUSPENDED_USER_REQUEST";
export const UNBLOCK_SUSPENDED_USER_SUCCESS = "UNBLOCK_SUSPENDED_USER_SUCCESS";
export const UNBLOCK_SUSPENDED_USER_FAILED = "UNBLOCK_SUSPENDED_USER_FAILED";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILED = "INVITE_USER_FAILED";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const SELECT_USER_ACTION = "SELECT_USER_ACTION";
export const CLEAR_USER_ERROR_ACTION = "CLEAR_USER_ERROR_ACTION";
