export const GET_DATE_FORMATS_REQUEST = "GET_DATE_FORMATS_REQUEST";
export const GET_DATE_FORMATS_SUCCESS = "GET_DATE_FORMATS_SUCCESS";
export const GET_DATE_FORMATS_FAILED = "GET_DATE_FORMATS_FAILED";

export const CREATE_DATE_FORMAT_REQUEST = "CREATE_DATE_FORMAT_REQUEST";
export const CREATE_DATE_FORMAT_SUCCESS = "CREATE_DATE_FORMAT_SUCCESS";
export const CREATE_DATE_FORMAT_FAILED = "CREATE_DATE_FORMAT_FAILED";

export const DELETE_DATE_FORMAT_REQUEST = "DELETE_DATE_FORMAT_REQUEST";
export const DELETE_DATE_FORMAT_SUCCESS = "DELETE_DATE_FORMAT_SUCCESS";
export const DELETE_DATE_FORMAT_FAILED = "DELETE_DATE_FORMAT_FAILED";

export const EDIT_DATE_FORMAT_REQUEST = "EDIT_DATE_FORMAT_REQUEST";
export const EDIT_DATE_FORMAT_SUCCESS = "EDIT_DATE_FORMAT_SUCCESS";
export const EDIT_DATE_FORMAT_FAILED = "EDIT_DATE_FORMAT_FAILED";

export const SELECT_DATE_FORMAT_ACTION = "SELECT_DATE_FORMAT_ACTION";
export const DESELECT_DATE_FORMAT_ACTION = "DESELECT_DATE_FORMAT_ACTION";
