export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const LOGIN_USER_VIA_MAGIC_LINK_REQUEST = "LOGIN_USER_VIA_MAGIC_LINK_REQUEST";
export const LOGIN_USER_VIA_MAGIC_LINK_SUCCESS = "LOGIN_USER_VIA_MAGIC_LINK_SUCCESS";
export const LOGIN_USER_VIA_MAGIC_LINK_FAILED = "LOGIN_USER_VIA_MAGIC_LINK_FAILED";

export const GET_USER_VIA_MAGIC_LINK_REQUEST = "GET_USER_VIA_MAGIC_LINK_REQUEST";
export const GET_USER_VIA_MAGIC_LINK_SUCCESS = "GET_USER_VIA_MAGIC_LINK_SUCCESS";
export const GET_USER_VIA_MAGIC_LINK_FAILED = "GET_USER_VIA_MAGIC_LINK_FAILED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

export const REGISTER_USER_VIA_INVITATION_REQUEST = "REGISTER_USER_VIA_INVITATION_REQUEST";
export const REGISTER_USER_VIA_INVITATION_SUCCESS = "REGISTER_USER_VIA_INVITATION_SUCCESS";
export const REGISTER_USER_VIA_INVITATION_FAILED = "REGISTER_USER_VIA_INVITATION_FAILED";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";

export const UPDATE_MY_PROFILE_REQUEST = "UPDATE_MY_PROFILE_REQUEST";
export const UPDATE_MY_PROFILE_SUCCESS = "UPDATE_MY_PROFILE_SUCCESS";
export const UPDATE_MY_PROFILE_FAILED = "UPDATE_MY_PROFILE_FAILED";

export const GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST";

export const LOGOUT_USER_ACTION = "LOGOUT_USER_ACTION";
export const CLEAR_AUTH_ERROR_ACTION = "CLEAR_AUTH_ERROR_ACTION";
