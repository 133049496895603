import { all, call, put, takeLatest, select } from "redux-saga/effects";

import preference from "../../../api/preference";

import * as types from "./sponsorshipLevelActionTypes";
import * as actions from "./sponsorshipLevelActions";
import * as alertActions from "../../alert/alertActions";


function* getSponsorshipLevelsSaga({ payload }) {
  try {
    const response = yield call(preference.getSponsorshipLevels, payload);
    yield put(actions.getSponsorshipLevelsSuccess(response));
  } catch (err) {
    yield put(actions.getSponsorshipLevelsFailed(err));
  }
}

function* createSponsorshipLevelSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.sponsorshipLevel.items);
    const response = yield call(preference.createSponsorshipLevel, payload.data);

    yield put(actions.createSponsorshipLevelSuccess({ items: [...items, response] }));
    yield call(() => {
      payload.toggleModal()
    });

  } catch (err) {
    yield put(actions.createSponsorshipLevelFailed(err));
  }
}

function* deleteSponsorshipLevelSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.sponsorshipLevel.items);
    const response = yield call(preference.deleteSponsorshipLevel, payload);

    const newItems = items.filter(item => item.id !== response.id);
    yield put(actions.deleteSponsorshipLevelSuccess({ items: newItems }));

  } catch (err) {
    yield put(actions.deleteSponsorshipLevelFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editSponsorshipLevelSaga({ payload }) {
  try {
    yield call(preference.editSponsorshipLevel, payload.data);
    yield put(actions.editSponsorshipLevelSuccess());
    yield put(actions.getSponsorshipLevelsRequest());
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.editSponsorshipLevelFailed(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_SPONSORSHIP_LEVELS_REQUEST, getSponsorshipLevelsSaga),
    yield takeLatest(types.CREATE_SPONSORSHIP_LEVEL_REQUEST, createSponsorshipLevelSaga),
    yield takeLatest(types.EDIT_SPONSORSHIP_LEVEL_REQUEST, editSponsorshipLevelSaga),
    yield takeLatest(types.DELETE_SPONSORSHIP_LEVEL_REQUEST, deleteSponsorshipLevelSaga),
  ]);
}
