import { all, call, put, select, takeLatest } from "redux-saga/effects";

import booking from "../../api/booking";
import event from "../../api/event";
import management from "../../api/management";
import * as types from "./bookingActionTypes";
import * as actions from "./bookingActions";
import * as alertActions from "../alert/alertActions";
import * as eventsActions from "../event/eventActions";
import * as globalActions from "../global/globalActions";

import { REQUEST_SUCCESS_MESSAGE } from "../../constants";


function* getBookingsSaga({ payload }) {
  try {
    const response = yield call(booking.getBookings, payload);
    yield put(actions.getBookingsSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getBookingsFailed(err));
  }
}

function* editBookingSaga({ payload }) {
  try {
    const response = yield call(booking.editBooking, payload.data);
    yield put(actions.editBookingSuccess(response));
    yield put(actions.getBookingsRequest({ event_id: response.event_id, status: "", search: "" }));
    if (payload.path) {
      yield call(() => payload.history.replace(payload.path));
    } else if (payload.data.action === "registered") {
      yield call(() => payload.history.replace(`/bookings/bookings-list/${response.id}/payment-details`));
    }
  } catch (err) {
    yield put(alertActions.showFailedAlertAction((err[0] && err[0].email && err[0].email[0]) || err));
    yield put(actions.editBookingFailed(err));
  }
}

function* deleteBookingSaga({ payload }) {
  try {
    const userRole = yield select(store => store.auth.user.role);
    const response = yield call(booking.deleteBooking, payload);
    if (userRole === "admin") {
      yield put(actions.getBookingsRequest({ event_id: response.event_id }));
    } else {
      yield put(actions.getBookingsRequest());
    }
    yield put(actions.deleteBookingSuccess(response));
    yield call(payload.callback);
    yield put(alertActions.showSuccessAlertAction("Booking deleted successfully!"));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.deleteBookingFailed(err));
  }
}

function* updateBookingStatusSaga({ payload }) {
  try {
    const response = yield call(booking.editBooking, payload.data);
    yield put(actions.editBookingSuccess(response));
    yield put(actions.getBookingsRequest({ event_id: response.event_id, status: "", search: "" }));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.editBookingFailed(err));
  }
}

function* getSingleBookingSaga({ payload }) {
  try {
    const response = yield call(booking.getSingleBooking, payload);

    yield put(actions.getSingleBookingSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getSingleBookingFailed(err));
  }
}

function* createNoteSaga({ payload }) {
  try {
    const response = yield call(booking.createNote, payload.data);
    yield put(actions.createNoteSuccess(response));
    yield call(payload.history.goBack);
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.createNoteFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editNoteSaga({ payload }) {
  try {
    const response = yield call(booking.editNote, payload.data);
    yield put(actions.editNoteSuccess(response));
    yield call(payload.history.goBack);
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.editNoteFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getUserByEmailSaga({ payload }) {
  try {
    const response = payload.role === "admin"
      ? yield call(booking.getUserByEmail, payload.email)
      : yield call(management.getUser, payload.id);
    yield put(actions.getUserByEmailBookingSuccess(response));
  } catch (err) {
    if (err.error && err.error[0] === "record not found") {
      yield put(alertActions.showFailedAlertAction("User not found"));
    }
    yield put(actions.getUserByEmailBookingFailed(err));
  }
}

function* createBookingSaga({ payload }) {
  try {
    const response = yield call(booking.createBooking, payload.data);
    yield put(actions.createBookingSuccess(response));
    yield call(() => payload.history.replace(`/bookings/bookings-list/${response.id}/delegate-info`));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err.email ? err.email[0] : err.user_email ? err.user_email[0] : err));
    yield put(actions.createBookingFailed(err));
  }
}

function* updateBookingSaga({ payload }) {
  try {
    const response = yield call(booking.updateBooking, payload.data);
    yield put(actions.createBookingSuccess(response));
    yield call(() => payload.history.replace(`/bookings/bookings-list/${response.id}/delegate-info`));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err.email ? err.email[0] : err));
    yield put(actions.createBookingFailed(err));
  }
}

function* getSingleBookingInfoSaga({ payload }) {
  try {
    const response = yield call(booking.getSingleBooking, payload);
    const responseEvent = yield call(event.getSingleEvent, response.event_id);

    yield put(actions.getSingleBookingSuccess(response));
    yield put(eventsActions.getSingleEventSuccess(responseEvent));
    yield put(globalActions.setSelectedEventAction(responseEvent));

    yield put(eventsActions.getBreakoutsRequest(response.event_id));
    yield put(eventsActions.getOrganizationTypesRequest(response.event_id));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getSingleBookingFailed(err));
  }
}

function* addBookingDiscountSaga({ payload }) {
  try {
    const response = yield call(booking.addBookingDiscountApi, payload);
    yield put(actions.proceedBookingDelegateRequest(response.id));
    yield put(alertActions.showSuccessAlertAction("Discount code successfully applied"));
  } catch (err) {
    yield put(actions.getSingleBookingFailed(err));
  }
}

function* updateBookingDiscountSaga({ payload }) {
  try {
    const response = yield call(booking.updateBookingDiscountApi, payload);
    yield put(actions.proceedBookingDelegateRequest(response.id));
    yield put(alertActions.showSuccessAlertAction("Discount code successfully updated"));
  } catch (err) {
    yield put(actions.getSingleBookingFailed(err));
  }
}

function* deleteBookingDiscountSaga({ payload }) {
  try {
    const response = yield call(booking.deleteBookingDiscountApi, payload);
    yield put(actions.proceedBookingDelegateRequest(response.id));
    yield put(alertActions.showSuccessAlertAction("Discount code successfully deleted"));
  } catch (err) {
    yield put(actions.getSingleBookingFailed(err));
  }
}

function* remindEmailAboutBookingSaga({ payload }) {
  try {
    const response = yield call(booking.remindEmailAboutBooking, payload.id);
    yield call(payload.callback);
    yield put(actions.remindEmailAboutBookingSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Remind sent successfully!"));
  } catch (err) {
    yield put(actions.remindEmailAboutBookingFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

/************************************************* BOOKING DELEGATE ***************************************************/

function* getBookingDelegatesSaga({ payload }) {
  try {
    const response = yield call(booking.getBookingDelegates, payload);
    yield put(actions.getBookingDelegatesSuccess(response.items));
  } catch (err) {
    yield put(actions.getBookingDelegatesFailed(err));
  }
}

function* createBookingDelegateSaga({ payload }) {
  try {
    yield call(booking.createBookingDelegate, payload.data);
    yield put(actions.proceedBookingDelegateRequest(payload.data.bookingId));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.createBookingDelegateFailed(err));
  }
}

function* editBookingDelegateSaga({ payload }) {
  try {
    yield call(booking.editBookingDelegate, payload.data);
    yield put(actions.proceedBookingDelegateRequest(payload.data.bookingId));
    yield call(payload.callback);
  } catch (err) {
    yield put(actions.editBookingDelegateFailed(err));
  }
}

function* deleteBookingDelegateSaga({ payload }) {
  try {
    const response = yield call(booking.deleteBookingDelegate, payload);
    yield put(actions.proceedBookingDelegateRequest(response.booking_id));
  } catch (err) {
    yield put(actions.deleteBookingDelegateFailed(err));
  }
}

function* proceedBookingDelegateSaga({ payload }) {
  try {
    const response = yield call(booking.proceedBookingDelegate, payload);
    yield put(actions.proceedBookingDelegateSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.proceedBookingDelegateFailed(err));
  }
}

function* getBookingExelFileSaga({ payload }) {
  try {
    const response = yield call(booking.getBookingExelFile, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = payload.filename || "export-bookings.xlsx";
    link.click();
    yield put(actions.getBookingExelFileSuccess(response));
    yield call(payload.callback);
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getBookingExelFileFailed(err));
  }
}

function* initBookingPaymentSaga({ payload }) {
  try {
    const response = yield call(booking.initBookingPayment, payload);
    yield put(actions.initBookingPaymentSuccess(response));
  } catch (err) {
    yield put(actions.initBookingPaymentFailed(err));
    yield put(alertActions.showFailedAlertAction("Payment initializing failed."));
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.CREATE_BOOKING_REQUEST, createBookingSaga),
    yield takeLatest(types.CREATE_NOTE_REQUEST, createNoteSaga),
    yield takeLatest(types.EDIT_BOOKING_REQUEST, editBookingSaga),
    yield takeLatest(types.DELETE_BOOKING_REQUEST, deleteBookingSaga),
    yield takeLatest(types.UPDATE_BOOKING_STATUS_REQUEST, updateBookingStatusSaga),
    yield takeLatest(types.UPDATE_BOOKING_REQUEST, updateBookingSaga),
    yield takeLatest(types.EDIT_NOTE_REQUEST, editNoteSaga),
    yield takeLatest(types.GET_SINGLE_BOOKING_INFO_REQUEST, getSingleBookingInfoSaga),
    yield takeLatest(types.GET_BOOKINGS_REQUEST, getBookingsSaga),
    yield takeLatest(types.GET_SINGLE_BOOKING_REQUEST, getSingleBookingSaga),
    yield takeLatest(types.GET_USER_BY_EMAIL_BOOKING_REQUEST, getUserByEmailSaga),
    yield takeLatest(types.ADD_BOOKING_DISCOUNT_REQUEST, addBookingDiscountSaga),
    yield takeLatest(types.UPDATE_BOOKING_DISCOUNT_REQUEST, updateBookingDiscountSaga),
    yield takeLatest(types.DELETE_BOOKING_DISCOUNT_REQUEST, deleteBookingDiscountSaga),
    yield takeLatest(types.REMIND_EMAIL_ABOUT_BOOKING_REQUEST, remindEmailAboutBookingSaga),

    yield takeLatest(types.GET_BOOKING_DELEGATES_REQUEST, getBookingDelegatesSaga),
    yield takeLatest(types.CREATE_BOOKING_DELEGATE_REQUEST, createBookingDelegateSaga),
    yield takeLatest(types.EDIT_BOOKING_DELEGATE_REQUEST, editBookingDelegateSaga),
    yield takeLatest(types.DELETE_BOOKING_DELEGATE_REQUEST, deleteBookingDelegateSaga),
    yield takeLatest(types.PROCEED_BOOKING_DELEGATE_REQUEST, proceedBookingDelegateSaga),
    yield takeLatest(types.GET_BOOKING_EXEL_FILE_REQUEST, getBookingExelFileSaga),

    yield takeLatest(types.INIT_BOOKING_PAYMENT_REQUEST, initBookingPaymentSaga),
  ]);
}
