import * as types from "./authActionTypes";


export const loginUserRequest = (email, password) => ({
  type: types.LOGIN_USER_REQUEST, payload: { user: { email, password } }
});

export const loginUserSuccess = (user) => ({
  type: types.LOGIN_USER_SUCCESS, payload: user
});

export const loginUserFailed = (error) => ({
  type: types.LOGIN_USER_FAILED, payload: error
});

export const loginUserViaMagicLinkRequest = (email, redirect_url) => ({
  type: types.LOGIN_USER_VIA_MAGIC_LINK_REQUEST, payload: { email, redirect_url }
});

export const loginUserViaMagicLinkSuccess = magicLinkStatus => ({
  type: types.LOGIN_USER_VIA_MAGIC_LINK_SUCCESS, payload: magicLinkStatus
});

export const loginUserViaMagicLinkFailed = error => ({
  type: types.LOGIN_USER_VIA_MAGIC_LINK_FAILED, payload: error
});

export const getUserViaMagicLinkRequest = (query, history) => ({
  type: types.GET_USER_VIA_MAGIC_LINK_REQUEST, payload: { query, history }
});

export const getUserViaMagicLinkSuccess = user => ({
  type: types.GET_USER_VIA_MAGIC_LINK_SUCCESS, payload: user
});

export const getUserViaMagicLinkFailed = error => ({
  type: types.GET_USER_VIA_MAGIC_LINK_FAILED, payload: error
});

export const resetPasswordRequest = (data, history) => ({
  type: types.RESET_PASSWORD_REQUEST, payload: { data, history }
});

export const resetPasswordSuccess = data => ({
  type: types.RESET_PASSWORD_SUCCESS, payload: data
});

export const resetPasswordFailed = data => ({
  type: types.RESET_PASSWORD_FAILED, payload: data
});

export const registerUserRequest = (data, history) => ({
  type: types.REGISTER_USER_REQUEST, payload: { data, history }
});

export const registerUserSuccess = (user, message) => ({
  type: types.REGISTER_USER_SUCCESS, payload: { user, message }
});

export const registerUserFailed = (error) => ({
  type: types.REGISTER_USER_FAILED, payload: error
});

export const registerUserViaInvitationRequest = (data, history) => ({
  type: types.REGISTER_USER_VIA_INVITATION_REQUEST, payload: { data, history }
});

export const registerUserViaInvitationSuccess = (user, message) => ({
  type: types.REGISTER_USER_VIA_INVITATION_SUCCESS, payload: { user, message }
});

export const registerUserViaInvitationFailed = (error) => ({
  type: types.REGISTER_USER_VIA_INVITATION_FAILED, payload: error
});

export const forgetPasswordRequest = (email, redirect_url) => ({
  type: types.FORGET_PASSWORD_REQUEST, payload: { user: { email, redirect_url } }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: types.FORGET_PASSWORD_SUCCESS, payload: passwordResetStatus
});

export const forgetPasswordFailed = (error) => ({
  type: types.FORGET_PASSWORD_FAILED, payload: error
});

export const getMyProfileRequest = (id) => ({
  type: types.GET_MY_PROFILE_REQUEST, payload: id
});

export const updateMyProfileRequest = (body) => ({
  type: types.UPDATE_MY_PROFILE_REQUEST, payload: { body }
});

export const updateMyProfileSuccess = (data) => ({
  type: types.UPDATE_MY_PROFILE_SUCCESS, payload: data
});

export const updateMyProfileFailed = (error) => ({
  type: types.UPDATE_MY_PROFILE_FAILED, payload: error
});

export const logoutUserAction = (history) => ({
  type: types.LOGOUT_USER_ACTION, payload: { history }
});

export const clearAuthErrorAction = () => ({
  type: types.CLEAR_AUTH_ERROR_ACTION
});
