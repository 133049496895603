export const REQUIRED_RULE = { required: { message: "Field is required" } };

export const URL_VALIDATION_PATTERN = { value: /(http|https):\/\/[^\s]+\.[^\s]+/i, message: "Invalid url address" };
export const EMAIL_VALIDATION_PATTERN = { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Invalid email address" };
export const PHONE_VALIDATION_PATTERN = { value: /^\+/i, message: "Invalid phone number" };

export const CONTACT_PERSON_FIELDS = ["email", "title", "first_name", "last_name", "organization", "position", "phone"];
export const BILLING_INFO_FIELDS = ["address", "additional_address", "city", "po_number", "postcode", "country_id", "belgian_vat_number",
  "vat_exempt_reference", "hear_about_event", "option", "vat_exempt", "accept", "vat_country_code", "vat_number"
];

export const CURRENCY_OPTIONS = [
  { value: "euro", label: "EUR(€)", symbol: "€" },
  { value: "gbp", label: "GBR(£)", symbol: "£" },
  { value: "usd", label: "USD($)", symbol: "$" },
  { value: "canada", label: "CAD(C$)", symbol: "C$" }
];

export const PREFIX_TO_NAME_OPTIONS = [
  { value: "mr", label: "Mr" },
  { value: "ms", label: "Ms" },
  { value: "mrs", label: "Mrs" },
  { value: "miss", label: "Miss" },
  { value: "mx", label: "Mx" },
  { value: "dr", label: "Dr" }
];

export const PREFIX_TO_NAME_HASH = {
  mr: "Mr",
  ms: "Ms",
  mrs: "Mrs",
  miss: "Miss",
  mx: "Mx",
  dr: "Dr",
};

export const TRUE_FALSE_RADIOS = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const TEXT_COLOR_RADIOS = [
  { value: "#ffffff", label: "Light color" },
  { value: "#323a46", label: "Dark color" }
];

export const PAYMENT_TYPES = {
  credit_card: "Credit Card",
  bank_transfer: "Bank Transfer",
};

export const EVENT_GROUP_OPTIONS = [
  { id: 1, value: "", label: "All" },
  { id: 2, value: "client", label: "Client Events" },
  { id: 3, value: "own_initiative", label: "Own Initiative Events" },
  { id: 4, value: "spectrum", label: "Spectrum Events" }
];

export const EVENT_ADMIN_STATUS_OPTIONS = [
  { id: 1, value: "", label: "All" },
  { id: 2, value: "not_published", label: "Not published" },
  { id: 3, value: "registration_open", label: "Registration open" },
  { id: 4, value: "registration_closed", label: "Registration closed" },
  { id: 5, value: "live", label: "Event is live" },
  { id: 6, value: "archived", label: "Archived" }
];

export const EVENT_OTHER_STATUS_OPTIONS = [
  { id: 7, value: "", label: "All" },
  { id: 8, value: "registration_open", label: "Registration open" },
  { id: 9, value: "registration_closed", label: "Registration closed" },
  { id: 0, value: "live", label: "Event is live" },
];

export const BOOKING_OPTIONS = [
  { id: 5, value: "", label: "All" },
  { id: 0, value: "incomplete", label: "Incomplete" },
  { id: 1, value: "registered", label: "Registered" },
  { id: 2, value: "applied", label: "Applied" },
  { id: 3, value: "confirmed", label: "Confirmed" },
  { id: 4, value: "rejected", label: "Rejected" },
];

export const EVENT_ORGANIZATION_TYPES_OPTIONS = [
  "Corporate Organisations",
  "NGO / Not for Profit",
  "Academic / Student",
  "National Government / Regulator",
  "Diplomatic Missions to the EU",
  "European Commission / Parliament / Council",
  "EU Permanent Representation",
  "Press / Journalist"
];

export const BOOKING_FORM_FIELD_SETTINGS = {
  contact_person: {
    title: { name: "title", mandatory: true, visible: true, key: "contact_person_title" },
    first_name: { name: "first_name", mandatory: true, visible: true, key: "contact_person_first_name" },
    last_name: { name: "last_name", mandatory: true, visible: true, key: "contact_person_last_name" },
    email: { name: "email", mandatory: true, visible: true, key: "contact_person_email" },
    email_confirmation: { name: "email_confirmation", mandatory: true, visible: true, key: "contact_person_email_confirmation" },
    organization: { name: "organization", mandatory: true, visible: true, key: "contact_person_organization" },
    position: { name: "position", mandatory: false, visible: true, key: "contact_person_position" },
  },
  booking: {
    country_id: { name: "country_id", mandatory: true, visible: true, key: "booking_country_id" },
    address: { name: "address", mandatory: false, visible: true, key: "booking_address" },
    additional_address: { name: "additional_address", mandatory: false, visible: true, key: "booking_additional_address" },
    city: { name: "city", mandatory: false, visible: true, key: "booking_city" },
    postcode: { name: "postcode", mandatory: false, visible: true, key: "booking_postcode" },
    phone: { name: "phone", mandatory: false, visible: true, key: "booking_phone" },
    vat_country_code: { name: "vat_country_code", mandatory: false, visible: true, key: "booking_vat_country_code" },
    vat_number: { name: "vat_number", mandatory: false, visible: true, key: "booking_vat_number" },
    belgian_vat_number: { name: "belgian_vat_number", mandatory: false, visible: true, key: "booking_belgian_vat_number" },
    hear_about_event: { name: "hear_about_event", mandatory: false, visible: true, key: "booking_hear_about_event" },
  },
  booking_delegate: {
    organization_type_id: { name: "organization_type_id", mandatory: true, visible: true, key: "booking_delegate_organization_type_id" },
    special_needs: { name: "special_needs", mandatory: false, visible: true, key: "booking_delegate_special_needs" },
    po_number: { name: "po_number", mandatory: false, visible: true, key: "booking_delegate_po_number" },
    online_attending: { name: "online_attending", mandatory: false, visible: true, key: "booking_delegate_online_attending" }
  }
};
