export const GET_DOTMAILER_ADDRESS_BOOK_LIST_REQUEST = "GET_DOTMAILER_ADDRESS_BOOK_LIST_REQUEST";
export const GET_DOTMAILER_ADDRESS_BOOK_LIST_SUCCESS = "GET_DOTMAILER_ADDRESS_BOOK_LIST_SUCCESS";
export const GET_DOTMAILER_ADDRESS_BOOK_LIST_FAILED = "GET_DOTMAILER_ADDRESS_BOOKS_FAILED";

export const CREATE_DOTMAILER_ADDRESS_BOOK_REQUEST = "CREATE_DOTMAILER_ADDRESS_BOOK_REQUEST";
export const CREATE_DOTMAILER_ADDRESS_BOOK_SUCCESS = "CREATE_DOTMAILER_ADDRESS_BOOK_SUCCESS";
export const CREATE_DOTMAILER_ADDRESS_BOOK_FAILED = "CREATE_DOTMAILER_ADDRESS_BOOK_FAILED";

export const UPDATE_DOTMAILER_ADDRESS_BOOK_REQUEST = "UPDATE_DOTMAILER_ADDRESS_BOOK_REQUEST";
export const UPDATE_DOTMAILER_ADDRESS_BOOK_SUCCESS = "UPDATE_DOTMAILER_ADDRESS_BOOK_SUCCESS";
export const UPDATE_DOTMAILER_ADDRESS_BOOK_FAILED = "UPDATE_DOTMAILER_ADDRESS_BOOK_FAILED";

export const DELETE_DOTMAILER_ADDRESS_BOOK_REQUEST = "DELETE_DOTMAILER_ADDRESS_BOOK_REQUEST";
export const DELETE_DOTMAILER_ADDRESS_BOOK_SUCCESS = "DELETE_DOTMAILER_ADDRESS_BOOK_SUCCESS";
export const DELETE_DOTMAILER_ADDRESS_BOOK_FAILED = "DELETE_DOTMAILER_ADDRESS_BOOK_FAILED";

export const SELECT_DOTMAILER_ADDRESS_BOOK_ACTION = "SELECT_DOTMAILER_ADDRESS_BOOK_ACTION";
export const DESELECT_DOTMAILER_ADDRESS_BOOK_ACTION = "DESELECT_DOTMAILER_ADDRESS_BOOK_ACTION";
