export const GET_ISO_COUNTRIES_REQUEST = "GET_ISO_COUNTRIES_REQUEST";
export const GET_ISO_COUNTRIES_SUCCESS = "GET_ISO_COUNTRIES_SUCCESS";
export const GET_ISO_COUNTRIES_FAILED = "GET_ISO_COUNTRIES_FAILED";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export const GET_COUNTRIES_OPTIONS_REQUEST = "GET_COUNTRIES_OPTIONS_REQUEST";
export const GET_COUNTRIES_OPTIONS_SUCCESS = "GET_COUNTRIES_OPTIONS_SUCCESS";
export const GET_COUNTRIES_OPTIONS_FAILED = "GET_COUNTRIES_OPTIONS_FAILED";

export const CREATE_COUNTRY_REQUEST = "CREATE_COUNTRY_REQUEST";
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS";
export const CREATE_COUNTRY_FAILED = "CREATE_COUNTRY_FAILED";

export const DELETE_COUNTRY_REQUEST = "DELETE_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAILED = "DELETE_COUNTRY_FAILED";

export const EDIT_COUNTRY_REQUEST = "EDIT_COUNTRY_REQUEST";
export const EDIT_COUNTRY_SUCCESS = "EDIT_COUNTRY_SUCCESS";
export const EDIT_COUNTRY_FAILED = "EDIT_COUNTRY_FAILED";

export const SELECT_COUNTRY_ACTION = "SELECT_COUNTRY_ACTION";
export const DESELECT_COUNTRY_ACTION = "DESELECT_COUNTRY_ACTION";
