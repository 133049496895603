export const GET_ISO_TIME_ZONES_REQUEST = "GET_ISO_TIME_ZONES_REQUEST";
export const GET_ISO_TIME_ZONES_SUCCESS = "GET_ISO_TIME_ZONES_SUCCESS";
export const GET_ISO_TIME_ZONES_FAILED = "GET_ISO_TIME_ZONES_FAILED";

export const GET_SECTORS_REQUEST = "GET_SECTORS_REQUEST";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_FAILED = "GET_SECTORS_FAILED";

export const GET_CODE_KEYWORDS_REQUEST = "GET_CODE_KEYWORDS_REQUEST";
export const GET_CODE_KEYWORDS_SUCCESS = "GET_CODE_KEYWORDS_SUCCESS";
export const GET_CODE_KEYWORDS_FAILED = "GET_CODE_KEYWORDS_FAILED";

export const SET_SELECTED_EVENT_ACTION = "SET_SELECTED_EVENT_ACTION";
