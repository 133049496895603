import fetch from "./customFetch";
import { API_URL } from "../constants";


const plugin = {
  async getEventPlugin(data) {
    try {
      return await fetch(`${API_URL}/events/${data.eventId}/plugins/${data.pluginId}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createEventPlugin(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/plugins`, {
        method: "POST",
        body: JSON.stringify({ event: { plugin_attributes: data.body } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateEventPlugin(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/plugins/${data.body.id}`, {
        method: "PUT",
        body: JSON.stringify({ event: { plugin_attributes: data.body } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteEventPlugin(data) {
    try {
      return await fetch(`${API_URL}/events/${data.eventId}/plugins/${data.pluginId}`, {
        method: "DELETE",
        body: JSON.stringify({})
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default plugin;
