import * as types from "./invoiceActionTypes";


export const getInvoicesRequest = (query = {}) => ({
  type: types.GET_INVOICES_REQUEST, payload: query
});

export const getInvoicesSuccess = data => ({
  type: types.GET_INVOICES_SUCCESS, payload: data
});

export const getInvoicesFailed = error => ({
  type: types.GET_INVOICES_FAILED, payload: error
});

export const getSingleInvoiceRequest = id => ({
  type: types.GET_SINGLE_INVOICE_REQUEST, payload: id
});

export const getSingleInvoiceSuccess = data => ({
  type: types.GET_SINGLE_INVOICE_SUCCESS, payload: data
});

export const getSingleInvoiceFailed = error => ({
  type: types.GET_SINGLE_INVOICE_FAILED, payload: error
});

export const createInvoiceRequest = (data = {}, history) => ({
  type: types.CREATE_INVOICE_REQUEST, payload: { data, history }
});

export const createInvoiceSuccess = data => ({
  type: types.CREATE_INVOICE_SUCCESS, payload: data
});

export const createInvoiceFailed = error => ({
  type: types.CREATE_INVOICE_FAILED, payload: error
});

export const editInvoiceRequest = (data = {}, history) => ({
  type: types.EDIT_INVOICE_REQUEST, payload: { data, history }
});

export const editInvoiceSuccess = data => ({
  type: types.EDIT_INVOICE_SUCCESS, payload: data
});

export const editInvoiceFailed = error => ({
  type: types.EDIT_INVOICE_FAILED, payload: error
});

export const deleteInvoiceRequest = (invoiceId, eventId, callback) => ({
  type: types.DELETE_INVOICE_REQUEST, payload: { invoiceId, eventId, callback }
});

export const deleteInvoiceSuccess = data => ({
  type: types.DELETE_INVOICE_SUCCESS, payload: data
});

export const deleteInvoiceFailed = error => ({
  type: types.DELETE_INVOICE_FAILED, payload: error
});

export const toggleInvoiceStatusRequest = (body, callback) => ({
  type: types.TOGGLE_INVOICE_STATUS_REQUEST, payload: { body, callback }
});

export const toggleInvoiceStatusSuccess = data => ({
  type: types.TOGGLE_INVOICE_STATUS_SUCCESS, payload: data
});

export const toggleInvoiceStatusFailed = error => ({
  type: types.TOGGLE_INVOICE_STATUS_FAILED, payload: error
});

export const sendInvoiceEmailRequest = (id, callback) => ({
  type: types.SEND_INVOICE_EMAIL_REQUEST, payload: { id, callback }
});

export const sendInvoiceEmailSuccess = data => ({
  type: types.SEND_INVOICE_EMAIL_SUCCESS, payload: data
});

export const sendInvoiceEmailFailed = error => ({
  type: types.SEND_INVOICE_EMAIL_FAILED, payload: error
});

export const getEventInvoicesPdfRequest = id => ({
  type: types.GET_EVENT_INVOICES_PDF_REQUEST, payload: id
});

export const getEventInvoicesPdfSuccess = data => ({
  type: types.GET_EVENT_INVOICES_PDF_SUCCESS, payload: data
});

export const getEventInvoicesPdfFailed = error => ({
  type: types.GET_EVENT_INVOICES_PDF_FAILED, payload: error
});

export const getEventInvoicesExelRequest = (id, filename) => ({
  type: types.GET_EVENT_INVOICES_EXEL_REQUEST, payload: { id, filename }
});

export const getEventInvoicesExelSuccess = data => ({
  type: types.GET_EVENT_INVOICES_EXEL_SUCCESS, payload: data
});

export const getEventInvoicesExelFailed = error => ({
  type: types.GET_EVENT_INVOICES_EXEL_FAILED, payload: error
});

export const getInvoicesExelRequest = (query, filename) => ({
  type: types.GET_INVOICES_EXEL_REQUEST, payload: { query, filename }
});

export const getInvoicesExelSuccess = data => ({
  type: types.GET_INVOICES_EXEL_SUCCESS, payload: data
});

export const getInvoicesExelFailed = error => ({
  type: types.GET_INVOICES_EXEL_FAILED, payload: error
});

export const getXeroExportRequest = (query) => ({
  type: types.GET_XERO_EXPORT_REQUEST, payload: { query }
});

export const getXeroExportSuccess = data => ({
  type: types.GET_XERO_EXPORT_SUCCESS, payload: data
});

export const getXeroExportFailed = error => ({
  type: types.GET_XERO_EXPORT_FAILED, payload: error
});
