export const scrollTop = () => {
  window.scrollY && window.scrollTo && window.scrollTo({ top: 0 });
};

export const getTimeFromDate = (date, format = "hh:mm") => {
  const time = typeof date === "string" ? date.slice(11, 16) : "";

  if (time.length === 5) {
    if (format === "hh : mm") {
      return `${time.slice(0, 2)} : ${time.slice(3, 5)}`;
    }

    return time;
  }

  return "";
};

export const insertTimeToDate = (date, time, format = "hh:mm") => {
  try {
    return date.length >= 19 ? date.slice(0, 11) + (format === "hh : mm" ? time.replaceAll(" ", "") : time) + date.slice(16) : date;
  } catch (e) {
    return date;
  }
};

export const firstLetterCapitalize = (word) => {
  return word[0].toUpperCase().concat(word.slice(1));
}

export const buildCountryOptionsWithDivider = (countries, countryCodeAsValue = false, countryCodeAsLabel = false) => {
  const europeCountryOptions = [];
  const otherWorldCountryOptions = [];

  for (const country of countries) {
    if (country.europe) {
      europeCountryOptions.push({
        value: countryCodeAsValue ? country.code : country.id,
        label: countryCodeAsLabel ? country.code : country.name
      });
    } else {
      otherWorldCountryOptions.push({
        value: countryCodeAsValue ? country.code : country.id,
        label: countryCodeAsLabel ? country.code : country.name
      });
    }
  }

  return [
    {
      label: "Europe",
      options: europeCountryOptions
    },
    {
      label: "Rest of the world",
      options: otherWorldCountryOptions
    }
  ]
}
