import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";


const Alert = ({ alertProps }) => {
  const alert = useAlert();

  useEffect(() => {
    if (alertProps.text) {
      alert.show(alertProps.text, {
        type: alertProps.type,
      });
    }
  }, [alertProps]);

  return (
    <div />
  );
};

Alert.propTypes = {
  alertProps: PropTypes.object.isRequired
};

export default connect(
  state => ({
    alertProps: state.alert.props
  })
)(Alert);
