import { all, call, put, takeLatest } from "redux-saga/effects";

import delegate from "../../api/delegate";

import * as types from "./delegateActionTypes";
import * as actions from "./delegateActions";
import * as alertActions from "../alert/alertActions";

import { REQUEST_SUCCESS_MESSAGE } from "../../constants";


function* getDelegateListSaga({ payload }) {
  try {
    const response = yield call(delegate.getDelegateList, payload);
    yield put(actions.getDelegateListSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getDelegateListFailed(err));
  }
}

function* getSingleDelegateSaga({ payload }) {
  try {
    const response = yield call(delegate.getSingleDelegate, payload);
    yield put(actions.getSingleDelegateSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getSingleDelegateFailed(err));
  }
}

function* updateSingleDelegateSaga({ payload }) {
  try {
    const response = yield call(delegate.updateSingleDelegate, payload);
    yield put(actions.updateSingleDelegateSuccess(response));
    yield put(alertActions.showSuccessAlertAction(REQUEST_SUCCESS_MESSAGE));
  } catch (err) {
    yield put(actions.updateSingleDelegateFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* toggleAttendeeSaga({ payload }) {
  try {
    const response = yield call(delegate.toggleAttendee, payload);
    yield put(actions.toggleAttendeeSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.toggleAttendeeFailed(err));
  }
}

function* toggleSpamSaga({ payload }) {
  try {
    const response = yield call(delegate.toggleSpam, payload);
    yield put(actions.toggleSpamSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.toggleSpamFailed(err));
  }
}

function* toggleCanceledSaga({ payload }) {
  try {
    const response = yield call(delegate.toggleCanceled, payload.id);
    if (response.canceled && response.attendee) {
      const newResponse = yield call(delegate.toggleAttendee, payload.id);
      yield put(actions.toggleAttendeeSuccess(newResponse));
    } else {
      yield put(actions.toggleCanceledSuccess(response));
    }
    yield call(payload.callback);
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.toggleCanceledFailed(err));
  }
}

function* getDelegateEventsSaga({ payload }) {
  try {
    const response = yield call(delegate.getDelegateEvents, payload);
    yield put(actions.getDelegateEventsSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getDelegateEventsFailed(err));
  }
}

function* getDelegateExelFileSaga({ payload }) {
  try {
    const response = yield call(delegate.getDelegateExelFile, payload);
    const fileURL = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = payload.filename || "export-delegates.xlsx";
    link.click();
    yield put(actions.getDelegateExelFileSuccess(response));
    yield call(payload.callback);
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getDelegateExelFileFailed(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DELEGATE_LIST_REQUEST, getDelegateListSaga),
    yield takeLatest(types.GET_SINGLE_DELEGATE_REQUEST, getSingleDelegateSaga),
    yield takeLatest(types.UPDATE_SINGLE_DELEGATE_REQUEST, updateSingleDelegateSaga),
    yield takeLatest(types.TOGGLE_ATTENDEE_REQUEST, toggleAttendeeSaga),
    yield takeLatest(types.TOGGLE_SPAM_REQUEST, toggleSpamSaga),
    yield takeLatest(types.TOGGLE_CANCELED_REQUEST, toggleCanceledSaga),
    yield takeLatest(types.GET_DELEGATE_EVENTS_REQUEST, getDelegateEventsSaga),
    yield takeLatest(types.GET_DELEGATE_EXEL_FILE_REQUEST, getDelegateExelFileSaga),
  ]);
}
