export const GET_SPONSOR_LIST_REQUEST = "GET_SPONSOR_LIST_REQUEST";
export const GET_SPONSOR_LIST_SUCCESS = "GET_SPONSOR_LIST_SUCCESS";
export const GET_SPONSOR_LIST_FAILED = "GET_SPONSOR_LIST_FAILED";

export const CREATE_SPONSOR_REQUEST = "CREATE_SPONSOR_REQUEST";
export const CREATE_SPONSOR_SUCCESS = "CREATE_SPONSOR_SUCCESS";
export const CREATE_SPONSOR_FAILED = "CREATE_SPONSOR_FAILED";

export const EDIT_SPONSOR_REQUEST = "EDIT_SPONSOR_REQUEST";
export const EDIT_SPONSOR_SUCCESS = "EDIT_SPONSOR_SUCCESS";
export const EDIT_SPONSOR_FAILED = "EDIT_SPONSOR_FAILED";

export const DELETE_SPONSOR_REQUEST = "DELETE_SPONSOR_REQUEST";
export const DELETE_SPONSOR_SUCCESS = "DELETE_SPONSOR_SUCCESS";
export const DELETE_SPONSOR_FAILED = "DELETE_SPONSOR_FAILED";

export const SELECT_SPONSOR_ACTION = "SELECT_SPONSOR_ACTION";
export const DESELECT_SPONSOR_ACTION = "DESELECT_SPONSOR_ACTION";
