import * as types from "./globalActionTypes";
import lodash from "lodash";

import { BOOKING_FORM_FIELD_SETTINGS } from "../../helpers/constants";

export const INIT_STATE = {
  isoZones: [],
  sectors: [],
  selectedEvent: {},
  codeKeywords: [],
  loading: false,
  error: null,
};

const convertSingleEvent = (event = {}) => {
  const newSettings = lodash.cloneDeep(BOOKING_FORM_FIELD_SETTINGS);

  if (!event.booking_field_settings) {
    return event;
  }

  event.booking_field_settings.forEach(item => {
    const field = BOOKING_FORM_FIELD_SETTINGS[item.model] ? BOOKING_FORM_FIELD_SETTINGS[item.model][item.name] : {};
    newSettings[item.model][item.name] = { ...field, ...item };
  });

  return { ...event, booking_field_settings: newSettings };
};


const global = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_ISO_TIME_ZONES_REQUEST:
    case types.GET_SECTORS_REQUEST:
    case types.GET_CODE_KEYWORDS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_ISO_TIME_ZONES_FAILED:
    case types.GET_SECTORS_FAILED:
    case types.GET_CODE_KEYWORDS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_ISO_TIME_ZONES_SUCCESS:
      return {
        ...state,
        isoZones: action.payload,
        loading: false,
        error: null
      };

    case types.GET_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.payload.items,
        loading: false,
        error: null
      };

    case types.GET_CODE_KEYWORDS_SUCCESS:
      return {
        ...state,
        codeKeywords: action.payload.map(item => ({
          ...item,
          value: item.label.slice(1, -1).toLowerCase().replace(/\W+/gi, "_"),
          name: item.label.slice(1, -1)
        })),
        loading: false,
        error: null
      };

    case types.SET_SELECTED_EVENT_ACTION:
      return {
        ...state,
        selectedEvent: convertSingleEvent(action.payload)
      };

    default:
      return state;
  }
};

export default global;
