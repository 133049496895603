import * as types from "./dateFormatsActionTypes";


export const getDateFormatsRequest = () => ({
  type: types.GET_DATE_FORMATS_REQUEST
});

export const getDateFormatsSuccess = data => ({
  type: types.GET_DATE_FORMATS_SUCCESS, payload: data
});

export const getDateFormatsFailed = error => ({
  type: types.GET_DATE_FORMATS_FAILED, payload: error
});

export const createDateFormatRequest = (data, toggleModal) => ({
  type: types.CREATE_DATE_FORMAT_REQUEST, payload: { data, toggleModal }
});

export const createDateFormatSuccess = data => ({
  type: types.CREATE_DATE_FORMAT_SUCCESS, payload: data
});

export const createDateFormatFailed = error => ({
  type: types.CREATE_DATE_FORMAT_FAILED, payload: error
});

export const updateDateFormatRequest = (data, toggleModal) => ({
  type: types.EDIT_DATE_FORMAT_REQUEST, payload: { data, toggleModal }
});

export const updateDateFormatSuccess = data => ({
  type: types.EDIT_DATE_FORMAT_SUCCESS, payload: data
});

export const updateDateFormatFailed = error => ({
  type: types.EDIT_DATE_FORMAT_FAILED, payload: error
});

export const deleteDateFormatRequest = data => ({
  type: types.DELETE_DATE_FORMAT_REQUEST, payload: data
});

export const deleteDateFormatSuccess = data => ({
  type: types.DELETE_DATE_FORMAT_SUCCESS, payload: data
});

export const deleteDateFormatFailed = error => ({
  type: types.DELETE_DATE_FORMAT_FAILED, payload: error
});

export const selectDateFormatAction = data => ({
  type: types.SELECT_DATE_FORMAT_ACTION, payload: data
});

export const deselectDateFormatAction = () => ({
  type: types.DESELECT_DATE_FORMAT_ACTION
});
