import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const booking = {
  async getUserByEmail(email) {
    try {
      return await fetch(`${API_URL}/users_email${QueryString.stringify({ email })}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getBookings(query) {
    try {
      return await fetch(`${API_URL}/bookings${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSingleBooking(id) {
    try {
      return await fetch(`${API_URL}/bookings/${id}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async createBooking(data) {
    try {
      return await fetch(`${API_URL}/bookings`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateBooking(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.id}`, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editBooking(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.id}/${data.action}`, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteBooking(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.id}`, {
        method: "DELETE",
        body: JSON.stringify({})
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createNote(data) {
    try {
      return await fetch(`${API_URL}/notices`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editNote(data) {
    try {
      return await fetch(`${API_URL}/notices/${data.id}`, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async addBookingDiscountApi(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.booking_discount.booking_id}/booking_discount`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateBookingDiscountApi(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.booking_discount.booking_id}/booking_discount`, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteBookingDiscountApi(data) {
    try {
      return await fetch(`${API_URL}/bookings/${data.booking_discount.booking_id}/booking_discount`, {
        method: "DELETE",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async remindEmailAboutBooking(id) {
    try {
      return await fetch(`${API_URL}/bookings/${id}/remind`, {
        method: "POST",
        body: JSON.stringify({})
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createBookingDelegate({ bookingId, ...requestData }) {
    try {
      return await fetch(`${API_URL}/bookings/${bookingId}/booking_delegates`, {
        method: "POST",
        body: JSON.stringify({ booking_delegate: requestData })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteBookingDelegate({ bookingId, delegateId }) {
    try {
      return await fetch(`${API_URL}/bookings/${bookingId}/booking_delegates/${delegateId}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editBookingDelegate({ bookingId, editDelegateId, ...requestData }) {
    try {
      return await fetch(`${API_URL}/bookings/${bookingId}/booking_delegates/${editDelegateId}`, {
        method: "PUT",
        body: JSON.stringify({ booking_delegate: requestData })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async proceedBookingDelegate(id) {
    try {
      return await fetch(`${API_URL}/bookings/${id}/proceed`, {
        method: "PUT",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getBookingDelegates({ bookingId }) {
    try {
      return await fetch(`${API_URL}/booking_delegates/${bookingId}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getBookingExelFile(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/export_bookings.xlsx${QueryString.stringify(data.query)}`, {
        headers: {
          "Content-Type": "application/xlsx"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async initBookingPayment(data) {
    try {
      return await fetch(`${API_URL}/invoices/${data.id}/init_payment`, {
        method: "POST",
        body: JSON.stringify({})
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default booking;
