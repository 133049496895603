import { SHOW_ALERT } from "./alertActionTypes";

export const showSuccessAlertAction = text => ({
  type: SHOW_ALERT,
  payload: {
    type: "success",
    text: text
  }
});

export const showFailedAlertAction = (err, except_keys = []) => {
  const error = typeof err === "string"
    ? err
    : err && err.message
      ? err.message
      : Array.isArray(err)
        ? err.join(". ")
        : typeof err === "object" && except_keys.length
          ? Object.entries(err).filter(x => !except_keys.includes(x[0])).map(x => x[1]).join(" ")
          : typeof err === "object"
            ? Object.values(err).join(" ")
            : "";

  if (except_keys.length && !error) {
    return { type: "EMPTY_ACTION", payload: err };
  }

  return {
    type: SHOW_ALERT,
    payload: {
      type: "error",
      text: error || "Something wrong"
    }
  }
};
