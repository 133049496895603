import * as types from "./creditNoteActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  singleCreditNote: {},
  loading: false,
  loadingPdf: false,
  error: null
};


const creditNote = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_CREDIT_NOTES_REQUEST:
    case types.CREATE_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_CREDIT_NOTE_FAILED:
    case types.EDIT_CREDIT_NOTE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_CREDIT_NOTES_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        error: null,
        loading: false
      };

    case types.GET_CREDIT_NOTES_FAILED:
      return {
        ...state,
        items: [],
        page: 1,
        count: 0,
        error: action.payload,
        loading: false
      };

    case types.DELETE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id),
        count: state.count - 1,
        error: null,
        loading: false
      };

    case types.GET_SINGLE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        singleCreditNote: action.payload,
        error: null,
        loading: false
      };

    case types.GET_CREDIT_NOTE_PDF_REQUEST:
      return {
        ...state,
        loadingPdf: true,
        error: null
      };

    case types.GET_CREDIT_NOTE_PDF_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        error: null
      };

    case types.GET_CREDIT_NOTE_PDF_FAILED:
      return {
        ...state,
        loadingPdf: false,
        error: action.payload
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default creditNote;
