import { all, call, put, takeLatest } from "redux-saga/effects";

import * as types from "./dashboardActionTypes";

import dashboard from "../../api/dashboard";
import * as actions from "./dashboardActions";
import * as alertActions from "../alert/alertActions";

function* getDashboardEventListSaga({ payload }) {
  try {
    const response = yield call(dashboard.getDashboardEventList, payload);
    yield put(actions.getDashboardEventListSuccess(response));
  } catch (err) {
    yield put(actions.getDashboardEventListFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* getDetailedStatisticSaga({ payload }) {
  try {
    const { main, secondary } = payload;
    const mainResponse = main ? yield call(dashboard.getDetailedStatistic, main) : null;
    const secondaryResponse = secondary ? yield call(dashboard.getDetailedStatistic, secondary) : null;

    const response = main && secondary ? [mainResponse, secondaryResponse] : main ? [mainResponse] : [];
    yield put(actions.getDetailedStatisticSuccess(response));
  } catch (err) {
    yield put(actions.getDetailedStatisticFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DASHBOARD_EVENT_LIST_REQUEST, getDashboardEventListSaga),
    yield takeLatest(types.GET_DETAILED_STATISTIC_REQUEST, getDetailedStatisticSaga),
  ]);
}
