import { all, call, put, takeLatest, select } from "redux-saga/effects";

import preference from "../../../api/preference";

import * as types from "./dateFormatsActionTypes";
import * as actions from "./dateFormatsActions";
import * as alertActions from "../../alert/alertActions";


function* getDateFormatsSaga({ payload }) {
  try {
    const response = yield call(preference.getDateFormats, payload);
    yield put(actions.getDateFormatsSuccess(response));
  } catch (err) {
    yield put(actions.getDateFormatsFailed(err));
  }
}

function* createDataFormatSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.dateFormats.items);
    const response = yield call(preference.createDateFormat, payload.data);

    yield put(actions.createDateFormatSuccess({ items: [...items, response] }));
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.createDateFormatFailed(err));
  }
}

function* deleteDataFormatSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.dateFormats.items);
    const response = yield call(preference.deleteDateFormat, payload);

    const newItems = items.filter(item => item.id !== response.id);
    yield put(actions.deleteDateFormatSuccess({ items: newItems }));
  } catch (err) {
    yield put(actions.deleteDateFormatFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editDataFormatSaga({ payload }) {
  try {
    yield call(preference.updateDateFormat, payload.data);
    yield put(actions.updateDateFormatSuccess());
    yield put(actions.getDateFormatsRequest());
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.updateDateFormatFailed(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DATE_FORMATS_REQUEST, getDateFormatsSaga),
    yield takeLatest(types.CREATE_DATE_FORMAT_REQUEST, createDataFormatSaga),
    yield takeLatest(types.EDIT_DATE_FORMAT_REQUEST, editDataFormatSaga),
    yield takeLatest(types.DELETE_DATE_FORMAT_REQUEST, deleteDataFormatSaga),
  ]);
}
