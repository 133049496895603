import * as types from "./managementActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  singleUser: {},
  loading: false,
  error: null
};


const management = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SUSPEND_USER_REQUEST:
    case types.UNBLOCK_SUSPENDED_USER_REQUEST:
    case types.GET_USER_REQUEST:
    case types.EDIT_USER_REQUEST:
    case types.CREATE_USER_REQUEST:
    case types.DELETE_USER_REQUEST:
    case types.GET_USERS_REQUEST:
    case types.INVITE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.SUSPEND_USER_FAILED:
    case types.UNBLOCK_SUSPENDED_USER_FAILED:
    case types.GET_USER_FAILED:
    case types.EDIT_USER_FAILED:
    case types.CREATE_USER_FAILED:
    case types.DELETE_USER_FAILED:
    case types.GET_USERS_FAILED:
    case types.INVITE_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.user.id),
        count: state.count > 0 ? state.count - 1 : 0,
        loading: false,
        error: null
      };

    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        loading: false,
        error: null
      };

    case types.GET_USER_SUCCESS:
    case types.SELECT_USER_ACTION:
      return {
        ...state,
        singleUser: action.payload
      };

    case types.SUSPEND_USER_SUCCESS:
    case types.UNBLOCK_SUSPENDED_USER_SUCCESS:
    case types.EDIT_USER_SUCCESS:
    case types.CREATE_USER_SUCCESS:
    case types.INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case types.CLEAR_USER_ERROR_ACTION:
      return {
        ...state,
        loading: false,
        error: null
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default management;
