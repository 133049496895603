import * as types from "./dashboardActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  statistic: [],
  loading: false,
  error: null
};


const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_EVENT_LIST_REQUEST:
    case types.GET_DETAILED_STATISTIC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_DASHBOARD_EVENT_LIST_FAILED:
    case types.GET_DETAILED_STATISTIC_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_DASHBOARD_EVENT_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        loading: false,
        error: null
      };

    case types.GET_DETAILED_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: action.payload,
        loading: false,
        error: null
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default dashboard;
