import { all, call, put, takeLatest, select } from "redux-saga/effects";

import preference from "../../../api/preference";

import * as types from "./countriesActionTypes";
import * as actions from "./countriesActions";
import * as alertActions from "../../alert/alertActions";


function* getIsoCountriesSaga() {
  try {
    const response = yield call(preference.getIsoCountries);
    yield put(actions.getIsoCountriesSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getIsoCountriesFailed(err));
  }
}

function* getCountriesSaga({ payload }) {
  try {
    const response = yield call(preference.getCountries, payload);
    yield put(actions.getCountriesSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getCountriesFailed(err));
  }
}

function* getCountriesOptionsSaga({ payload }) {
  try {
    const response = yield call(preference.getCountries, payload);
    yield put(actions.getCountriesOptionsSuccess(response));
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err));
    yield put(actions.getCountriesOptionsFailed(err));
  }
}

function* createCountrySaga({ payload }) {
  try {
    const items = yield select(store => store.preference.countries.items);
    const response = yield call(preference.createCountry, payload.country);

    yield put(actions.createCountrySuccess({ items: [response, ...items] }));
    yield call(() => {
      payload.toggleModal()
    });

  } catch (err) {
    yield put(actions.createCountryFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteCountrySaga({ payload }) {
  try {
    const items = yield select(store => store.preference.countries.items);
    const response = yield call(preference.deleteCountry, payload);

    const newItems = items.filter(item => item.id !== response.id);
    yield put(actions.deleteCountrySuccess({ items: newItems }));

  } catch (err) {
    yield put(actions.deleteCountryFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editCountrySaga({ payload }) {
  try {
    yield call(preference.editCountry, payload.data);
    yield put(actions.editCountrySuccess());
    yield put(actions.getCountriesRequest());
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.editCountryFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_ISO_COUNTRIES_REQUEST, getIsoCountriesSaga),
    yield takeLatest(types.GET_COUNTRIES_OPTIONS_REQUEST, getCountriesOptionsSaga),
    yield takeLatest(types.GET_COUNTRIES_REQUEST, getCountriesSaga),
    yield takeLatest(types.CREATE_COUNTRY_REQUEST, createCountrySaga),
    yield takeLatest(types.EDIT_COUNTRY_REQUEST, editCountrySaga),
    yield takeLatest(types.DELETE_COUNTRY_REQUEST, deleteCountrySaga),
  ]);
}
