import fetch from "./customFetch";
import { API_URL } from "../constants";

const global = {
  async getIsoTimeZones() {
    try {
      return await fetch(`${API_URL}/iso_time_zones`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSectors() {
    try {
      return await fetch(`${API_URL}/sectors`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getCodeKeywords() {
    try {
      return await fetch(`${API_URL}/events/email_templates/code_keywords`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },
};

export default global;
