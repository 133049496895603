import { all, call, put, takeLatest, select } from "redux-saga/effects";

import preference from "../../../api/preference";

import * as types from "./dotmailerAddressBooksActionTypes";
import * as actions from "./dotmailerAddressBooksActions";
import * as alertActions from "../../alert/alertActions";

function* getDotmailerAddressBookListSaga({ payload }) {
  try {
    const response = yield call(preference.getDotmailerAddressBooks, payload);
    yield put(actions.getDotmailerAddressBooksSuccess(response));
  } catch (err) {
    yield put(actions.getDotmailerAddressBooksFailed(err));
  }
}

function* createDotmailerAddressBookSaga({ payload }) {
  try {
    yield call(preference.createDotmailerAddressBook, payload.data);
    yield put(actions.getDotmailerAddressBookListRequest(payload.category));
    yield call(payload.toggleModal);
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err, ["name", "category", "address_book_id"]));
    yield put(actions.createDotmailerAddressBookFailed(err));
  }
}

function* updateDotmailerAddressBookSaga({ payload }) {
  try {
    yield call(preference.updateDotmailerAddressBook, payload.data);
    yield put(actions.updateDotmailerAddressBookSuccess());
    yield put(actions.getDotmailerAddressBookListRequest(payload.category));
    yield call(payload.toggleModal);
  } catch (err) {
    yield put(alertActions.showFailedAlertAction(err, ["name", "category", "address_book_id"]));
    yield put(actions.updateDotmailerAddressBookFailed(err));
  }
}

function* deleteDotmailerAddressBookSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.dotmailerAddressBooks.items);
    const response = yield call(preference.deleteDotmailerAddressBook, payload);

    const newItems = items.filter(item => item.id !== response.id);
    yield put(actions.deleteDotmailerAddressBookSuccess({ items: newItems }));
  } catch (err) {
    yield put(actions.deleteDotmailerAddressBookFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_DOTMAILER_ADDRESS_BOOK_LIST_REQUEST, getDotmailerAddressBookListSaga),
    yield takeLatest(types.CREATE_DOTMAILER_ADDRESS_BOOK_REQUEST, createDotmailerAddressBookSaga),
    yield takeLatest(types.UPDATE_DOTMAILER_ADDRESS_BOOK_REQUEST, updateDotmailerAddressBookSaga),
    yield takeLatest(types.DELETE_DOTMAILER_ADDRESS_BOOK_REQUEST, deleteDotmailerAddressBookSaga),
  ]);
}
