import * as types from "./sponsorsActionTypes";


export const getSponsorListRequest = () => ({
  type: types.GET_SPONSOR_LIST_REQUEST
});

export const getSponsorListSuccess = data => ({
  type: types.GET_SPONSOR_LIST_SUCCESS, payload: data
});

export const getSponsorListFailed = error => ({
  type: types.GET_SPONSOR_LIST_FAILED, payload: error
});

export const createSponsorRequest = (data, toggleModal) => ({
  type: types.CREATE_SPONSOR_REQUEST, payload: { data, toggleModal }
});

export const createSponsorSuccess = data => ({
  type: types.CREATE_SPONSOR_SUCCESS, payload: data
});

export const createSponsorFailed = error => ({
  type: types.CREATE_SPONSOR_FAILED, payload: error
});

export const editSponsorRequest = (data, toggleModal) => ({
  type: types.EDIT_SPONSOR_REQUEST, payload: { data, toggleModal }
});

export const editSponsorSuccess = data => ({
  type: types.EDIT_SPONSOR_SUCCESS, payload: data
});

export const editSponsorFailed = error => ({
  type: types.EDIT_SPONSOR_FAILED, payload: error
});

export const deleteSponsorRequest = data => ({
  type: types.DELETE_SPONSOR_REQUEST, payload: data
});

export const deleteSponsorSuccess = data => ({
  type: types.DELETE_SPONSOR_SUCCESS, payload: data
});

export const deleteSponsorFailed = error => ({
  type: types.DELETE_SPONSOR_FAILED, payload: error
});

export const selectSponsorAction = data => ({
  type: types.SELECT_SPONSOR_ACTION, payload: data
});

export const deselectSponsorAction = () => ({
  type: types.DESELECT_SPONSOR_ACTION
});
