import * as types from "./creditNoteActionTypes";


export const getCreditNotesRequest = (query = {}) => ({
  type: types.GET_CREDIT_NOTES_REQUEST, payload: query
});

export const getCreditNotesSuccess = data => ({
  type: types.GET_CREDIT_NOTES_SUCCESS, payload: data
});

export const getCreditNotesFailed = error => ({
  type: types.GET_CREDIT_NOTES_FAILED, payload: error
});

export const getSingleCreditNoteRequest = id => ({
  type: types.GET_SINGLE_CREDIT_NOTE_REQUEST, payload: id
});

export const getSingleCreditNoteSuccess = (data = {}) => ({
  type: types.GET_SINGLE_CREDIT_NOTE_SUCCESS, payload: data
});

export const getSingleCreditNotesFailed = error => ({
  type: types.GET_SINGLE_CREDIT_NOTE_FAILED, payload: error
});

export const createCreditNoteRequest = (data = {}, history) => ({
  type: types.CREATE_CREDIT_NOTE_REQUEST, payload: { data, history }
});

export const createCreditNoteSuccess = data => ({
  type: types.CREATE_CREDIT_NOTE_SUCCESS, payload: data
});

export const createCreditNoteFailed = error => ({
  type: types.CREATE_CREDIT_NOTE_FAILED, payload: error
});

export const editCreditNoteRequest = (data = {}, history) => ({
  type: types.EDIT_CREDIT_NOTE_REQUEST, payload: { data, history }
});

export const editCreditNoteSuccess = data => ({
  type: types.EDIT_CREDIT_NOTE_SUCCESS, payload: data
});

export const editCreditNoteFailed = error => ({
  type: types.EDIT_CREDIT_NOTE_FAILED, payload: error
});

export const deleteCreditNoteRequest = (id, history, callback) => ({
  type: types.DELETE_CREDIT_NOTE_REQUEST, payload: { id, history, callback }
});

export const deleteCreditNoteSuccess = data => ({
  type: types.DELETE_CREDIT_NOTE_SUCCESS, payload: data
});

export const deleteCreditNoteFailed = error => ({
  type: types.DELETE_CREDIT_NOTE_FAILED, payload: error
});

export const getCreditNotePdfRequest = id => ({
  type: types.GET_CREDIT_NOTE_PDF_REQUEST, payload: id
});

export const getCreditNotePdfSuccess = data => ({
  type: types.GET_CREDIT_NOTE_PDF_SUCCESS, payload: data
});

export const getCreditNotePdfFailed = error => ({
  type: types.GET_CREDIT_NOTE_PDF_FAILED, payload: error
});
