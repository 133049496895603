import * as types from "./countriesActionTypes";
import { buildCountryOptionsWithDivider } from "../../../helpers/methods";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  isoCountries: {},
  options: [],
  countryCodeOptions: [],
  selected: {},
  loading: false,
  error: null
};


const countries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_ISO_COUNTRIES_REQUEST:
    case types.GET_COUNTRIES_OPTIONS_REQUEST:
    case types.GET_COUNTRIES_REQUEST:
    case types.CREATE_COUNTRY_REQUEST:
    case types.EDIT_COUNTRY_REQUEST:
    case types.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_ISO_COUNTRIES_FAILED:
    case types.GET_COUNTRIES_OPTIONS_FAILED:
    case types.GET_COUNTRIES_FAILED:
    case types.CREATE_COUNTRY_FAILED:
    case types.EDIT_COUNTRY_FAILED:
    case types.DELETE_COUNTRY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_ISO_COUNTRIES_SUCCESS:
      return {
        ...state,
        isoCountries: action.payload,
        loading: false,
        error: null
      };

    case types.GET_COUNTRIES_OPTIONS_SUCCESS:
      return {
        ...state,
        options: buildCountryOptionsWithDivider(action.payload.items),
        countryCodeOptions: buildCountryOptionsWithDivider(action.payload.items, true, true),
        loading: false,
        error: null
      };

    case types.GET_COUNTRIES_SUCCESS:
    case types.CREATE_COUNTRY_SUCCESS:
    case types.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        loading: false,
        error: null
      };

    case types.EDIT_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case types.SELECT_COUNTRY_ACTION:
      return {
        ...state,
        selected: action.payload
      };

    case types.DESELECT_COUNTRY_ACTION:
      return {
        ...state,
        selected: {}
      };

    default:
      return state;
  }
};

export default countries;
