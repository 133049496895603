import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const preference = {

  /* Start API for countries */

  async getIsoCountries() {
    try {
      return await fetch(`${API_URL}/iso_countries`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getCountries(query) {
    try {
      return await fetch(`${API_URL}/countries${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createCountry(data) {
    try {
      return await fetch(`${API_URL}/countries`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteCountry({ id }) {
    try {
      return await fetch(`${API_URL}/countries/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editCountry({ id, code, europe }) {
    try {
      return await fetch(`${API_URL}/countries/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          country: { code, europe }
        })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  /* End API for countries */

  /* Start API for date formats */

  async getDateFormats() {
    try {
      return await fetch(`${API_URL}/date_formats`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createDateFormat(data) {
    try {
      return await fetch(`${API_URL}/date_formats`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteDateFormat({ id }) {
    try {
      return await fetch(`${API_URL}/date_formats/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateDateFormat({ id, name, format }) {
    try {
      return await fetch(`${API_URL}/date_formats/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          date_format: { name, format }
        })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  /* End API for date formats */

  /* Start API for sponsors */

  async getSponsors() {
    try {
      return await fetch(`${API_URL}/sponsors`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createSponsor({ sponsor }) {
    const formData = new FormData();
    formData.append("sponsor[name]", sponsor.name);
    formData.append("sponsor[link]", sponsor.link);
    formData.append("sponsor[description]", sponsor.description);
    formData.append("sponsor[internal_name]", sponsor.internal_name)
    // in case when need upload a photo
    if (typeof sponsor.logo === "object") {
      formData.append("sponsor[logo]", sponsor.logo);
    }
    // in case when logo is empty
    if (typeof sponsor.logo === "string" && !sponsor.logo.length) {
      formData.append("sponsor[logo]", "");
    }

    try {
      return await fetch(`${API_URL}/sponsors`, {
        headers: {
          "Content-Type": null
        },
        method: "POST",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteSponsor({ id }) {
    try {
      return await fetch(`${API_URL}/sponsors/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editSponsor({ id, name, description, link, logo, internal_name }) {
    const formData = new FormData();
    formData.append("sponsor[name]", name);
    formData.append("sponsor[link]", link);
    formData.append("sponsor[description]", description);
    formData.append("sponsor[internal_name]", internal_name)
    // in case when need upload a photo
    if (typeof logo === "object") {
      formData.append("sponsor[logo]", logo);
    }
    // in case when need delete a photo
    if (typeof logo === "string" && !logo.length) {
      formData.append("sponsor[logo]", "DELETE");
    }

    try {
      return await fetch(`${API_URL}/sponsors/${id}`, {
        headers: {
          "Content-Type": null
        },
        method: "PUT",
        body: formData
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  /* End API for sponsors */

  /* Start API for sponsorship level */

  async getSponsorshipLevels() {
    try {
      return await fetch(`${API_URL}/sponsorship_levels`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createSponsorshipLevel(data) {
    try {
      return await fetch(`${API_URL}/sponsorship_levels`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteSponsorshipLevel({ id }) {
    try {
      return await fetch(`${API_URL}/sponsorship_levels/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editSponsorshipLevel({ id, name, priority }) {
    try {
      return await fetch(`${API_URL}/sponsorship_levels/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          sponsorship_level: { name, priority }
        })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  /* End API for sponsorship level */

  /* Start API for dotmailer address books  */

  async getDotmailerAddressBooks(category = "sector") {
    try {
      return await fetch(`${API_URL}/dotmailer_address_books?category=${category}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createDotmailerAddressBook(data) {
    try {
      return await fetch(`${API_URL}/dotmailer_address_books`, {
        method: "POST",
        body: JSON.stringify(data)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteDotmailerAddressBook({ id }) {
    try {
      return await fetch(`${API_URL}/dotmailer_address_books/${id}`, {
        method: "DELETE",
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async updateDotmailerAddressBook({ id, ...resp }) {
    try {
      return await fetch(`${API_URL}/dotmailer_address_books/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          dotmailer_address_book: { ...resp }
        })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  /* End API for dotmailer address books */
};

export default preference;
