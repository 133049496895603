import * as types from "./sponsorshipLevelActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  selected: {},
  loading: false,
  error: null
};


const sponsorshipLevel = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_SPONSORSHIP_LEVELS_REQUEST:
    case types.CREATE_SPONSORSHIP_LEVEL_REQUEST:
    case types.EDIT_SPONSORSHIP_LEVEL_REQUEST:
    case types.DELETE_SPONSORSHIP_LEVEL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_SPONSORSHIP_LEVELS_FAILED:
    case types.CREATE_SPONSORSHIP_LEVEL_FAILED:
    case types.EDIT_SPONSORSHIP_LEVEL_FAILED:
    case types.DELETE_SPONSORSHIP_LEVEL_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_SPONSORSHIP_LEVELS_SUCCESS:
    case types.CREATE_SPONSORSHIP_LEVEL_SUCCESS:
    case types.DELETE_SPONSORSHIP_LEVEL_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null
      };

    case types.EDIT_SPONSORSHIP_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case types.SELECT_SPONSORSHIP_LEVEL_ACTION:
      return {
        ...state,
        selected: action.payload
      };

    case types.DESELECT_SPONSORSHIP_LEVEL_ACTION:
      return {
        ...state,
        selected: {}
      };

    default:
      return state;
  }
};

export default sponsorshipLevel;
