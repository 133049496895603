import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const dashboard = {
  async getDashboardEventList(query) {
    try {
      return await fetch(`${API_URL}/dashboard/events${QueryString.stringify(query)}`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  },

  async getDetailedStatistic(id) {
    try {
      return await fetch(`${API_URL}/dashboard/events/${id}`);
    } catch (err) {
      if (err.errors) throw err.errors;
      throw err;
    }
  }
};

export default dashboard;
