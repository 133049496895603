import { all } from "redux-saga/effects";
import authSagas from "./auth/authSagas";
import bookingSagas from "./booking/bookingSagas";
import creditNoteSagas from "./creditNote/creditNoteSagas";
import dashboardSagas from "./dashboard/dashboardSagas";
import delegateSagas from "./delegate/delegateSagas";
import eventSagas from "./event/eventSagas";
import globalSagas from "./global/globalSagas";
import invoiceSagas from "./invoice/invoiceSagas";
import countriesSagas from "./preference/countries/countriesSagas";
import dateFormatsSagas from "./preference/dateFormats/dateFormatsSagas";
import dotmailerAddressBooksSagas from "./preference/dotmailerAddressBooks/dotmailerAddressBooksSagas";
import sponsorsSagas from "./preference/sponsors/sponsorsSagas";
import sponsorshipLevelSagas from "./preference/sponsorshipLevel/sponsorshipLevelSagas";
import managementSagas from "./management/managementSagas";
import pluginSagas from "./plugin/pluginSagas";


export default function* rootSaga() {
  yield all([
    authSagas(),
    bookingSagas(),
    creditNoteSagas(),
    countriesSagas(),
    dashboardSagas(),
    delegateSagas(),
    eventSagas(),
    globalSagas(),
    invoiceSagas(),
    dateFormatsSagas(),
    dotmailerAddressBooksSagas(),
    sponsorsSagas(),
    sponsorshipLevelSagas(),
    managementSagas(),
    pluginSagas()
  ]);
}
