export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILED = "GET_EVENTS_FAILED";

export const GET_EVENTS_FOR_SELECTOR_REQUEST = "GET_EVENTS_FOR_SELECTOR_REQUEST";
export const GET_EVENTS_FOR_SELECTOR_SUCCESS = "GET_EVENTS_FOR_SELECTOR_SUCCESS";
export const GET_EVENTS_FOR_SELECTOR_FAILED = "GET_EVENTS_FOR_SELECTOR_FAILED";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED";

export const EDIT_EVENT_CONTACT_REQUEST = "EDIT_EVENT_CONTACT_REQUEST";
export const EDIT_EVENT_CONTACT_FAILED = "EDIT_EVENT_CONTACT_FAILED";

export const EDIT_EVENT_DETAILS_REQUEST = "EDIT_EVENT_DETAILS_REQUEST";
export const EDIT_EVENT_DETAILS_SUCCESS = "EDIT_EVENT_DETAILS_SUCCESS";
export const EDIT_EVENT_DETAILS_FAILED = "EDIT_EVENT_DETAILS_FAILED";

export const EDIT_EVENT_VENUE_REQUEST = "EDIT_EVENT_VENUE_REQUEST";
export const EDIT_EVENT_VENUE_FAILED = "EDIT_EVENT_VENUE_FAILED";

export const EDIT_EVENT_APPEARANCE_REQUEST = "EDIT_EVENT_APPEARANCE_REQUEST";
export const EDIT_EVENT_APPEARANCE_FAILED = "EDIT_EVENT_APPEARANCE_FAILED";

export const EDIT_BOOKING_FIELD_REQUEST = "EDIT_BOOKING_FIELD_REQUEST";
export const EDIT_BOOKING_FIELD_FAILED = "EDIT_BOOKING_FIELD_FAILED";

export const EDIT_EVENT_INVOICE_TEMPLATE_REQUEST = "EDIT_EVENT_INVOICE_TEMPLATE_REQUEST";
export const EDIT_EVENT_INVOICE_TEMPLATE_FAILED = "EDIT_EVENT_INVOICE_TEMPLATE_FAILED";
export const PREVIEW_EVENT_INVOICE_TEMPLATE_REQUEST = "PREVIEW_EVENT_INVOICE_TEMPLATE_REQUEST";
export const PREVIEW_EVENT_INVOICE_TEMPLATE_SUCCESS = "PREVIEW_EVENT_INVOICE_TEMPLATE_SUCCESS";
export const PREVIEW_EVENT_INVOICE_TEMPLATE_FAILED = "PREVIEW_EVENT_INVOICE_TEMPLATE_FAILED";

export const CREATE_EVENT_FEE_GROUP_REQUEST = "CREATE_EVENT_FEE_GROUP_REQUEST";
export const CREATE_EVENT_FEE_GROUP_FAILED = "CREATE_EVENT_FEE_GROUP_FAILED";
export const DELETE_EVENT_FEE_GROUP_REQUEST = "DELETE_EVENT_FEE_GROUP_REQUEST";
export const DELETE_EVENT_FEE_GROUP_FAILED = "DELETE_EVENT_FEE_GROUP_FAILED";

export const CREATE_EVENT_GROUP_DISCOUNT_REQUEST = "CREATE_EVENT_GROUP_DISCOUNT_REQUEST";
export const CREATE_EVENT_GROUP_DISCOUNT_FAILED = "CREATE_EVENT_GROUP_DISCOUNT_FAILED";
export const DELETE_EVENT_GROUP_DISCOUNT_REQUEST = "DELETE_EVENT_GROUP_DISCOUNT_REQUEST";
export const DELETE_EVENT_GROUP_DISCOUNT_FAILED = "DELETE_EVENT_GROUP_DISCOUNT_FAILED";

export const CREATE_EVENT_ORGANIZATION_TYPE_REQUEST = "CREATE_EVENT_ORGANIZATION_TYPE_REQUEST";
export const CREATE_EVENT_ORGANIZATION_TYPE_FAILED = "CREATE_EVENT_ORGANIZATION_TYPE_FAILED";
export const UPDATE_EVENT_ORGANIZATION_TYPE_REQUEST = "UPDATE_EVENT_ORGANIZATION_TYPE_REQUEST";
export const UPDATE_EVENT_ORGANIZATION_TYPE_FAILED = "UPDATE_EVENT_ORGANIZATION_TYPE_FAILED";
export const DELETE_EVENT_ORGANIZATION_TYPE_REQUEST = "DELETE_EVENT_ORGANIZATION_TYPE_REQUEST";
export const DELETE_EVENT_ORGANIZATION_TYPE_FAILED = "DELETE_EVENT_ORGANIZATION_TYPE_FAILED";

export const CRUD_EVENT_OPTIONAL_ITEM_REQUEST = "CRUD_EVENT_OPTIONAL_ITEM_REQUEST";
export const CRUD_EVENT_OPTIONAL_ITEM_FAILED = "CRUD_EVENT_OPTIONAL_ITEM_FAILED";

export const CREATE_EVENT_DISCOUNT_CODE_REQUEST = "CREATE_EVENT_DISCOUNT_CODE_REQUEST";
export const CREATE_EVENT_DISCOUNT_CODE_FAILED = "CREATE_EVENT_DISCOUNT_CODE_FAILED";
export const DELETE_EVENT_DISCOUNT_CODE_REQUEST = "DELETE_EVENT_DISCOUNT_CODE_REQUEST";
export const DELETE_EVENT_DISCOUNT_CODE_FAILED = "DELETE_EVENT_DISCOUNT_CODE_FAILED";

export const CRUD_EVENT_SPONSOR_REQUEST = "CRUD_EVENT_SPONSOR_REQUEST";
export const CRUD_EVENT_SPONSOR_FAILED = "CRUD_EVENT_SPONSOR_FAILED";

export const CREATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST = "CREATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST";
export const UPDATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST = "UPDATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST";
export const DELETE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST = "DELETE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST";
export const CREATE_EVENT_ADDITIONAL_FIELD_REQUEST = "CREATE_EVENT_ADDITIONAL_FIELD_REQUEST";
export const UPDATE_EVENT_ADDITIONAL_FIELD_REQUEST = "UPDATE_EVENT_ADDITIONAL_FIELD_REQUEST";
export const DELETE_EVENT_ADDITIONAL_FIELD_REQUEST = "DELETE_EVENT_ADDITIONAL_FIELD_REQUEST";
export const CRUD_ADDITIONAL_FIELDS_FAILED = "CRUD_ADDITIONAL_FIELDS_FAILED";

export const ASSIGN_EVENT_MANAGER_REQUEST = "ASSIGN_EVENT_MANAGER_REQUEST";
export const ASSIGN_EVENT_MANAGER_FAILED = "ASSIGN_EVENT_MANAGER_FAILED";

export const CREATE_EVENT_AGENDA_BREAKOUT_REQUEST = "CREATE_EVENT_AGENDA_BREAKOUT_REQUEST";
export const CREATE_EVENT_AGENDA_BREAKOUT_FAILED = "CREATE_EVENT_AGENDA_BREAKOUT_FAILED";
export const EDIT_EVENT_AGENDA_BREAKOUT_REQUEST = "EDIT_EVENT_AGENDA_BREAKOUT_REQUEST";
export const EDIT_EVENT_AGENDA_BREAKOUT_FAILED = "EDIT_EVENT_AGENDA_BREAKOUT_FAILED";
export const DELETE_EVENT_AGENDA_BREAKOUT_REQUEST = "DELETE_EVENT_AGENDA_BREAKOUT_REQUEST";
export const DELETE_EVENT_AGENDA_BREAKOUT_FAILED = "DELETE_EVENT_AGENDA_BREAKOUT_FAILED";

export const CREATE_EVENT_AGENDA_SESSION_REQUEST = "CREATE_EVENT_AGENDA_SESSION_REQUEST";
export const CREATE_EVENT_AGENDA_SESSION_FAILED = "CREATE_EVENT_AGENDA_SESSION_FAILED";
export const EDIT_EVENT_AGENDA_SESSION_REQUEST = "EDIT_EVENT_AGENDA_SESSION_REQUEST";
export const EDIT_EVENT_AGENDA_SESSION_FAILED = "EDIT_EVENT_AGENDA_SESSION_FAILED";
export const DELETE_EVENT_AGENDA_SESSION_REQUEST = "DELETE_EVENT_AGENDA_SESSION_REQUEST";
export const DELETE_EVENT_AGENDA_SESSION_FAILED = "DELETE_EVENT_AGENDA_SESSION_FAILED";

export const EDIT_EVENT_PREFERENCES_REQUEST = "EDIT_EVENT_PREFERENCES_REQUEST";
export const EDIT_EVENT_PREFERENCES_FAILED = "EDIT_EVENT_PREFERENCES_FAILED";

export const GET_SINGLE_EVENT_REQUEST = "GET_SINGLE_EVENT_REQUEST";
export const GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS";
export const GET_SINGLE_EVENT_FAILED = "GET_SINGLE_EVENT_FAILED";

export const GET_ORGANIZATION_TYPES_REQUEST = "GET_ORGANIZATION_TYPES_REQUEST";
export const GET_ORGANIZATION_TYPES_SUCCESS = "GET_ORGANIZATION_TYPES_SUCCESS";
export const GET_ORGANIZATION_TYPES_FAILED = "GET_ORGANIZATION_TYPES_FAILED";

export const GET_BREAKOUTS_REQUEST = "GET_BREAKOUTS_REQUEST";
export const GET_BREAKOUTS_SUCCESS = "GET_BREAKOUTS_SUCCESS";
export const GET_BREAKOUTS_FAILED = "GET_BREAKOUTS_FAILED";

export const UPDATE_EVENT_EMAIL_TEMPLATES_REQUEST = "UPDATE_EVENT_EMAIL_TEMPLATES_REQUEST";
export const UPDATE_EVENT_EMAIL_TEMPLATES_SUCCESS = "UPDATE_EVENT_EMAIL_TEMPLATES_SUCCESS";
export const UPDATE_EVENT_EMAIL_TEMPLATES_FAILED = "UPDATE_EVENT_EMAIL_TEMPLATES_FAILED";

export const SEND_EVENT_EMAIL_INFO_REQUEST = "SEND_EVENT_EMAIL_INFO_REQUEST";
export const SEND_EVENT_EMAIL_INFO_SUCCESS = "SEND_EVENT_EMAIL_INFO_SUCCESS";
export const SEND_EVENT_EMAIL_INFO_FAILED = "SEND_EVENT_EMAIL_INFO_FAILED";

export const ARCHIVE_EVENT_REQUEST = "ARCHIVE_EVENT_REQUEST";
export const ARCHIVE_EVENT_SUCCESS = "ARCHIVE_EVENT_SUCCESS";
export const ARCHIVE_EVENT_FAILED = "ARCHIVE_EVENT_FAILED";

export const SET_REDIRECT_ACTION = "SET_REDIRECT_ACTION";
export const CLEAR_EVENT_ERROR_ACTION = "CLEAR_EVENT_ERROR_ACTION";

export const GET_EVENT_OPTIONS_POLICY_REQUEST = "GET_EVENT_OPTIONS_POLICY_REQUEST";
export const GET_EVENT_OPTIONS_POLICY_REQUEST_SUCCESS = "GET_EVENT_OPTIONS_POLICY_REQUEST_SUCCESS";
export const GET_EVENT_OPTIONS_POLICY_REQUEST_ERROR = "GET_EVENT_OPTIONS_POLICY_REQUEST_ERROR";

export const SAVE_EVENT_AS_DUPLICATE_REQUEST = "SAVE_EVENT_AS_DUPLICATE_REQUEST";
export const SAVE_EVENT_AS_DUPLICATE_REQUEST_ERROR = "SAVE_EVENT_AS_DUPLICATE_REQUEST_ERROR";
