import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";

// lazy load all the views

// auth
const Login = React.lazy(() => import("./pages/auth/Login"));
const Logout = React.lazy(() => import("./pages/auth/Logout"));
const ForgetPassword = React.lazy(() => import("./pages/auth/ForgetPassword"));
const RegisterManager = React.lazy(() => import("./pages/auth/RegisterManager"));
const RegisterDelegate = React.lazy(() => import("./pages/auth/RegisterDelegate"));
const ConfirmAccount = React.lazy(() => import("./pages/auth/Confirm"));
const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));
const LoginViaMagicLink = React.lazy(() => import("./pages/auth/LoginViaMagicLink"));

// event
const Events = React.lazy(() => import("./pages/event/Events"));
const SingleEvent = React.lazy(() => import("./pages/event/SingleEvent"));

// dashboard
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const DetailedStatistic = React.lazy(() => import("./pages/dashboard/DetailedStatistic"));

// booking
const BookingsLayout = React.lazy(() => import("./layouts/BookingsLayout"));

// management
const UserManagement = React.lazy(() => import("./pages/management/UserManagement"));
const UserCreateEdit = React.lazy(() => import("./pages/management/UserCreateEdit"));

const Countries = React.lazy(() => import("./pages/preference/countries/Countries"));
const DateFormats = React.lazy(() => import("./pages/preference/dateFormats/DateFormats"));
const Sponsors = React.lazy(() => import("./pages/preference/sponsors/Sponsors"));
const SponsorshipLevel = React.lazy(() => import("./pages/preference/sponsorshipLevel/SponsorshipLevel"));
const DotmailerAddressBooks = React.lazy(() => import("./pages/preference/dotmailerAddressBooks/DotmailerAddressBooks"));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: "/" }} />;
    }

    // authorised so return component
    return <Component {...props} />;
  }} />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  roles: PropTypes.array,
  location: PropTypes.object,
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const loggedUser = getLoggedInUser();

  if (loggedUser) {
    return <Route {...rest} render={() => <Redirect to={loggedUser.role === "delegate" ? "/bookings" : "/events"} />} />
  }

  return <Route {...rest} render={props => <Component {...props} />} />
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};


const routes = [
  // auth and account
  { exact: true, path: "/login", name: "Login", component: Login, route: PublicRoute },
  { exact: true, path: "/login-via-magic-link", name: "Login Via Magic Link", component: LoginViaMagicLink, route: PublicRoute },
  { exact: true, path: "/manager-register", name: "Register", component: RegisterManager, route: PublicRoute },
  { exact: true, path: "/delegate-register", name: "Register", component: RegisterDelegate, route: PublicRoute },
  { exact: true, path: "/register", name: "Register", component: RegisterManager, route: PublicRoute },
  { exact: true, path: "/confirm", name: "Confirm", component: ConfirmAccount, route: PublicRoute },
  { exact: true, path: "/reset-password", name: "Reset Password", component: ResetPassword, route: PublicRoute },
  { exact: true, path: "/forget-password", name: "Forget Password", component: ForgetPassword, route: PublicRoute },
  { exact: true, path: "/logout", name: "Logout", component: Logout, route: Route },
  // event
  { exact: true, path: "/events", name: "Events", component: Events, route: PrivateRoute, roles: ["admin", "event_manager"] },
  { exact: true, path: "/events/create", name: "SingleEvent", component: SingleEvent, route: PrivateRoute, roles: ["admin"] },
  { exact: true, path: "/events/:id/:type", name: "SingleEvent", component: SingleEvent, route: PrivateRoute, roles: ["admin"] },
  // dashboard
  { exact: true, path: "/dashboard/events", name: "Dashboard", component: Dashboard, route: PrivateRoute, roles: ["admin", "event_manager"], title: "Dashboard" },
  { exact: true, path: "/dashboard/events/:id([0-9]+)", name: "DashboardEvent", component: DetailedStatistic, route: PrivateRoute, roles: ["admin", "event_manager"], title: "Dashboard Event" },
  // booking
  { path: "/bookings", name: "BookingsLayout", component: BookingsLayout, route: PrivateRoute },
  // preference
  { exact: true, path: "/countries", name: "Countries", component: Countries, roles: ["admin"], route: PrivateRoute },
  { exact: true, path: "/date-formats", name: "DateFormats", component: DateFormats, roles: ["admin"], route: PrivateRoute },
  { exact: true, path: "/sponsors", name: "Sponsors", component: Sponsors, roles: ["admin"], route: PrivateRoute },
  { exact: true, path: "/sponsorship-level", name: "sponsorshipLevel", component: SponsorshipLevel, roles: ["admin"], route: PrivateRoute },
  { exact: true, path: "/dotmailer-address-books/:category(sector|event)", name: "dotmailerAddressBooks", component: DotmailerAddressBooks, roles: ["admin"], route: PrivateRoute },
  // management
  { exact: true, path: "/management/:role(admin|event_manager|delegate)", name: "UserManagement", component: UserManagement, route: PrivateRoute, roles: ["admin"], title: "UserManagement" },
  { exact: true, path: "/management/:id(create|[0-9]+)", name: "UserCreateEdit", component: UserCreateEdit, route: PrivateRoute, roles: ["admin"], title: "UserCreateEdit" },
  { exact: true, path: "/profile", name: "MyProfile", component: UserCreateEdit, route: PrivateRoute, title: "MyProfile" },
  // home
  {
    exact: true,
    path: "/",
    component: () => {
      const loggedUser = getLoggedInUser();

      return <Redirect to={loggedUser && loggedUser.role === "delegate" ? "/bookings" : "/events"} />;
    },
    route: PrivateRoute
  },
];

export { routes, PrivateRoute };
