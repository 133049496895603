import { Cookies } from "react-cookie";

function getHeaders(extraHeaders = {}) {
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };

  // Add auth token
  const cookies = new Cookies();
  const user = cookies.get("user");
  if (user && user.token) headers.Authorization = `Bearer ${user.token}`;

  Object.keys(extraHeaders).forEach(key => {
    if (extraHeaders[key] === null) {
      delete headers[key];
    } else {
      headers[key] = extraHeaders[key];
    }
  });

  return headers;
}

async function customFetch(url, params = {}, responseType = "json") {
  const headers = getHeaders(params.headers);

  const response = await fetch(url, { ...params, headers });

  if (response.status === 401 && !response.url.endsWith("/user/sign_in")) {
    const cookies = new Cookies();
    cookies.remove("user", { path: "/" });
    window.location.href = `${window.location.origin}/login`;

    return Promise.reject(new Error("Unauthorized"));
  } else if (response.status === 403) {
    return Promise.reject(new Error("Permission denied"));
  } else if (response.status === 500) {
    return Promise.reject(new Error("Internal server error"));
  }

  if (responseType === "json") {
    // Body is empty
    let text = await response.text();
    if (!text) text = "{}";
    // Parse json from body
    try {
      const jsonBody = JSON.parse(text);

      // Response is not in 200-299 range
      if (!response.ok) {
        if (jsonBody.errors && typeof jsonBody.errors === "object") {
          return Promise.reject(jsonBody.errors);
        }

        return Promise.reject(jsonBody);
      }

      return jsonBody;
    } catch (err) {
      return Promise.reject(err);
    }
  } else if (responseType === "file") {
    // Response is not in 200-299 range
    if (!response.ok) {
      return Promise.reject(new Error(response.statusText));
    }

    return response.blob();
  }
}

export default customFetch;
