import * as types from "./delegateActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  delegateEvents: {
    items: [],
    page: 1,
    count: 0,
  },
  singleDelegate: {},
  loading: false,
  loadingExel: false,
  error: null
};


const delegate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_DELEGATE_LIST_REQUEST:
    case types.GET_SINGLE_DELEGATE_REQUEST:
    case types.UPDATE_SINGLE_DELEGATE_REQUEST:
    case types.TOGGLE_ATTENDEE_REQUEST:
    case types.TOGGLE_SPAM_REQUEST:
    case types.TOGGLE_CANCELED_REQUEST:
    case types.GET_DELEGATE_EVENTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_DELEGATE_LIST_FAILED:
    case types.GET_SINGLE_DELEGATE_FAILED:
    case types.UPDATE_SINGLE_DELEGATE_FAILED:
    case types.TOGGLE_ATTENDEE_FAILED:
    case types.TOGGLE_SPAM_FAILED:
    case types.TOGGLE_CANCELED_FAILED:
    case types.GET_DELEGATE_EVENTS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_DELEGATE_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        loading: false,
        error: null
      };

    case types.GET_SINGLE_DELEGATE_SUCCESS:
    case types.UPDATE_SINGLE_DELEGATE_SUCCESS:
      return {
        ...state,
        singleDelegate: action.payload,
        loading: false,
        error: null
      };

    case types.TOGGLE_ATTENDEE_SUCCESS:
    case types.TOGGLE_SPAM_SUCCESS:
    case types.TOGGLE_CANCELED_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
        loading: false,
        error: null
      };

    case types.GET_DELEGATE_EVENTS_SUCCESS:
      return {
        ...state,
        delegateEvents: action.payload,
        loading: false,
        error: null
      };

    case types.GET_DELEGATE_EXEL_FILE_REQUEST:
      return {
        ...state,
        loadingExel: true
      };

    case types.GET_DELEGATE_EXEL_FILE_SUCCESS:
      return {
        ...state,
        loadingExel: false,
        error: null
      };

    case types.GET_DELEGATE_EXEL_FILE_FAILED:
      return {
        ...state,
        loadingExel: false,
        error: action.payload
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default delegate;
