export const GET_EVENT_PLUGIN_REQUEST = "GET_EVENT_PLUGIN_REQUEST";
export const GET_EVENT_PLUGIN_SUCCESS = "GET_EVENT_PLUGIN_SUCCESS";
export const GET_EVENT_PLUGIN_FAILED = "GET_EVENT_PLUGIN_FAILED";

export const CREATE_EVENT_PLUGIN_REQUEST = "CREATE_EVENT_PLUGIN_REQUEST";
export const CREATE_EVENT_PLUGIN_SUCCESS = "CREATE_EVENT_PLUGIN_SUCCESS";
export const CREATE_EVENT_PLUGIN_FAILED = "CREATE_EVENT_PLUGIN_FAILED";

export const UPDATE_EVENT_PLUGIN_REQUEST = "UPDATE_EVENT_PLUGIN_REQUEST";
export const UPDATE_EVENT_PLUGIN_SUCCESS = "UPDATE_EVENT_PLUGIN_SUCCESS";
export const UPDATE_EVENT_PLUGIN_FAILED = "UPDATE_EVENT_PLUGIN_FAILED";

export const DELETE_EVENT_PLUGIN_REQUEST = "DELETE_EVENT_PLUGIN_REQUEST";
export const DELETE_EVENT_PLUGIN_SUCCESS = "DELETE_EVENT_PLUGIN_SUCCESS";
export const DELETE_EVENT_PLUGIN_FAILED = "DELETE_EVENT_PLUGIN_FAILED";

export const SET_EVENT_PLUGIN_ACTION = "SET_EVENT_PLUGIN_ACTION";
