export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAILED = "GET_BOOKINGS_FAILED";

export const GET_USER_BY_EMAIL_BOOKING_REQUEST = "GET_USER_BY_EMAIL_BOOKING_REQUEST";
export const GET_USER_BY_EMAIL_BOOKING_SUCCESS = "GET_USER_BY_EMAIL_BOOKING_SUCCESS";
export const GET_USER_BY_EMAIL_BOOKING_FAILED = "GET_USER_BY_EMAIL_BOOKING_FAILED";

export const GET_SINGLE_BOOKING_INFO_REQUEST = "GET_SINGLE_BOOKING_INFO_REQUEST";

export const GET_SINGLE_BOOKING_REQUEST = "GET_SINGLE_BOOKING_REQUEST";
export const GET_SINGLE_BOOKING_SUCCESS = "GET_SINGLE_BOOKING_SUCCESS";
export const GET_SINGLE_BOOKING_FAILED = "GET_SINGLE_BOOKING_FAILED";

export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILED = "CREATE_BOOKING_FAILED";

export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILED = "UPDATE_BOOKING_FAILED";

export const EDIT_BOOKING_REQUEST = "EDIT_BOOKING_REQUEST";
export const EDIT_BOOKING_FAILED = "EDIT_BOOKING_FAILED";
export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_FAILED = "DELETE_BOOKING_FAILED";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";

export const UPDATE_BOOKING_STATUS_REQUEST = "UPDATE_BOOKING_STATUS_REQUEST";

export const GET_BOOKING_DELEGATES_REQUEST = "GET_BOOKING_DELEGATES_REQUEST";
export const GET_BOOKING_DELEGATES_SUCCESS = "GET_BOOKING_DELEGATES_SUCCESS";
export const GET_BOOKING_DELEGATES_FAILED = "GET_BOOKING_DELEGATES_FAILED";

export const CREATE_BOOKING_DELEGATE_REQUEST = "CREATE_BOOKING_DELEGATE_REQUEST";
export const CREATE_BOOKING_DELEGATE_SUCCESS = "CREATE_BOOKING_DELEGATE_SUCCESS";
export const CREATE_BOOKING_DELEGATE_FAILED = "CREATE_BOOKING_DELEGATE_FAILED";

export const EDIT_BOOKING_DELEGATE_REQUEST = "EDIT_BOOKING_DELEGATE_REQUEST";
export const EDIT_BOOKING_DELEGATE_SUCCESS = "EDIT_BOOKING_DELEGATE_SUCCESS";
export const EDIT_BOOKING_DELEGATE_FAILED = "EDIT_BOOKING_DELEGATE_FAILED";

export const DELETE_BOOKING_DELEGATE_REQUEST = "DELETE_BOOKING_DELEGATE_REQUEST";
export const DELETE_BOOKING_DELEGATE_SUCCESS = "DELETE_BOOKING_DELEGATE_SUCCESS";
export const DELETE_BOOKING_DELEGATE_FAILED = "DELETE_BOOKING_DELEGATE_FAILED";

export const PROCEED_BOOKING_DELEGATE_REQUEST = "PROCEED_BOOKING_DELEGATE_REQUEST";
export const PROCEED_BOOKING_DELEGATE_SUCCESS = "PROCEED_BOOKING_DELEGATE_SUCCESS";
export const PROCEED_BOOKING_DELEGATE_FAILED = "PROCEED_BOOKING_DELEGATE_FAILED";

export const ADD_BOOKING_DISCOUNT_REQUEST = "ADD_BOOKING_DISCOUNT_REQUEST";
export const UPDATE_BOOKING_DISCOUNT_REQUEST = "UPDATE_BOOKING_DISCOUNT_REQUEST";
export const DELETE_BOOKING_DISCOUNT_REQUEST = "DELETE_BOOKING_DISCOUNT_REQUEST";

export const REMIND_EMAIL_ABOUT_BOOKING_REQUEST = "REMIND_EMAIL_ABOUT_BOOKING_REQUEST";
export const REMIND_EMAIL_ABOUT_BOOKING_SUCCESS = "REMIND_EMAIL_ABOUT_BOOKING_SUCCESS";
export const REMIND_EMAIL_ABOUT_BOOKING_FAILED = "REMIND_EMAIL_ABOUT_BOOKING_FAILED";

export const CREATE_NOTE_REQUEST = "CREATE_NOTE_REQUEST";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";

export const EDIT_NOTE_REQUEST = "EDIT_NOTE_REQUEST";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILED = "EDIT_NOTE_FAILED";

export const GET_BOOKING_EXEL_FILE_REQUEST = "GET_BOOKING_EXEL_FILE_REQUEST";
export const GET_BOOKING_EXEL_FILE_SUCCESS = "GET_BOOKING_EXEL_FILE_SUCCESS";
export const GET_BOOKING_EXEL_FILE_FAILED = "GET_BOOKING_EXEL_FILE_FAILED";

export const INIT_BOOKING_PAYMENT_REQUEST = "INIT_BOOKING_PAYMENT_REQUEST";
export const INIT_BOOKING_PAYMENT_SUCCESS = "INIT_BOOKING_PAYMENT_SUCCESS";
export const INIT_BOOKING_PAYMENT_FAILED = "INIT_BOOKING_PAYMENT_FAILED";

export const SET_SELECTED_DELEGATE_ACTION = "SET_SELECTED_DELEGATE_ACTION";
export const CLEAR_SELECTED_BOOKING_ACTION = "CLEAR_SELECTED_BOOKING_ACTION";
export const CLEAR_SELECTED_USER_ACTION = "CLEAR_SELECTED_USER_ACTION";
export const CLEAR_BOOKING_ERROR_ACTION = "CLEAR_BOOKING_ERROR_ACTION";
