import * as types from "./invoiceActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  singleInvoice: {},
  loading: false,
  loadingPdf: false,
  loadingExel: false,
  loadingXero: false,
  error: null
};


const creditNote = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.CREATE_INVOICE_FAILED:
    case types.DELETE_INVOICE_FAILED:
    case types.TOGGLE_INVOICE_STATUS_FAILED:
    case types.SEND_INVOICE_EMAIL_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        error: null,
        loading: false
      };

    case types.GET_INVOICES_FAILED:
      return {
        ...state,
        items: [],
        page: 1,
        count: 0,
        error: action.payload,
        loading: false
      };

    case types.GET_SINGLE_INVOICE_SUCCESS:
      return {
        ...state,
        singleInvoice: action.payload,
        error: null,
        loading: false
      };

    case types.TOGGLE_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        item: state.items.map(item => item.id === action.payload.id ? action.payload : item),
        error: null,
        loading: false
      };

    case types.GET_EVENT_INVOICES_PDF_REQUEST:
      return {
        ...state,
        loadingPdf: true,
        error: null
      };

    case types.GET_EVENT_INVOICES_PDF_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        error: null
      };

    case types.GET_EVENT_INVOICES_PDF_FAILED:
      return {
        ...state,
        loadingPdf: false,
        error: action.payload
      };

    case types.GET_INVOICES_EXEL_REQUEST:
    case types.GET_EVENT_INVOICES_EXEL_REQUEST:
      return {
        ...state,
        loadingExel: true
      };

    case types.GET_INVOICES_EXEL_SUCCESS:
    case types.GET_EVENT_INVOICES_EXEL_SUCCESS:
      return {
        ...state,
        loadingExel: false,
        error: null
      };

    case types.GET_INVOICES_EXEL_FAILED:
    case types.GET_EVENT_INVOICES_EXEL_FAILED:
      return {
        ...state,
        loadingExel: false,
        error: action.payload
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default creditNote;
