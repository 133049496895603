import * as types from "./eventActionTypes";


export const getEventsRequest = query => ({
  type: types.GET_EVENTS_REQUEST, payload: query
});

export const getEventsSuccess = events => ({
  type: types.GET_EVENTS_SUCCESS, payload: events
});

export const getEventsFailed = error => ({
  type: types.GET_EVENTS_FAILED, payload: error
});

export const getEventsForSelectorRequest = (role, query) => ({
  type: types.GET_EVENTS_FOR_SELECTOR_REQUEST, payload: { role, query }
});

export const getEventsForSelectorSuccess = events => ({
  type: types.GET_EVENTS_FOR_SELECTOR_SUCCESS, payload: events
});

export const getEventsForSelectorFailed = error => ({
  type: types.GET_EVENTS_FOR_SELECTOR_FAILED, payload: error
});

export const createEventRequest = (event, history) => ({
  type: types.CREATE_EVENT_REQUEST, payload: { event, history }
});

export const createEventSuccess = event => ({
  type: types.CREATE_EVENT_SUCCESS, payload: event
});

export const createEventFailed = error => ({
  type: types.CREATE_EVENT_FAILED, payload: error
});

export const editEventContact = event => ({
  type: types.EDIT_EVENT_CONTACT_REQUEST, payload: event
});

export const editEventContactFailed = error => ({
  type: types.EDIT_EVENT_CONTACT_FAILED, payload: error
});

export const editEventDetailsRequest = event => ({
  type: types.EDIT_EVENT_DETAILS_REQUEST, payload: event
});

export const editEventDetailsSuccess = event => ({
  type: types.EDIT_EVENT_DETAILS_SUCCESS, payload: event
});

export const editEventDetailsFailed = error => ({
  type: types.EDIT_EVENT_DETAILS_FAILED, payload: error
});

export const editEventVenue = event => ({
  type: types.EDIT_EVENT_VENUE_REQUEST, payload: event
});

export const editEventVenueFailed = error => ({
  type: types.EDIT_EVENT_VENUE_FAILED, payload: error
});

export const editEventAppearanceRequest = (event) => ({
  type: types.EDIT_EVENT_APPEARANCE_REQUEST, payload: event
});

export const editEventAppearanceFailed = (error) => ({
  type: types.EDIT_EVENT_APPEARANCE_FAILED, payload: error
});

export const editBookingFieldRequest = (body) => ({
  type: types.EDIT_BOOKING_FIELD_REQUEST, payload: body
});

export const editBookingFieldFailed = (error) => ({
  type: types.EDIT_BOOKING_FIELD_FAILED, payload: error
});

export const editEventInvoiceTemplateRequest = (event) => ({
  type: types.EDIT_EVENT_INVOICE_TEMPLATE_REQUEST, payload: event
});

export const editEventInvoiceTemplateFailed = (error) => ({
  type: types.EDIT_EVENT_INVOICE_TEMPLATE_FAILED, payload: error
});

export const previewEventCustomInvoicePdfRequest = (id) => ({
  type: types.PREVIEW_EVENT_INVOICE_TEMPLATE_REQUEST, payload: id
});

export const previewEventCustomInvoicePdfSuccess = () => ({
  type: types.PREVIEW_EVENT_INVOICE_TEMPLATE_SUCCESS
});

export const previewEventCustomInvoicePdfFailed = (error) => ({
  type: types.PREVIEW_EVENT_INVOICE_TEMPLATE_FAILED, payload: error
});

export const createEventFeeGroup = (event) => ({
  type: types.CREATE_EVENT_FEE_GROUP_REQUEST, payload: event
});

export const createEventFeeGroupFailed = (error) => ({
  type: types.CREATE_EVENT_FEE_GROUP_FAILED, payload: error
});

export const deleteEventFeeGroup = (event) => ({
  type: types.DELETE_EVENT_FEE_GROUP_REQUEST, payload: event
});

export const deleteEventFeeGroupFailed = (error) => ({
  type: types.DELETE_EVENT_FEE_GROUP_FAILED, payload: error
});

export const createEventGroupDiscount = (event) => ({
  type: types.CREATE_EVENT_GROUP_DISCOUNT_REQUEST, payload: event
});

export const createEventGroupDiscountFailed = (error) => ({
  type: types.CREATE_EVENT_GROUP_DISCOUNT_FAILED, payload: error
});

export const deleteEventGroupDiscount = (event) => ({
  type: types.DELETE_EVENT_GROUP_DISCOUNT_REQUEST, payload: event
});

export const deleteEventGroupDiscountFailed = (error) => ({
  type: types.DELETE_EVENT_GROUP_DISCOUNT_FAILED, payload: error
});

export const createEventOrganizationTypeRequest = (event) => ({
  type: types.CREATE_EVENT_ORGANIZATION_TYPE_REQUEST, payload: event
});

export const createEventOrganizationTypeFailed = (error) => ({
  type: types.CREATE_EVENT_ORGANIZATION_TYPE_FAILED, payload: error
});

export const updateEventOrganizationTypeRequest = (data, callback) => ({
  type: types.UPDATE_EVENT_ORGANIZATION_TYPE_REQUEST, payload: { data, callback }
});

export const updateEventOrganizationTypeFailed = (error) => ({
  type: types.UPDATE_EVENT_ORGANIZATION_TYPE_FAILED, payload: error
});

export const deleteEventOrganizationTypeRequest = (event) => ({
  type: types.DELETE_EVENT_ORGANIZATION_TYPE_REQUEST, payload: event
});

export const deleteEventOrganizationTypeFailed = (error) => ({
  type: types.DELETE_EVENT_ORGANIZATION_TYPE_FAILED, payload: error
});

export const crudEventOptionalItem = (event) => ({
  type: types.CRUD_EVENT_OPTIONAL_ITEM_REQUEST, payload: event
});

export const crudEventOptionalItemFailed = (error) => ({
  type: types.CRUD_EVENT_OPTIONAL_ITEM_FAILED, payload: error
});

export const createEventDiscountCode = (event) => ({
  type: types.CREATE_EVENT_DISCOUNT_CODE_REQUEST, payload: event
});

export const createEventDiscountCodeFailed = (error) => ({
  type: types.CREATE_EVENT_DISCOUNT_CODE_FAILED, payload: error
});

export const deleteEventDiscountCode = (event) => ({
  type: types.DELETE_EVENT_DISCOUNT_CODE_REQUEST, payload: event
});

export const deleteEventDiscountCodeFailed = error => ({
  type: types.DELETE_EVENT_DISCOUNT_CODE_FAILED, payload: error
});

export const createEventAgendaBreakout = event => ({
  type: types.CREATE_EVENT_AGENDA_BREAKOUT_REQUEST, payload: event
});

export const createEventAgendaBreakoutFailed = error => ({
  type: types.CREATE_EVENT_AGENDA_BREAKOUT_FAILED, payload: error
});

export const editEventAgendaBreakout = (breakout, history) => ({
  type: types.EDIT_EVENT_AGENDA_BREAKOUT_REQUEST, payload: { breakout, history }
});

export const editEventAgendaBreakoutFailed = breakout => ({
  type: types.EDIT_EVENT_AGENDA_BREAKOUT_FAILED, payload: breakout
});

export const deleteEventAgendaBreakout = breakout => ({
  type: types.DELETE_EVENT_AGENDA_BREAKOUT_REQUEST, payload: breakout
});

export const deleteEventAgendaBreakoutFailed = error => ({
  type: types.DELETE_EVENT_AGENDA_BREAKOUT_FAILED, payload: error
});

export const createEventAgendaSession = event => ({
  type: types.CREATE_EVENT_AGENDA_SESSION_REQUEST, payload: event
});
export const createEventAgendaSessionFailed = error => ({
  type: types.CREATE_EVENT_AGENDA_SESSION_FAILED, payload: error
});

export const editEventAgendaSession = session => ({
  type: types.EDIT_EVENT_AGENDA_SESSION_REQUEST, payload: session
});

export const editEventAgendaSessionFailed = breakout => ({
  type: types.EDIT_EVENT_AGENDA_SESSION_FAILED, payload: breakout
});

export const deleteEventAgendaSessionRequest = (session) => ({
  type: types.DELETE_EVENT_AGENDA_SESSION_REQUEST, payload: session
});

export const deleteEventAgendaSessionFailed = (error) => ({
  type: types.DELETE_EVENT_AGENDA_SESSION_FAILED, payload: error
});

export const crudEventSponsorRequest = (event) => ({
  type: types.CRUD_EVENT_SPONSOR_REQUEST, payload: event
});

export const crudEventSponsorFailed = (error) => ({
  type: types.CRUD_EVENT_SPONSOR_FAILED, payload: error
});

export const createEventAdditionalFieldGroupRequest = (data, callback) => ({
  type: types.CREATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, payload: { data, callback }
});

export const updateEventAdditionalFieldGroupRequest = (data, callback) => ({
  type: types.UPDATE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, payload: { data, callback }
});

export const deleteEventAdditionalFieldGroupRequest = (data) => ({
  type: types.DELETE_EVENT_ADDITIONAL_FIELD_GROUP_REQUEST, payload: data
});

export const createEventAdditionalFieldRequest = (data, callback) => ({
  type: types.CREATE_EVENT_ADDITIONAL_FIELD_REQUEST, payload: { data, callback }
});

export const updateEventAdditionalFieldRequest = (data, callback) => ({
  type: types.UPDATE_EVENT_ADDITIONAL_FIELD_REQUEST, payload: { data, callback }
});

export const deleteEventAdditionalFieldRequest = (data) => ({
  type: types.DELETE_EVENT_ADDITIONAL_FIELD_REQUEST, payload: data
});

export const createEventAdditionalFieldFailed = (error) => ({
  type: types.CRUD_ADDITIONAL_FIELDS_FAILED, payload: error
});

export const assignEventManagerRequest = (data) => ({
  type: types.ASSIGN_EVENT_MANAGER_REQUEST, payload: data
});

export const assignEventManagerFailed = (error) => ({
  type: types.ASSIGN_EVENT_MANAGER_FAILED, payload: error
});

export const editEventPreferencesRequest = (id, data) => ({
  type: types.EDIT_EVENT_PREFERENCES_REQUEST, payload: { id, data }
});

export const updateEventPreferencesFailed = (error) => ({
  type: types.EDIT_EVENT_PREFERENCES_FAILED, payload: error
});

export const getSingleEventRequest = (id) => ({
  type: types.GET_SINGLE_EVENT_REQUEST, payload: id
});

export const getSingleEventSuccess = (event = {}) => ({
  type: types.GET_SINGLE_EVENT_SUCCESS, payload: event
});

export const getSingleEventFailed = (error) => ({
  type: types.GET_SINGLE_EVENT_FAILED, payload: error
});

export const getOrganizationTypesRequest = id => ({
  type: types.GET_ORGANIZATION_TYPES_REQUEST, payload: id
});

export const getOrganizationTypesSuccess = data => ({
  type: types.GET_ORGANIZATION_TYPES_SUCCESS, payload: data
});

export const getOrganizationTypesFailed = data => ({
  type: types.GET_ORGANIZATION_TYPES_FAILED, payload: data
});

export const getBreakoutsRequest = id => ({
  type: types.GET_BREAKOUTS_REQUEST, payload: id
});

export const getBreakoutsSuccess = data => ({
  type: types.GET_BREAKOUTS_SUCCESS, payload: data
});

export const getBreakoutsFailed = error => ({
  type: types.GET_BREAKOUTS_FAILED, payload: error
});

export const updateEmailTemplatesRequest = (id, body) => ({
  type: types.UPDATE_EVENT_EMAIL_TEMPLATES_REQUEST, payload: { id, body }
});

export const updateEmailTemplatesSuccess = data => ({
  type: types.UPDATE_EVENT_EMAIL_TEMPLATES_SUCCESS, payload: data
});

export const updateEmailTemplatesFailed = error => ({
  type: types.UPDATE_EVENT_EMAIL_TEMPLATES_FAILED, payload: error
});

export const sendEventEmailInfoRequest = (id, type, callback) => ({
  type: types.SEND_EVENT_EMAIL_INFO_REQUEST, payload: { id, type, callback }
});

export const sendEventEmailInfoSuccess = data => ({
  type: types.SEND_EVENT_EMAIL_INFO_SUCCESS, payload: data
});

export const sendEventEmailInfoFailed = error => ({
  type: types.SEND_EVENT_EMAIL_INFO_FAILED, payload: error
});

export const archiveEventRequest = (id) => ({
  type: types.ARCHIVE_EVENT_REQUEST, payload: id
});

export const archiveEventSuccess = (data) => ({
  type: types.ARCHIVE_EVENT_SUCCESS, payload: data
});

export const archiveEventFailed = (error) => ({
  type: types.ARCHIVE_EVENT_FAILED, payload: error
});

export const setEventRedirectAction = () => ({
  type: types.SET_REDIRECT_ACTION, payload: true
});

export const resetEventRedirectAction = () => ({
  type: types.SET_REDIRECT_ACTION, payload: false
});

export const clearEventErrorAction = () => ({
  type: types.CLEAR_EVENT_ERROR_ACTION
});

export const getEventOptionsPolicyRequest = () => ({
  type: types.GET_EVENT_OPTIONS_POLICY_REQUEST
});

export const getEventOptionsPolicyRequestSuccess = (payload) => ({
  type: types.GET_EVENT_OPTIONS_POLICY_REQUEST_SUCCESS,
  payload
});

export const getEventOptionsPolicyRequestError = (payload) => ({
  type: types.GET_EVENT_OPTIONS_POLICY_REQUEST_ERROR,
  payload
});

export const saveEventAsDuplicateRequest = (payload) => ({
  type: types.SAVE_EVENT_AS_DUPLICATE_REQUEST,
  payload
});

export const saveEventAsDuplicateRequestError = (payload) => ({
  type: types.SAVE_EVENT_AS_DUPLICATE_REQUEST_ERROR,
  payload
});
