import * as types from "./globalActionTypes";


export const getIsoTimeZonesRequest = () => ({
  type: types.GET_ISO_TIME_ZONES_REQUEST,
});

export const getIsoTimeZonesSuccess = data => ({
  type: types.GET_ISO_TIME_ZONES_SUCCESS, payload: data
});

export const getIsoTimeZonesFailed = error => ({
  type: types.GET_ISO_TIME_ZONES_FAILED, payload: error
});

export const getSectorsRequest = () => ({
  type: types.GET_SECTORS_REQUEST,
});

export const getSectorsSuccess = data => ({
  type: types.GET_SECTORS_SUCCESS, payload: data
});

export const getSectorsFailed = error => ({
  type: types.GET_SECTORS_FAILED, payload: error
});

export const getCodeKeywordsRequest = () => ({
  type: types.GET_CODE_KEYWORDS_REQUEST
});

export const getCodeKeywordsSuccess = data => ({
  type: types.GET_CODE_KEYWORDS_SUCCESS, payload: data
});

export const getCodeKeywordsFailed = error => ({
  type: types.GET_CODE_KEYWORDS_FAILED, payload: error
});

export const setSelectedEventAction = event => ({
  type: types.SET_SELECTED_EVENT_ACTION, payload: event
});
