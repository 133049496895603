export default class QueryString {
  static stringify = query => {
    try {
      const array = [];
      if (!query || typeof query !== "object") return "";

      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          const value = query[key];
          if (Array.isArray(value)) {
            value.forEach(item => array.push(`${key}[]=${item}`));
          } else if (value !== null && typeof value !== "undefined" && typeof value === "object") {
            const inner = Object.entries(value).map(item => `${key}[${item[0]}]=${item[1]}`).join("&");
            inner && inner.length && array.push(inner);
          } else if (value !== null && typeof value !== "undefined" && value !== "") {
            array.push(`${key}=${value}`);
          }
        }
      }

      return array.length ? `?${array.join("&").replaceAll("+", "%2B")}` : "";
    } catch (err) {
      console.error("query string stringify: ", err);

      return "";
    }
  };

  static parse = string => {
    try {
      const obj = {};
      const params = new URLSearchParams(string || window.location.search);

      for (const [key, value] of params) {
        obj[key] = value;
      }

      return obj;
    } catch (err) {
      console.error("query string parse: ", err);

      return {};
    }
  };

  static getValue = (key, query, type) => {
    try {
      const params = new URLSearchParams(query || window.location.search);

      if (type === "integer") {
        return Number.parseInt(params.get(key), 10) || null;
      } else if (type === "float") {
        return Number.parseFloat(params.get(key)) || null;
      }

      return params.get(key);
    } catch (err) {
      console.error("query string getValue: ", err);

      return null;
    }
  };
}
