import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const creditNote = {
  async getCreditNotes({ eventId, search, page }) {
    try {
      return await fetch(`${API_URL}/credit_notes${QueryString.stringify({ event_id: eventId, search, page })}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSingleCreditNote(id) {
    try {
      return await fetch(`${API_URL}/credit_notes/${id}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createCreditNote(data) {
    try {
      return await fetch(`${API_URL}/credit_notes/`, {
        method: "POST",
        body: JSON.stringify({ credit_note: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editCreditNote(data) {
    try {
      return await fetch(`${API_URL}/credit_notes/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ credit_note: data })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteCreditNote(id) {
    try {
      return await fetch(`${API_URL}/credit_notes/${id}`, {
        method: "DELETE",
        body: JSON.stringify({})
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getCreditNotePdf(id) {
    try {
      return await fetch(`${API_URL}/credit_notes/${id}.pdf`, {
        headers: {
          "Content-Type": "application/pdf"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default creditNote;
