import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loadable from "react-loadable";
import { positions, Provider } from "react-alert";

import Alert from "./components/Alert";
import AlertTemplate from "./components/AlertTemplate";

import { routes } from "./routes";

import { getMyProfileRequest } from "../redux/auth/authActions";
import { getCountriesOptionsRequest } from "../redux/preference/countries/countriesActions";

import { isUserAuthenticated } from "../helpers/authUtils";
import { GOOGLE_AUTOCOMPLETE } from "../constants";

// Outer libraries
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "rc-color-picker/assets/index.css";

// Themes
import "../assets/scss/DefaultTheme.scss";

// Lazy loading and code splitting -
const loading = () => <div />;

const alertOptions = {
  timeout: 4000,
  position: positions.TOP_RIGHT,
  containerStyle: {
    zIndex: 9999999999,
  }
};

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import("./layouts/NonAuthLayout"),
  render(loaded, props) {
    const LoadedComponent = loaded.default;

    return <LoadedComponent {...props} />;
  },
  loading
});

const AuthLayout = Loadable({
  loader: () => import("./layouts/AuthLayout"),
  render(loaded, props) {
    const LoadedComponent = loaded.default;

    return <LoadedComponent {...props} />;
  },
  loading
});


export const App = ({ user, getMyProfile, getCountriesOptions }) => {
  useEffect(() => {
    if (user && user.id) {
      getMyProfile(user.id);
    }

    getCountriesOptions();

    /* Initialize google maps */
    if (!window.google && GOOGLE_AUTOCOMPLETE) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_AUTOCOMPLETE}&libraries=places`;
      document.body.insertBefore(script, document.body.firstChild);
    }
  }, []);

  const getLayout = () => {
    return isUserAuthenticated() ? AuthLayout : NonAuthLayout;
  };

  return (
    <Provider template={AlertTemplate} {...alertOptions}>
      <BrowserRouter>
        <>
          {routes.map((route, index) => {
            return (
              <route.route
                key={index}
                path={route.path}
                exact={route.exact}
                roles={route.roles}
                component={props => {
                  const Layout = getLayout();

                  return (
                    <Suspense fallback={loading()}>
                      <Layout {...props} title={route.title}>
                        <route.component {...props} />
                      </Layout>
                    </Suspense>
                  );
                }}
              />
            );
          })}
        </>
      </BrowserRouter>
      <Alert />
    </Provider>
  );
};

App.propTypes = {
  user: PropTypes.object.isRequired,
  getMyProfile: PropTypes.func.isRequired,
  getCountriesOptions: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    user: state.auth.user
  }),
  {
    getMyProfile: getMyProfileRequest,
    getCountriesOptions: getCountriesOptionsRequest,
  }
)(App);
