import { all, call, put, takeLatest } from "redux-saga/effects";

import management from "../../../src/api/management";

import * as types from "./managementActionTypes";
import * as actions from "./managementActions";


function* getUsersSaga({ payload }) {
  try {
    const response = yield call(management.getUsers, payload);
    yield put(actions.getUsersSuccess(response));
  } catch (err) {
    yield put(actions.getUsersFailed(err));
  }
}

function* getUserSaga({ payload }) {
  try {
    const response = yield call(management.getUser, payload);
    yield put(actions.getUserSuccess(response));
  } catch (err) {
    yield put(actions.getUserFailed(err));
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const response = yield call(management.deleteUser, payload.data);

    yield put(actions.deleteUserSuccess({ user: response }));
    yield call(payload.toggle);
  } catch (err) {
    yield put(actions.deleteUserFailed(err));
  }
}

function* suspendUserSaga({ payload }) {
  try {
    yield call(management.suspendUser, payload.data);
    yield put(actions.suspendUserSuccess());
    yield put(actions.getUsersRequest(payload.data));
    yield call(() => {
      payload.toggle()
    });
  } catch (err) {
    yield put(actions.suspendUserFailed(err));
  }
}

function* unblockSuspendedUserSaga({ payload }) {
  try {
    yield call(management.suspendUser, payload);
    yield put(actions.unblockSuspendedUserSuccess());
    yield put(actions.getUsersRequest(payload));
  } catch (err) {
    yield put(actions.unblockSuspendedUserFailed(err));
  }
}

function* inviteUserSaga({ payload }) {
  try {
    yield call(management.inviteUser, payload.data);
    yield put(actions.inviteUserSuccess());
    yield call(() => {
      payload.toggle()
    });
  } catch (err) {
    yield put(actions.inviteUserFailed(err));
  }
}

function* createUserSaga({ payload }) {
  try {
    yield call(management.createUser, payload.data);
    yield put(actions.createUserSuccess());
    yield call(payload.history.goBack);
  } catch (err) {
    yield put(actions.createUserFailed(err));
  }
}

function* editUserSaga({ payload }) {
  try {
    const response = yield call(management.editUser, payload.data);
    yield put(actions.editUserSuccess(response));
    yield call(payload.history.goBack);
  } catch (err) {
    yield put(actions.editUserFailed(err));
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_USERS_REQUEST, getUsersSaga),
    yield takeLatest(types.DELETE_USER_REQUEST, deleteUserSaga),
    yield takeLatest(types.SUSPEND_USER_REQUEST, suspendUserSaga),
    yield takeLatest(types.INVITE_USER_REQUEST, inviteUserSaga),
    yield takeLatest(types.CREATE_USER_REQUEST, createUserSaga),
    yield takeLatest(types.EDIT_USER_REQUEST, editUserSaga),
    yield takeLatest(types.GET_USER_REQUEST, getUserSaga),
    yield takeLatest(types.UNBLOCK_SUSPENDED_USER_REQUEST, unblockSuspendedUserSaga),
  ]);
}
