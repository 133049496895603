import fetch from "./customFetch";
import { API_URL } from "../constants";
import QueryString from "../helpers/QueryString";

const invoice = {
  async getInvoices({ eventId, kind, paid, page, search }) {
    try {
      return await fetch(`${API_URL}/invoices${QueryString.stringify({ event_id: eventId, kind, paid, page, search })}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getSingleInvoice(id) {
    try {
      return await fetch(`${API_URL}/invoices/${id}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createInvoice(data) {
    try {
      return await fetch(`${API_URL}/invoices`, {
        method: "POST",
        body: JSON.stringify({ invoice: { ...data } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editInvoice(data) {
    try {
      return await fetch(`${API_URL}/invoices/${data.id}`, {
        method: "PUT",
        body: JSON.stringify({ invoice: { ...data } })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteInvoice(id) {
    try {
      return await fetch(`${API_URL}/invoices/${id}`, {
        method: "DELETE",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async toggleInvoiceStatus(body) {
    try {
      return await fetch(`${API_URL}/invoices/${body.id}/toggle_paid`, {
        method: "PUT",
        body: JSON.stringify(body)
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async sendInvoiceEmail(id) {
    try {
      return await fetch(`${API_URL}/invoices/${id}/payment_chasing`, {
        method: "POST",
        body: JSON.stringify({ id })
      });
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getEventInvoicesPdf(id) {
    try {
      return await fetch(`${API_URL}/invoices/${id}.pdf`, {
        headers: {
          "Content-Type": "application/pdf"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getEventInvoicesExel(data) {
    try {
      return await fetch(`${API_URL}/events/${data.id}/export_invoices.xlsx`, {
        headers: {
          "Content-Type": "application/xlsx"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getInvoicesExel(data) {
    try {
      return await fetch(`${API_URL}/invoices.xlsx${QueryString.stringify(data.query)}`, {
        headers: {
          "Content-Type": "application/xlsx"
        }
      }, "file");
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getXeroExport(data) {
    try {
      return await fetch(`${API_URL}/invoices/xero_export${QueryString.stringify(data.query)}`);
    } catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default invoice;
