export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILED = "GET_INVOICES_FAILED";

export const GET_SINGLE_INVOICE_REQUEST = "GET_SINGLE_INVOICE_REQUEST";
export const GET_SINGLE_INVOICE_SUCCESS = "GET_SINGLE_INVOICE_SUCCESS";
export const GET_SINGLE_INVOICE_FAILED = "GET_SINGLE_INVOICE_FAILED";

export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED";

export const EDIT_INVOICE_REQUEST = "EDIT_INVOICE_REQUEST";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILED = "EDIT_INVOICE_FAILED";

export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILED = "DELETE_INVOICE_FAILED";

export const TOGGLE_INVOICE_STATUS_REQUEST = "TOGGLE_INVOICE_STATUS_REQUEST";
export const TOGGLE_INVOICE_STATUS_SUCCESS = "TOGGLE_INVOICE_STATUS_SUCCESS";
export const TOGGLE_INVOICE_STATUS_FAILED = "TOGGLE_INVOICE_STATUS_FAILED";

export const SEND_INVOICE_EMAIL_REQUEST = "SEND_INVOICE_EMAIL_REQUEST";
export const SEND_INVOICE_EMAIL_SUCCESS = "SEND_INVOICE_EMAIL_SUCCESS";
export const SEND_INVOICE_EMAIL_FAILED = "SEND_INVOICE_EMAIL_FAILED";

export const GET_EVENT_INVOICES_PDF_REQUEST = "GET_EVENT_INVOICES_PDF_REQUEST";
export const GET_EVENT_INVOICES_PDF_SUCCESS = "GET_EVENT_INVOICES_PDF_SUCCESS";
export const GET_EVENT_INVOICES_PDF_FAILED = "GET_EVENT_INVOICES_PDF_FAILED";

export const GET_EVENT_INVOICES_EXEL_REQUEST = "GET_EVENT_INVOICES_EXEL_REQUEST";
export const GET_EVENT_INVOICES_EXEL_SUCCESS = "GET_EVENT_INVOICES_EXEL_SUCCESS";
export const GET_EVENT_INVOICES_EXEL_FAILED = "GET_EVENT_INVOICES_EXEL_FAILED";

export const GET_INVOICES_EXEL_REQUEST = "GET_INVOICES_EXEL_REQUEST";
export const GET_INVOICES_EXEL_SUCCESS = "GET_INVOICES_EXEL_SUCCESS";
export const GET_INVOICES_EXEL_FAILED = "GET_INVOICES_EXEL_FAILED";

export const GET_XERO_EXPORT_REQUEST = "GET_XERO_EXPORT_REQUEST";
export const GET_XERO_EXPORT_SUCCESS = "GET_XERO_EXPORT_SUCCESS";
export const GET_XERO_EXPORT_FAILED = "GET_XERO_EXPORT_FAILED";
