import * as types from "./bookingActionTypes";

export const getBookingsRequest = (query = {}) => ({
  type: types.GET_BOOKINGS_REQUEST, payload: query
});

export const getBookingsSuccess = data => ({
  type: types.GET_BOOKINGS_SUCCESS, payload: data
});

export const getBookingsFailed = error => ({
  type: types.GET_BOOKINGS_FAILED, payload: error
});

export const getUserByEmailBookingRequest = data => ({
  type: types.GET_USER_BY_EMAIL_BOOKING_REQUEST, payload: data
});

export const getUserByEmailBookingSuccess = data => ({
  type: types.GET_USER_BY_EMAIL_BOOKING_SUCCESS, payload: data
});

export const getUserByEmailBookingFailed = data => ({
  type: types.GET_USER_BY_EMAIL_BOOKING_FAILED, payload: data
});

export const getSingleBookingInfoRequest = id => ({
  type: types.GET_SINGLE_BOOKING_INFO_REQUEST, payload: id
});

export const getSingleBookingRequest = id => ({
  type: types.GET_SINGLE_BOOKING_REQUEST, payload: id
});

export const getSingleBookingSuccess = data => ({
  type: types.GET_SINGLE_BOOKING_SUCCESS, payload: data
});

export const getSingleBookingFailed = error => ({
  type: types.GET_SINGLE_BOOKING_FAILED, payload: error
});

export const clearSingleBookingAction = () => ({
  type: types.GET_SINGLE_BOOKING_SUCCESS, payload: { organizationTypes: [], breakouts: [] }
});

export const createBookingRequest = (data, history) => ({
  type: types.CREATE_BOOKING_REQUEST, payload: { data, history }
});

export const createBookingSuccess = data => ({
  type: types.CREATE_BOOKING_SUCCESS, payload: data
});

export const createBookingFailed = data => ({
  type: types.CREATE_BOOKING_FAILED, payload: data
});

export const updateBookingRequest = (data, history) => ({
  type: types.UPDATE_BOOKING_REQUEST, payload: { data, history }
});

export const editBookingRequest = (data, history, path) => ({
  type: types.EDIT_BOOKING_REQUEST, payload: { data, history, path }
});

export const editBookingSuccess = event => ({
  type: types.EDIT_BOOKING_SUCCESS, payload: event
});

export const editBookingFailed = error => ({
  type: types.EDIT_BOOKING_FAILED, payload: error
});

export const deleteBookingRequest = (id, callback) => ({
  type: types.DELETE_BOOKING_REQUEST, payload: { id, callback }
});

export const deleteBookingSuccess = event => ({
  type: types.DELETE_BOOKING_SUCCESS, payload: event
});

export const deleteBookingFailed = error => ({
  type: types.DELETE_BOOKING_FAILED, payload: error
});

export const updateBookingStatusRequest = (data, callback) => ({
  type: types.UPDATE_BOOKING_STATUS_REQUEST, payload: { data, callback }
});

export const createNoteRequest = (data, history) => ({
  type: types.CREATE_NOTE_REQUEST, payload: { data, history }
});

export const createNoteSuccess = event => ({
  type: types.CREATE_NOTE_SUCCESS, payload: event
});

export const createNoteFailed = error => ({
  type: types.CREATE_NOTE_FAILED, payload: error
});

export const editNoteRequest = (data, history) => ({
  type: types.EDIT_NOTE_REQUEST, payload: { data, history }
});

export const editNoteSuccess = event => ({
  type: types.EDIT_NOTE_SUCCESS, payload: event
});

export const editNoteFailed = error => ({
  type: types.EDIT_NOTE_FAILED, payload: error
});

export const addBookingDiscountRequest = (data) => ({
  type: types.ADD_BOOKING_DISCOUNT_REQUEST, payload: data
});

export const updateBookingDiscountRequest = (data) => ({
  type: types.UPDATE_BOOKING_DISCOUNT_REQUEST, payload: data
});

export const deleteBookingDiscountRequest = (data) => ({
  type: types.DELETE_BOOKING_DISCOUNT_REQUEST, payload: data
});

export const remindEmailAboutBookingRequest = (id, callback) => ({
  type: types.REMIND_EMAIL_ABOUT_BOOKING_REQUEST, payload: { id, callback }
});

export const remindEmailAboutBookingSuccess = data => ({
  type: types.REMIND_EMAIL_ABOUT_BOOKING_SUCCESS, payload: data
});

export const remindEmailAboutBookingFailed = error => ({
  type: types.REMIND_EMAIL_ABOUT_BOOKING_FAILED, payload: error
});

export const getBookingDelegatesRequest = data => ({
  type: types.GET_BOOKING_DELEGATES_REQUEST, payload: data
});

export const getBookingDelegatesSuccess = data => ({
  type: types.GET_BOOKING_DELEGATES_SUCCESS, payload: data
});

export const getBookingDelegatesFailed = error => ({
  type: types.GET_BOOKING_DELEGATES_FAILED, payload: error
});

export const createBookingDelegateRequest = (data, callback) => ({
  type: types.CREATE_BOOKING_DELEGATE_REQUEST, payload: { data, callback }
});

export const createBookingDelegateSuccess = data => ({
  type: types.CREATE_BOOKING_DELEGATE_SUCCESS, payload: data
});

export const createBookingDelegateFailed = error => ({
  type: types.CREATE_BOOKING_DELEGATE_FAILED, payload: error
});

export const editBookingDelegateRequest = (data, callback) => ({
  type: types.EDIT_BOOKING_DELEGATE_REQUEST, payload: { data, callback }
});

export const editBookingDelegateSuccess = data => ({
  type: types.EDIT_BOOKING_DELEGATE_SUCCESS, payload: data
});

export const editBookingDelegateFailed = error => ({
  type: types.EDIT_BOOKING_DELEGATE_FAILED, payload: error
});

export const deleteBookingDelegateRequest = data => ({
  type: types.DELETE_BOOKING_DELEGATE_REQUEST, payload: data
});

export const deleteBookingDelegateSuccess = data => ({
  type: types.DELETE_BOOKING_DELEGATE_SUCCESS, payload: data
});

export const deleteBookingDelegateFailed = error => ({
  type: types.DELETE_BOOKING_DELEGATE_FAILED, payload: error
});

export const proceedBookingDelegateRequest = data => ({
  type: types.PROCEED_BOOKING_DELEGATE_REQUEST, payload: data
});

export const proceedBookingDelegateSuccess = data => ({
  type: types.PROCEED_BOOKING_DELEGATE_SUCCESS, payload: data
});

export const proceedBookingDelegateFailed = error => ({
  type: types.PROCEED_BOOKING_DELEGATE_FAILED, payload: error
});

export const getBookingExelFileRequest = (id, query, filename, callback) => ({
  type: types.GET_BOOKING_EXEL_FILE_REQUEST, payload: { id, query, filename, callback }
});

export const getBookingExelFileSuccess = data => ({
  type: types.GET_BOOKING_EXEL_FILE_SUCCESS, payload: data
});

export const getBookingExelFileFailed = error => ({
  type: types.GET_BOOKING_EXEL_FILE_FAILED, payload: error
});

export const initBookingPaymentRequest = (id) => ({
  type: types.INIT_BOOKING_PAYMENT_REQUEST, payload: { id }
});

export const initBookingPaymentSuccess = data => ({
  type: types.INIT_BOOKING_PAYMENT_SUCCESS, payload: data
});

export const initBookingPaymentFailed = error => ({
  type: types.INIT_BOOKING_PAYMENT_FAILED, payload: error
});

export const setSelectedDelegateAction = id => ({
  type: types.SET_SELECTED_DELEGATE_ACTION, payload: id
});

export const clearSelectedBookingAction = () => ({
  type: types.CLEAR_SELECTED_BOOKING_ACTION
});

export const clearSelectedUserAction = () => ({
  type: types.CLEAR_SELECTED_USER_ACTION
});

export const clearBookingErrorAction = () => ({
  type: types.CLEAR_BOOKING_ERROR_ACTION
});
