import * as types from "./eventActionTypes";
import * as authTypes from "../auth/authActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  organizationTypes: [],
  breakouts: [],
  selectOptions: [],
  singleEvent: {},
  plugins: {},
  isRedirect: false,
  loading: false,
  error: null,
  optionsPolicy: {}
};


const event = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_EVENTS_REQUEST:
    case types.GET_EVENTS_FOR_SELECTOR_REQUEST:
    case types.GET_SINGLE_EVENT_REQUEST:
    case types.GET_ORGANIZATION_TYPES_REQUEST:
    case types.GET_BREAKOUTS_REQUEST:
    case types.CREATE_EVENT_REQUEST:
    case types.CREATE_EVENT_AGENDA_BREAKOUT_REQUEST:
    case types.CREATE_EVENT_AGENDA_SESSION_REQUEST:
    case types.DELETE_EVENT_AGENDA_SESSION_REQUEST:
    case types.CREATE_EVENT_DISCOUNT_CODE_REQUEST:
    case types.DELETE_EVENT_DISCOUNT_CODE_REQUEST:
    case types.CREATE_EVENT_FEE_GROUP_REQUEST:
    case types.CREATE_EVENT_ADDITIONAL_FIELD_REQUEST:
    case types.CREATE_EVENT_GROUP_DISCOUNT_REQUEST:
    case types.CREATE_EVENT_ORGANIZATION_TYPE_REQUEST:
    case types.EDIT_EVENT_AGENDA_BREAKOUT_REQUEST:
    case types.EDIT_EVENT_AGENDA_SESSION_REQUEST:
    case types.EDIT_EVENT_APPEARANCE_REQUEST:
    case types.EDIT_EVENT_CONTACT_REQUEST:
    case types.EDIT_EVENT_VENUE_REQUEST:
    case types.DELETE_EVENT_AGENDA_BREAKOUT_REQUEST:
    case types.DELETE_EVENT_FEE_GROUP_REQUEST:
    case types.DELETE_EVENT_GROUP_DISCOUNT_REQUEST:
    case types.DELETE_EVENT_ORGANIZATION_TYPE_REQUEST:
    case types.CRUD_EVENT_OPTIONAL_ITEM_REQUEST:
    case types.CRUD_EVENT_SPONSOR_REQUEST:
    case types.PREVIEW_EVENT_INVOICE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_EVENTS_FAILED:
    case types.GET_EVENTS_FOR_SELECTOR_FAILED:
    case types.GET_ORGANIZATION_TYPES_FAILED:
    case types.GET_BREAKOUTS_FAILED:
    case types.CREATE_EVENT_FAILED:
    case types.EDIT_EVENT_VENUE_FAILED:
    case types.EDIT_EVENT_CONTACT_FAILED:
    case types.EDIT_EVENT_APPEARANCE_FAILED:
    case types.EDIT_EVENT_INVOICE_TEMPLATE_FAILED:
    case types.GET_SINGLE_EVENT_FAILED:
    case types.CREATE_EVENT_FEE_GROUP_FAILED:
    case types.DELETE_EVENT_FEE_GROUP_FAILED:
    case types.CREATE_EVENT_GROUP_DISCOUNT_FAILED:
    case types.DELETE_EVENT_GROUP_DISCOUNT_FAILED:
    case types.CREATE_EVENT_ORGANIZATION_TYPE_FAILED:
    case types.UPDATE_EVENT_ORGANIZATION_TYPE_FAILED:
    case types.DELETE_EVENT_ORGANIZATION_TYPE_FAILED:
    case types.CRUD_EVENT_OPTIONAL_ITEM_FAILED:
    case types.CREATE_EVENT_DISCOUNT_CODE_FAILED:
    case types.DELETE_EVENT_DISCOUNT_CODE_FAILED:
    case types.CRUD_EVENT_SPONSOR_FAILED:
    case types.CREATE_EVENT_AGENDA_BREAKOUT_FAILED:
    case types.EDIT_EVENT_AGENDA_BREAKOUT_FAILED:
    case types.DELETE_EVENT_AGENDA_BREAKOUT_FAILED:
    case types.CREATE_EVENT_AGENDA_SESSION_FAILED:
    case types.EDIT_EVENT_AGENDA_SESSION_FAILED:
    case types.DELETE_EVENT_AGENDA_SESSION_FAILED:
    case types.CRUD_ADDITIONAL_FIELDS_FAILED:
    case types.PREVIEW_EVENT_INVOICE_TEMPLATE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_EVENTS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
        loading: false,
        error: null
      };

    case types.GET_EVENTS_FOR_SELECTOR_SUCCESS:
      return {
        ...state,
        selectOptions: action.payload.items,
        loading: false,
        error: null
      };

    case types.GET_ORGANIZATION_TYPES_SUCCESS:
      return {
        ...state,
        organizationTypes: action.payload,
        error: null,
        loading: false
      };

    case types.GET_BREAKOUTS_SUCCESS:
      return {
        ...state,
        breakouts: action.payload
          .filter(item => item.sessions && item.sessions.length)
          .sort((a, b) => Date.parse(a.start_at) - Date.parse(b.start_at))
          .map((x, index) => ({ ...x, index })),
        error: null,
        loading: false
      };

    case types.GET_SINGLE_EVENT_SUCCESS:
    case types.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        singleEvent: action.payload,
        loading: false
      };

    case types.EDIT_EVENT_DETAILS_SUCCESS:
    case types.ARCHIVE_EVENT_SUCCESS:
      return {
        ...state,
        items: state.items
          .map(item => item.id === action.payload.id ? { ...item, ...action.payload } : item),
        singleEvent: state.singleEvent && state.singleEvent.id === action.payload.id
          ? { ...state.singleEvent, ...action.payload }
          : state.singleEvent,
        loading: false
      };

    case types.SET_REDIRECT_ACTION:
      return {
        ...state,
        isRedirect: action.payload,
      };

    case types.CLEAR_EVENT_ERROR_ACTION:
    case types.PREVIEW_EVENT_INVOICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case types.GET_EVENT_OPTIONS_POLICY_REQUEST_SUCCESS:
      return {
        ...state,
        optionsPolicy: action.payload
      }

    case types.GET_EVENT_OPTIONS_POLICY_REQUEST_ERROR:
      return {
        ...state,
        optionsPolicy: INIT_STATE.optionsPolicy
      }

    case types.SAVE_EVENT_AS_DUPLICATE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default event;
