import * as types from "./pluginActionTypes";


export const getEventPluginRequest = (eventId, pluginId) => ({
  type: types.GET_EVENT_PLUGIN_REQUEST, payload: { eventId, pluginId },
});

export const getEventPluginSuccess = data => ({
  type: types.GET_EVENT_PLUGIN_SUCCESS, payload: data
});

export const getEventPluginFailed = error => ({
  type: types.GET_EVENT_PLUGIN_FAILED, payload: error
});

export const createEventPluginRequest = (id, body) => ({
  type: types.CREATE_EVENT_PLUGIN_REQUEST, payload: { id, body },
});

export const createEventPluginSuccess = data => ({
  type: types.CREATE_EVENT_PLUGIN_SUCCESS, payload: data
});

export const createEventPluginFailed = error => ({
  type: types.CREATE_EVENT_PLUGIN_FAILED, payload: error
});

export const updateEventPluginRequest = (id, body) => ({
  type: types.UPDATE_EVENT_PLUGIN_REQUEST, payload: { id, body },
});

export const updateEventPluginSuccess = data => ({
  type: types.UPDATE_EVENT_PLUGIN_SUCCESS, payload: data
});

export const updateEventPluginFailed = error => ({
  type: types.UPDATE_EVENT_PLUGIN_FAILED, payload: error
});

export const deleteEventPluginRequest = (eventId, pluginId, callback) => ({
  type: types.DELETE_EVENT_PLUGIN_REQUEST, payload: { eventId, pluginId, callback },
});

export const deleteEventPluginSuccess = data => ({
  type: types.DELETE_EVENT_PLUGIN_SUCCESS, payload: data
});

export const deleteEventPluginFailed = error => ({
  type: types.DELETE_EVENT_PLUGIN_FAILED, payload: error
});

export const setEventPluginAction = (key, value) => ({
  type: types.SET_EVENT_PLUGIN_ACTION, payload: { key, value }
});
