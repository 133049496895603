import { Cookies } from "react-cookie";
import { all, call, put, takeLatest } from "redux-saga/effects";

import auth from "../../api/auth";

import * as types from "./authActionTypes";
import * as actions from "./authActions";
import * as alertActions from "../alert/alertActions";
import { scrollTop } from "../../helpers/methods";
import management from "../../api/management";


const setSession = (user) => {
  const cookies = new Cookies();

  if (user) {
    cookies.set("user", JSON.stringify(user), { path: "/" });
  } else {
    cookies.remove("user", { path: "/" });
  }
};

const updateSessionUser = (newUser) => {
  const cookies = new Cookies();
  const user = cookies.get("user");

  if (user && newUser && user.id === newUser.id) {
    cookies.set("user", JSON.stringify({ ...user, ...newUser }), { path: "/" });
  }
};

function *loginSaga({ payload }) {
  try {
    const response = yield call(auth.login, payload);
    setSession(response);
    yield put(actions.loginUserSuccess(response));
  } catch (err) {
    yield put(actions.loginUserFailed(err));
    setSession(null);
  }
}

function *getUserViaMagicLinkSaga({ payload }) {
  try {
    const response = yield call(auth.getUserViaMagicLink, payload.query);
    setSession(response);
    yield put(actions.getUserViaMagicLinkSuccess(response));
    if (payload.history) {
      yield call(() => payload.history.replace("/events"));
    }
  } catch (err) {
    yield put(actions.getUserViaMagicLinkFailed(err));
    setSession(null);
  }
}

function *loginViaMagicLinkSaga({ payload }) {
  try {
    yield call(auth.loginViaMagicLink, payload);
    yield put(actions.loginUserViaMagicLinkSuccess(`Log-in link was sent to ${payload.email}`));
  } catch (err) {
    yield put(actions.loginUserViaMagicLinkFailed(err));
    setSession(null);
  }
}

function* registerSaga({ payload }) {
  try {
    const response = yield call(auth.register, payload.data);
    yield put(actions.registerUserSuccess(response, "You have signed up successfully"));
    yield call(() => payload.history.push("/login"));
  } catch (err) {
    yield put(actions.registerUserFailed(err));
  }
}

function* registerViaInvitationSaga({ payload }) {
  try {
    const response = yield call(auth.registerViaInvitation, payload.data);
    yield put(actions.registerUserViaInvitationSuccess(response, "You have signed up successfully"));
    yield call(() => payload.history.push("/login"));
  } catch (err) {
    if (err.invitation_token) {
      yield put(alertActions.showFailedAlertAction("Invitation token is invalid."));
    }
    yield put(actions.registerUserViaInvitationFailed(err));
  }
}

function* getMyProfileSaga({ payload }) {
  try {
    const response = yield call(management.getUser, payload);
    updateSessionUser(response);
  } catch (err) {
    console.error("getMyProfileSaga: ", err);
    setSession(null);
    window.location.href = `${window.location.origin}/login`;
  }
}

function* updateMyProfileSaga({ payload }) {
  try {
    const response = yield call(management.editUser, payload.body);

    updateSessionUser(response);
    yield put(actions.updateMyProfileSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Your profile updated successfully!"));
    scrollTop();
  } catch (err) {
    yield put(actions.updateMyProfileFailed(err));
  }
}

function* forgetPasswordSaga({ payload }) {
  try {
    yield call(auth.forgetPassword, payload);
    yield put(actions.forgetPasswordSuccess(`Reset password link was sent to ${payload.user.email}`));
  } catch (err) {
    yield put(actions.forgetPasswordFailed(err));
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    yield call(auth.resetPassword, payload.data);
    yield put(actions.resetPasswordSuccess(`Password for ${payload.data} was changed`));
    yield call(() => payload.history.push("/login"));
  } catch (err) {
    yield put(actions.resetPasswordFailed(err));
  }
}

function* logoutSaga({ payload }) {
  try {
    setSession(null);
    yield call(() => payload.history.push("/login"));
  } catch (err) {
    console.warn("logout", err);
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.LOGIN_USER_REQUEST, loginSaga),
    yield takeLatest(types.LOGIN_USER_VIA_MAGIC_LINK_REQUEST, loginViaMagicLinkSaga),
    yield takeLatest(types.GET_USER_VIA_MAGIC_LINK_REQUEST, getUserViaMagicLinkSaga),
    yield takeLatest(types.REGISTER_USER_REQUEST, registerSaga),
    yield takeLatest(types.REGISTER_USER_VIA_INVITATION_REQUEST, registerViaInvitationSaga),
    yield takeLatest(types.GET_MY_PROFILE_REQUEST, getMyProfileSaga),
    yield takeLatest(types.UPDATE_MY_PROFILE_REQUEST, updateMyProfileSaga),
    yield takeLatest(types.FORGET_PASSWORD_REQUEST, forgetPasswordSaga),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordSaga),
    yield takeLatest(types.LOGOUT_USER_ACTION, logoutSaga),
  ])
}
