import * as types from "./dashboardActionTypes";


export const getDashboardEventListRequest = (query = {}) => ({
  type: types.GET_DASHBOARD_EVENT_LIST_REQUEST, payload: query
});

export const getDashboardEventListSuccess = data => ({
  type: types.GET_DASHBOARD_EVENT_LIST_SUCCESS, payload: data
});

export const getDashboardEventListFailed = error => ({
  type: types.GET_DASHBOARD_EVENT_LIST_FAILED, payload: error
});

export const getDetailedStatisticRequest = query => ({
  type: types.GET_DETAILED_STATISTIC_REQUEST, payload: query
});

export const getDetailedStatisticSuccess = (data = []) => ({
  type: types.GET_DETAILED_STATISTIC_SUCCESS, payload: data
});

export const getDetailedStatisticFailed = error => ({
  type: types.GET_DETAILED_STATISTIC_FAILED, payload: error
});
