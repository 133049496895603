import { all, call, put, takeLatest, select } from "redux-saga/effects";

import preference from "../../../api/preference";

import * as types from "./sponsorsActionTypes";
import * as actions from "./sponsorsActions";
import * as alertActions from "../../alert/alertActions";


function* getSponsorListSaga({ payload }) {
  try {
    const response = yield call(preference.getSponsors, payload);
    yield put(actions.getSponsorListSuccess(response));
  } catch (err) {
    yield put(actions.getSponsorListFailed(err));
  }
}

function* createSponsorSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.sponsors.items);
    const response = yield call(preference.createSponsor, payload.data);

    yield put(actions.createSponsorSuccess({ items: [...items, response] }));
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.createSponsorFailed(err));
  }
}

function* deleteSponsorSaga({ payload }) {
  try {
    const items = yield select(store => store.preference.sponsors.items);
    const response = yield call(preference.deleteSponsor, payload);

    const newItems = items.filter(item => item.id !== response.id);
    yield put(actions.deleteSponsorSuccess({ items: newItems }));
  } catch (err) {
    yield put(actions.deleteSponsorFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* editSponsorSaga({ payload }) {
  try {
    yield call(preference.editSponsor, payload.data);
    yield put(actions.editSponsorSuccess());
    yield put(actions.getSponsorListRequest());
    yield call(() => {
      payload.toggleModal()
    });
  } catch (err) {
    yield put(actions.editSponsorFailed(err));
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_SPONSOR_LIST_REQUEST, getSponsorListSaga),
    yield takeLatest(types.CREATE_SPONSOR_REQUEST, createSponsorSaga),
    yield takeLatest(types.EDIT_SPONSOR_REQUEST, editSponsorSaga),
    yield takeLatest(types.DELETE_SPONSOR_REQUEST, deleteSponsorSaga),
  ]);
}
