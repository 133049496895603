export const GOOGLE_AUTOCOMPLETE = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const BING_AUTOCOMPLETE = process.env.REACT_APP_BING_MAP_KEY;
export const BING_API_URL = "http://dev.virtualearth.net/REST/v1";

export const PER_PAGE = 20;

export const PLUGIN_PROPS = process.env.REACT_APP_API_URL === "https://api.forum-ems.com" ? {
  speaker: { func: "initSpeakers", url: "https://d1tptmhft7cwsd.cloudfront.net" },
  sponsor: { func: "initSponsors", url: "https://d2bk8xqylsr9xl.cloudfront.net" },
  location: { func: "initLocation", url: "https://d1uvkpqsa8wg8z.cloudfront.net" },
  agenda: { func: "initAgenda", url: "https://df9uqcr1sp7d6.cloudfront.net" },
  booking: { func: "initBooking", url: "https://d8kl1up79dmba.cloudfront.net" },
} : {
  speaker: { func: "initSpeakersStage", url: "https://d270xfaqdqvfme.cloudfront.net" },
  sponsor: { func: "initSponsorsStage", url: "https://dss1nz6fppv0t.cloudfront.net" },
  location: { func: "initLocationStage", url: "https://d2uh7l23baws1g.cloudfront.net" },
  agenda: { func: "initAgendaStage", url: "https://d2z4w9eonavlga.cloudfront.net" },
  booking: { func: "initBookingStage", url: "https://d2dssupx7jmkyz.cloudfront.net" },
};
