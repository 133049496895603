export const GET_SPONSORSHIP_LEVELS_REQUEST = "GET_SPONSORSHIP_LEVELS_REQUEST";
export const GET_SPONSORSHIP_LEVELS_SUCCESS = "GET_SPONSORSHIP_LEVELS_SUCCESS";
export const GET_SPONSORSHIP_LEVELS_FAILED = "GET_SPONSORSHIP_LEVELS_FAILED";

export const CREATE_SPONSORSHIP_LEVEL_REQUEST = "CREATE_SPONSORSHIP_LEVEL_REQUEST";
export const CREATE_SPONSORSHIP_LEVEL_SUCCESS = "CREATE_SPONSORSHIP_LEVEL_SUCCESS";
export const CREATE_SPONSORSHIP_LEVEL_FAILED = "CREATE_SPONSORSHIP_LEVEL_FAILED";

export const EDIT_SPONSORSHIP_LEVEL_REQUEST = "EDIT_SPONSORSHIP_LEVEL_REQUEST";
export const EDIT_SPONSORSHIP_LEVEL_SUCCESS = "EDIT_SPONSORSHIP_LEVEL_SUCCESS";
export const EDIT_SPONSORSHIP_LEVEL_FAILED = "EDIT_SPONSORSHIP_LEVEL_FAILED";

export const DELETE_SPONSORSHIP_LEVEL_REQUEST = "DELETE_SPONSORSHIP_LEVEL_REQUEST";
export const DELETE_SPONSORSHIP_LEVEL_SUCCESS = "DELETE_SPONSORSHIP_LEVEL_SUCCESS";
export const DELETE_SPONSORSHIP_LEVEL_FAILED = "DELETE_SPONSORSHIP_LEVEL_FAILED";

export const SELECT_SPONSORSHIP_LEVEL_ACTION = "SELECT_SPONSORSHIP_LEVEL_ACTION";
export const DESELECT_SPONSORSHIP_LEVEL_ACTION = "DESELECT_SPONSORSHIP_LEVEL_ACTION";
