import { all, call, put, takeLatest } from "redux-saga/effects";

import * as types from "./pluginActionTypes";

import plugin from "../../api/plugin";
import * as actions from "./pluginActions";
import * as alertActions from "../alert/alertActions";
import { PLUGIN_PROPS } from "../../constants";

const createScript = (eventId, configId, kind) => {
  const data = PLUGIN_PROPS[kind];

  return `<link rel="stylesheet" href="${data.url}/static/css/main.css" crossorigin>
<script charset="utf-8" src="${data.url}/static/js/main.js" crossorigin></script>
<script type="text/javascript">document.addEventListener("DOMContentLoaded", () => {if(window.${data.func}){window.${data.func}(${eventId},${configId})}})</script>`
};

function* getEventPluginSaga({ payload }) {
  try {
    const response = yield call(plugin.getEventPlugin, payload);
    yield put(actions.getEventPluginSuccess(response));
  } catch (err) {
    yield put(actions.getEventPluginFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* createEventPluginSaga({ payload }) {
  try {
    const newConfig = yield call(plugin.createEventPlugin, payload);

    const newScript = createScript(newConfig.pluginable_id, newConfig.id, newConfig.kind);
    const newPayload = { id: newConfig.pluginable_id, body: { ...newConfig, properties: {
      ...newConfig.properties,
      script: newScript
    } } };
    const response = yield call(plugin.updateEventPlugin, newPayload);

    yield put(actions.createEventPluginSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Plugin configuration created successfully!"));
  } catch (err) {
    yield put(actions.createEventPluginFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* updateEventPluginSaga({ payload }) {
  try {
    const { id, pluginable_id, kind, properties } = payload.body;
    const newPayload = properties.script ? payload : { ...payload, body: { ...payload.body, properties: {
      ...properties,
      script: createScript(pluginable_id, id, kind)
    } } }
    const response = yield call(plugin.updateEventPlugin, newPayload);
    yield put(actions.updateEventPluginSuccess(response));
    yield put(alertActions.showSuccessAlertAction("Plugin configuration updated successfully!"));
  } catch (err) {
    yield put(actions.updateEventPluginFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

function* deleteEventPluginSaga({ payload }) {
  try {
    const response = yield call(plugin.deleteEventPlugin, payload);
    yield put(actions.deleteEventPluginSuccess(response));
    yield call(payload.callback);
    yield put(alertActions.showSuccessAlertAction("Plugin configuration deleted successfully!"));
  } catch (err) {
    yield put(actions.deleteEventPluginFailed(err));
    yield put(alertActions.showFailedAlertAction(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_EVENT_PLUGIN_REQUEST, getEventPluginSaga),
    yield takeLatest(types.CREATE_EVENT_PLUGIN_REQUEST, createEventPluginSaga),
    yield takeLatest(types.UPDATE_EVENT_PLUGIN_REQUEST, updateEventPluginSaga),
    yield takeLatest(types.DELETE_EVENT_PLUGIN_REQUEST, deleteEventPluginSaga),
  ]);
}
