import * as types from "./dateFormatsActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  selected: {},
  loading: false,
  error: null
};


const dateFormats = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_DATE_FORMATS_REQUEST:
    case types.CREATE_DATE_FORMAT_REQUEST:
    case types.EDIT_DATE_FORMAT_REQUEST:
    case types.DELETE_DATE_FORMAT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_DATE_FORMATS_FAILED:
    case types.CREATE_DATE_FORMAT_FAILED:
    case types.EDIT_DATE_FORMAT_FAILED:
    case types.DELETE_DATE_FORMAT_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_DATE_FORMATS_SUCCESS:
    case types.CREATE_DATE_FORMAT_SUCCESS:
    case types.DELETE_DATE_FORMAT_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null
      };

    case types.EDIT_DATE_FORMAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case types.SELECT_DATE_FORMAT_ACTION:
      return {
        ...state,
        selected: action.payload
      };

    case types.DESELECT_DATE_FORMAT_ACTION:
      return {
        ...state,
        selected: {}
      };

    default:
      return state;
  }
};

export default dateFormats;
