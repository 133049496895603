import * as types from "./dotmailerAddressBooksActionTypes";

export const INIT_STATE = {
  items: [],
  page: 1,
  count: 0,
  selected: {},
  loading: false,
  error: null
};


const sponsors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_DOTMAILER_ADDRESS_BOOK_LIST_REQUEST:
    case types.CREATE_DOTMAILER_ADDRESS_BOOK_REQUEST:
    case types.UPDATE_DOTMAILER_ADDRESS_BOOK_REQUEST:
    case types.DELETE_DOTMAILER_ADDRESS_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_DOTMAILER_ADDRESS_BOOK_LIST_FAILED:
    case types.CREATE_DOTMAILER_ADDRESS_BOOK_FAILED:
    case types.UPDATE_DOTMAILER_ADDRESS_BOOK_FAILED:
    case types.DELETE_DOTMAILER_ADDRESS_BOOK_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case types.GET_DOTMAILER_ADDRESS_BOOK_LIST_SUCCESS:
    case types.DELETE_DOTMAILER_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null
      };

    case types.CREATE_DOTMAILER_ADDRESS_BOOK_SUCCESS:
    case types.UPDATE_DOTMAILER_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case types.SELECT_DOTMAILER_ADDRESS_BOOK_ACTION:
      return {
        ...state,
        selected: action.payload
      };

    case types.DESELECT_DOTMAILER_ADDRESS_BOOK_ACTION:
      return {
        ...state,
        selected: {}
      };

    default:
      return state;
  }
};

export default sponsors;
