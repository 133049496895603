import {
  EMAIL_VALIDATION_PATTERN,
  PHONE_VALIDATION_PATTERN,
  PREFIX_TO_NAME_OPTIONS,
  REQUIRED_RULE
} from "../helpers/constants";
import { BOOKING_GDPR_CONSENT_MESSAGE_TEXT } from "../helpers/text";


export const BOOKING_CONTACT_INFO_DEFAULT_FIELDS = [
  {
    name: "email",
    placeholder: "Type your email",
    label: "Email",
    asterisk: "*",
    groupClass: "col-sm-6 form-group",
    registerPreference: { ...REQUIRED_RULE, pattern: EMAIL_VALIDATION_PATTERN },
  },
  {
    name: "email_confirmation",
    placeholder: "Confirm your email",
    label: "Confirm Email",
    asterisk: "*",
    groupClass: "col-sm-6 form-group",
    registerPreference: { ...REQUIRED_RULE, pattern: EMAIL_VALIDATION_PATTERN },
  },
  {
    name: "title",
    placeholder: "Title",
    label: "Title",
    asterisk: "*",
    groupClass: "col-sm-12 form-group",
    options: PREFIX_TO_NAME_OPTIONS,
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "first_name",
    placeholder: "Type your name",
    label: "First Name",
    asterisk: "*",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "last_name",
    placeholder: "Type your family name",
    label: "Family Name",
    asterisk: "*",
    groupClass: "col-sm-6 form-group",
    options: PREFIX_TO_NAME_OPTIONS,
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "organization",
    placeholder: "Enter your Organisation",
    label: "Organisation",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "position",
    placeholder: "Enter your position",
    label: "Position",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "address",
    placeholder: "Type address",
    label: "Address line 1",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "additional_address",
    placeholder: "Type address",
    label: "Address line 2",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "city",
    placeholder: "Type city",
    label: "City/Town",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "country_id",
    placeholder: "Select your country...",
    label: "Country",
    groupClass: "col-sm-6 form-group",
    isSearchable: true,
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "phone",
    label: "Phone",
    placeholder: "Enter phone number",
    groupClass: "col-sm-6 form-group",
    registerPreference: {
      ...REQUIRED_RULE,
      pattern: PHONE_VALIDATION_PATTERN
    },
  },
  {
    name: "po_number",
    label: "Purchase Order number",
    placeholder: "Enter purchase order number",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "postcode",
    label: "Postcode",
    placeholder: "Type postcode",
    groupClass: "col-sm-6 form-group",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "belgian_vat_number",
    placeholder: "Type VAT number",
    label: "Belgian VAT Number",
    groupClass: "col-sm-6 form-group",
  },
  {
    name: "vat_exempt_reference",
    placeholder: "Type VAT Exempt Reference",
    label: "VAT Exempt Reference",
    groupClass: "col-sm-6 form-group hidden",
  },
  {
    name: "hear_about_event",
    placeholder: "",
    label: "How did you hear about this event?",
    groupClass: "col-12 form-group",
  },
  {
    name: "vat_exempt",
    label: "VAT Exempt",
    className: "checkbox-primary ml-1",
    groupClass: "col-sm-6 form-group align-self-center",
    checkboxClass: "checkbox checkbox-primary checkbox-aligned",
  },
  {
    name: "accept",
    label: BOOKING_GDPR_CONSENT_MESSAGE_TEXT,
    asterisk: "*",
    className: "checkbox-primary",
    groupClass: "co-12 form-group",
    checkboxClass: "checkbox checkbox-primary checkbox-aligned",
    registerPreference: REQUIRED_RULE,
  }
];

export const BOOKING_DELEGATE_INFO_DEFAULT_FIELDS = [
  {
    name: "email",
    placeholder: "Type your email",
    label: "Email",
    groupClass: "col-md-6 form-group",
    asterisk: "*",
    registerPreference: {
      ...REQUIRED_RULE,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: "Invalid email address"
      }
    }
  },
  {
    name: "title",
    placeholder: "Title",
    label: "Title",
    groupClass: "col-md-6 form-group",
    options: PREFIX_TO_NAME_OPTIONS,
    asterisk: "*",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "first_name",
    placeholder: "Type your name",
    label: "First Name",
    groupClass: "col-md-6 form-group",
    asterisk: "*",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "last_name",
    placeholder: "Type your family name",
    label: "Family Name",
    groupClass: "col-md-6 form-group",
    asterisk: "*",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "organization",
    placeholder: "Enter your Organisation",
    label: "Organisation",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "position",
    placeholder: "Enter your position",
    label: "Position",
    groupClass: "col-md-6 form-group",
    registerPreference: REQUIRED_RULE,
  },
  {
    name: "organization_type_id",
    placeholder: "Select type...",
    label: "Organisation Type",
    groupClass: "col-md-6 form-group",
    options: [],
    asterisk: "*",
    registerPreference: REQUIRED_RULE
  },
  {
    name: "phone",
    label: "Phone",
    placeholder: "Enter phone number",
    groupClass: "col-md-6 form-group",
    registerPreference: {
      ...REQUIRED_RULE,
      pattern: PHONE_VALIDATION_PATTERN
    },
  },
  {
    name: "vat_country_code",
    placeholder: "Select Country Code",
    label: "VAT Country Code",
    groupClass: "col-md-6 form-group",
    options: []
  },
  {
    name: "vat_number",
    placeholder: "Enter VAT Number",
    label: "VAT Number",
    groupClass: "col-md-6 form-group",
    type: "number"
  },
  {
    name: "special_needs",
    placeholder: "Special Requests",
    label: "Do you have any specific needs, such as dietary or mobility requirements?",
    groupClass: "col-12 form-group"
  },
  {
    name: "online_attending",
    label: "Do you wish to attend the event virtually?",
    groupClass: "col-12 form-group",
    options: [
      { value: "true", label: "Attending virtually" },
      { value: "false", label: "Attending in-person" }
    ],
    registerPreference: REQUIRED_RULE
  },
  {
    name: "conference_list_permission",
    label: "I give permission to use this delegate’s details (full name, organisation, position and country) in the conference attendee list.",
    className: "checkbox-primary",
    groupClass: "mt-1 col-sm-10",
    checkboxClass: "checkbox checkbox-primary checkbox-aligned"
  },
  {
    name: "future_event_emails",
    label: "I confirm this delegate would like to hear about other future events held by Forum Europe / Forum Global in this sector. It's possible to unsubscribe at any time.",
    className: "checkbox-primary",
    groupClass: "my-2 col-sm-10",
    checkboxClass: "checkbox checkbox-primary checkbox-aligned"
  }
];
