import * as types from "./pluginActionTypes";
import * as eventTypes from "../event/eventActionTypes";
import * as authTypes from "../auth/authActionTypes";
import lodash from "lodash";

export const INIT_STATE = {
  array_agenda: [],
  array_booking: [],
  array_location: [],
  array_speaker: [],
  array_sponsor: [],
  config_agenda: {},
  config_booking: {},
  config_location: {},
  config_speaker: {},
  config_sponsor: {},
  loading: false,
  error: null,
};

const getPluginsHandler = (state, action) => {
  const { plugins } = action.payload;
  if (lodash.isEmpty(plugins)) return INIT_STATE;

  const newState = lodash.cloneDeep(INIT_STATE);
  plugins.forEach(item => {
    const arr = newState[`array_${item.kind}`];
    if (arr && arr.push) {
      arr.push(item);
    }
    if (lodash.isEmpty(newState[`config_${item.kind}`])) {
      newState[`config_${item.kind}`] = item;
    }
  });

  return newState;
};

const createPluginHandler = (state, action) => {
  const arrayKey = `array_${action.payload.kind}`;

  return {
    ...state,
    [arrayKey]: (state[arrayKey] || []).concat(action.payload),
    [`config_${action.payload.kind}`]: action.payload,
    error: null,
    loading: false
  };
};

const updatePluginHandler = (state, action) => {
  const arrayKey = `array_${action.payload.kind}`;

  return {
    ...state,
    [arrayKey]: (state[arrayKey] || []).map(item => item.id === action.payload.id ? action.payload : item),
    [`config_${action.payload.kind}`]: action.payload,
    error: null,
    loading: false
  };
};

const deletePluginHandler = (state, action) => {
  const arrayKey = `array_${action.payload.kind}`;
  const objectKey = `config_${action.payload.kind}`;
  const config = state[objectKey] || {};
  const newArray = (state[arrayKey] || []).filter(item => item.id !== action.payload.id);

  return {
    ...state,
    [arrayKey]: newArray,
    [objectKey]: config.id !== action.payload.id ? config : newArray[0] ? newArray[0] : {},
    error: null,
    loading: false
  };
};

const plugin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_EVENT_PLUGIN_REQUEST:
    case types.CREATE_EVENT_PLUGIN_REQUEST:
    case types.UPDATE_EVENT_PLUGIN_REQUEST:
    case types.DELETE_EVENT_PLUGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.GET_EVENT_PLUGIN_FAILED:
    case types.CREATE_EVENT_PLUGIN_FAILED:
    case types.UPDATE_EVENT_PLUGIN_FAILED:
    case types.DELETE_EVENT_PLUGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case eventTypes.GET_SINGLE_EVENT_SUCCESS:
      return getPluginsHandler(state, action);

    case types.CREATE_EVENT_PLUGIN_SUCCESS:
      return createPluginHandler(state, action);

    case types.UPDATE_EVENT_PLUGIN_SUCCESS:
      return updatePluginHandler(state, action);

    case types.DELETE_EVENT_PLUGIN_SUCCESS:
      return deletePluginHandler(state, action);

    case types.GET_EVENT_PLUGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false
      };

    case types.SET_EVENT_PLUGIN_ACTION:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        error: null,
        loading: false
      };

    case authTypes.LOGOUT_USER_ACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default plugin;
